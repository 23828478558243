import React from "react";
import { getDayName, getSecondaryMonthName } from "./App";

function UpcomingEvent(props){

  // function getUpcomingEvent(){
  //   console.log(getDayName(14, 6));
  // }

    return(
        <div className="upcoming-event">
            <div className="jumbotron">
  <h1 className="display-4">{props.event.title}</h1>
  {/* <p className="lead upcoming-event-date">{props.event.date}</p> */}
  <p className="lead upcoming-event-date">
    {getDayName(props.event.day, props.event.month, new Date().getFullYear())}, {props.event.day} {getSecondaryMonthName(props.event.month)}
    </p>
  <hr className="my-4"></hr>
  <p>{props.event.content}</p>
  <p className="event-author">autor: {props.event.author}</p>
  {/* <p class="lead">
    <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
  </p> */}
</div>
        </div>
    );
};

export default UpcomingEvent;

