import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getDayNameByNumber } from "../App";
import axios from "axios";

function AdminPanel(props){

    const [isLoading, setIsLoading] = useState(true);
    const [isSendingData, setIsSendingData] = useState(false);
    const [sendedResponse, setSendedResponse] = useState("");
    const [placeData, setPlaceData] = useState({
        _id: "",
        title: "",
        description: "",
        descriptionAuthor: "",
        city: "",
        street: "",
        postalCode: "",
        city: "",
        webpage: "",
        phone: "",
        mondayOpen: "",
        mondayClose: "",
        tuesdayOpen: "",
        tuesdayClose: "",
        wednesdayOpen: "",
        wednesdayClose: "",
        thursdayOpen: "",
        thursdayClose: "",
        fridayOpen: "",
        fridayClose: "",
        saturdayOpen: "",
        saturdayClose: "",
        sundayOpen: "",
        sundayClose: ""
    });
    const [changesMade, setChangesMade] = useState(false);
    const [startingAdminData, setStartingAdminData] = useState();
    const [formCheck, setFormCheck] = useState("");
    const [showForm, setShowForm] = useState(false);
    const formName = "Formularz";

    useEffect(() =>{
        loadAdminPanel();
    }, []);

    function loadAdminPanel(){
        axios({
            method: "POST",
            data: {
              email: props.userData.email,
              ip: props.addressIp
            },
            withCredentials: true,
            url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_USER_ADMIN_CHECK,
          }).then((res) => {
            setAdminPanelData(res);
          }).catch((err) =>{
            console.log(err);
          });
    }

    function saveAdminPanel(){
        setIsSendingData(true);
        axios({
            method: "POST",
            data: {
              email: props.userData.email,
              ip: props.addressIp,
              _id: placeData._id,
              title: placeData.title,
              city: placeData.city,
              address: (placeData.street + ", " + placeData.postalCode + " " + placeData.city),
              description: placeData.description,
              descriptionAuthor: placeData.descriptionAuthor,
              webpage: placeData.webpage,
              phone: placeData.phone,
              hours: getHoursArray()
            },
            withCredentials: true,
            url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_USER_ADMIN_CHANGE_PLACE,
          }).then((res) => {
            getResponseFromChanges(res.data);
          }).catch((err) =>{
            console.log(err);
          });
    }

    function sendPlaceForm(){
        setIsSendingData(true);
        axios({
            method: "POST",
            data: {
                email: props.userData.email,
                placeData: placeData,
                hours: getHoursArray()
            },
            withCredentials: true,
            url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_USER_ADMIN_FORM_PLACE
        }).then((res) =>{
            getResponseFromForm(res.data);
        }).catch((err) =>{
            console.log(err);
        });
    }

    function getHoursArray(){
        let hoursArray = [];

        if (placeData.mondayOpen !== ""){
            hoursArray.push(
                {
                    day: "monday",
                    open: placeData.mondayOpen,
                    close: placeData.mondayClose
                }
            )
        }

        if (placeData.tuesdayOpen !== ""){
            hoursArray.push(
                {
                    day: "tuesday",
                    open: placeData.tuesdayOpen,
                    close: placeData.tuesdayClose
                }
            )
        }

        if (placeData.wednesdayOpen !== ""){
            hoursArray.push(
                {
                    day: "wednesday",
                    open: placeData.wednesdayOpen,
                    close: placeData.wednesdayClose
                }
            )
        }

        if (placeData.thursdayOpen !== ""){
            hoursArray.push(
                {
                    day: "thursday",
                    open: placeData.thursdayOpen,
                    close: placeData.thursdayClose
                }
            )
        }

        if (placeData.fridayOpen !== ""){
            hoursArray.push(
                {
                    day: "friday",
                    open: placeData.fridayOpen,
                    close: placeData.fridayClose
                }
            )
        }

        if (placeData.saturdayOpen !== ""){
            hoursArray.push(
                {
                    day: "saturday",
                    open: placeData.saturdayOpen,
                    close: placeData.saturdayClose
                }
            )
        }

        if (placeData.sundayOpen !== ""){
            hoursArray.push(
                {
                    day: "sunday",
                    open: placeData.sundayOpen,
                    close: placeData.sundayClose
                }
            )
        }

        return hoursArray;
    }

    function getResponseFromChanges(data){
        switch (data) {
            case 0:
                setSendedResponse("Miejsce zmienione poprawnie!");
                break;
            case 1:
                setSendedResponse("Użytkownik nie ma uprawnień do zmiany tego miejsca");
            default:
                setSendedResponse("Wystąpił błąd. Skontaktuj się z administratorem!");
                break;
        }

        setIsLoading(false);
        setIsSendingData(false);
    }

    function getResponseFromForm(data){
        if (data === "1"){
            setFormCheck("2");
            setIsLoading(false);
            setIsSendingData(false);
        }
    }

    function setAdminPanelData(response){
        // console.log(response.data);
        if (response.data === "0" || response.data === "" || response.data === undefined) {
            return;
        } else if (response.data === "1") {
            setShowForm(true);
        } else if (response.data === "2") {
            setFormCheck("2");
        } else {
        setStartingAdminData(response.data);
        fillFields(response.data);
        }

        if (response.data !== undefined){
            setIsLoading(false);
        }
    }

    function fillFields(data){
        if (data === undefined || data === null || data.title === undefined || data.address === undefined){
            console.log("data is null");
            return;
        }

        setPlaceData({
            _id: data._id,
            title: data.title,
            description: data.description,
            descriptionAuthor: data.descriptionAuthor,
            city: data.city,
            street: data.address.split(",")[0],
            postalCode: (data.address.split(",")[1]).split(" ")[1],
            city: (data.address.split(",")[1]).split(" ")[2],
            webpage: data.webpage,
            phone: data.phone,
            mondayOpen: getHoursFromData(data, "monday", true),
            mondayClose: getHoursFromData(data, "monday", false),
            tuesdayOpen: getHoursFromData(data, "tuesday", true),
            tuesdayClose: getHoursFromData(data, "tuesday", false),
            wednesdayOpen: getHoursFromData(data, "wednesday", true),
            wednesdayClose: getHoursFromData(data, "wednesday", false),
            thursdayOpen: getHoursFromData(data, "thursday", true),
            thursdayClose: getHoursFromData(data, "thursday", false),
            fridayOpen: getHoursFromData(data, "friday", true),
            fridayClose: getHoursFromData(data, "friday", false),
            saturdayOpen: getHoursFromData(data, "saturday", true),
            saturdayClose: getHoursFromData(data, "saturday", false),
            sundayOpen: getHoursFromData(data, "sunday", true),
            sundayClose: getHoursFromData(data, "sunday", false)
        });
    }

    function getHoursFromData(data, weekDay, openOrClose){
        if (data.hours.find(element => element.day === weekDay) !== undefined){
            return(
                openOrClose === true ?
                data.hours.find(element => element.day === weekDay).open
                :
                data.hours.find(element => element.day === weekDay).close
            ) 
        } else {
            return "";
        }
    }

    function handleChange(event){
        const newValue = event.target.value;
        const inputName = event.target.name;

        setChangesMade(true);

        setPlaceData({
            ...placeData, [inputName]: newValue
        });
    }

    function handleAgainButton(){
        setSendedResponse("");
        setIsSendingData(false);
        setChangesMade(false);
        setIsLoading(true);
        loadAdminPanel();
    }

    function handleResetButton(){
        fillFields(startingAdminData);
        setChangesMade(false);
    }

    function handleSubmitButton(){
        saveAdminPanel();
    }

    function handleFormSendButton(){
        sendPlaceForm();
    }

    return(
        <div className="adminPanel">
            <h1>
                {/* Panel Administracyjny */}
                { props.userData.adminId === "1" || props.userData.adminId === 1 ?
                formName :
                "Panel Administracyjny"}
                </h1>

            <div className="jumbotron">
            
            <div className="accountTabs">
            <ul className="nav nav-pills nav-tabs">
            <li className="nav-item">
            <Link to={"/uzytkownik"}><p className="nav-link">Konto</p></Link>
            </li>
            <li className="nav-item">
            <Link to={"/adminPanel"}><p className="nav-link active disabled">
                {/* Panel Administracyjny */}
                { props.userData.adminId === "1" || props.userData.adminId === "2" ?
                formName :
                "Panel Administracyjny"}
                </p></Link>
            </li>
            </ul>
            </div>

            {isLoading ? <div className="loader"></div>
            :
            isSendingData ?
            <div>
                <div className="loader"></div>
            </div>
            :
            sendedResponse !== "" ?
            <div>
                <p>{sendedResponse}</p>
                <button className={"btn btn-primary"} onClick={handleAgainButton}>Zmień ponownie</button>
            </div>
            :
            formCheck === "2" ?
                <div>
                    <p>Formularz został przesłany, prosimy czekać na odpowiedź.</p>
                </div>
            :
            <div>

            <label htmlFor="title">Nazwa miejsca:</label>
        <br></br>
        <input type="text" size="50" name="title" value={placeData.title} onChange={handleChange}></input>
        <br></br>
        <hr></hr>

        <label htmlFor="city">Miasto:</label>
        <br></br>
        {/* <select name="city" value={placeData.city} onChange={handleChange}> */}
        {/* <select name="city" onChange={handleChange}>
            <option value={placeData.city === undefined || placeData.city === "" ? "Kraków" : placeData.city}>
                {placeData.city === undefined || placeData.city === "" ? "Kraków" : placeData.city}
                </option>
        </select> */}
        <input type="text" size="50" name="city" value={placeData.city} onChange={handleChange}></input>
        <br></br>
        <hr></hr>

        <label htmlFor="street">Ulica:</label>
        <br></br>
        <input type="text" size="50" name="street" value={placeData.street} onChange={handleChange}></input>
        <br></br>
        <br></br>
        <label htmlFor="postalCode">Kod pocztowy:</label>
        <br></br>
        <input type="text" size="10" name="postalCode" value={placeData.postalCode} onChange={handleChange}></input>
        <br></br>
        <hr></hr>

        <label htmlFor="phone">Telefon:</label>
        <br></br>
        <input type="tel" size="15" name="phone" value={placeData.phone} onChange={handleChange}></input>
        <br></br>
        <hr></hr>

        <label htmlFor="webpage">Strona internetowa:</label>
        <br></br>
        <input type="text" size="50" name="webpage" value={placeData.webpage} onChange={handleChange}></input>
        <br></br>
        <hr></hr>

        <label htmlFor="description">Opis:</label>
        <br></br>
        <textarea cols={50} rows={10} name="description" value={placeData.description} onChange={handleChange}></textarea>
        <br></br>
        <br></br>
        <label htmlFor="website">Autor opisu:</label>
        <br></br>
        <input type="text" size="50" name="descriptionAuthor" value={placeData.descriptionAuthor} onChange={handleChange}></input>
        <br></br>
        <hr></hr>

        <label htmlFor="hours">Godziny otwarcia: (jeśli jest nieczynne prosimy zostawić puste)</label>

        <div className="container weekHours">
        
        <div className="row">

        <div className="col weekHoursCol">
        <p className="weekHoursDay">Poniedziałek</p>
        <span>otwarcie </span><br></br><input type="text" size="10" name="mondayOpen" value={placeData.mondayOpen} onChange={handleChange}></input>
        <br></br>
        <br></br>
        <span>zamknięcie </span><br></br><input type="text" size="10" name="mondayClose" value={placeData.mondayClose} onChange={handleChange}></input>
        <br></br>
        </div>

        <div className="col weekHoursCol">
        <p className="weekHoursDay">Wtorek</p>
        <span>otwarcie </span><br></br><input type="text" size="10" name="tuesdayOpen" value={placeData.tuesdayOpen} onChange={handleChange}></input>
        <br></br>
        <br></br>
        <span>zamknięcie </span><br></br><input type="text" size="10" name="tuesdayClose" value={placeData.tuesdayClose} onChange={handleChange}></input>
        <br></br>
        </div>

        </div>

        <div className="row">

        <div className="col weekHoursCol">
        <p className="weekHoursDay">Środa</p>
        <span>otwarcie </span><br></br><input type="text" size="10" name="wednesdayOpen" value={placeData.wednesdayOpen} onChange={handleChange}></input>
        <br></br>
        <br></br>
        <span>zamknięcie </span><br></br><input type="text" size="10" name="wednesdayClose" value={placeData.wednesdayClose} onChange={handleChange}></input>
        <br></br>
        </div>

        <div className="col weekHoursCol">
        <p className="weekHoursDay">Czwartek</p>
        <span>otwarcie </span><br></br><input type="text" size="10" name="thursdayOpen" value={placeData.thursdayOpen} onChange={handleChange}></input>
        <br></br>
        <br></br>
        <span>zamknięcie </span><br></br><input type="text" size="10" name="thursdayClose" value={placeData.thursdayClose} onChange={handleChange}></input>
        <br></br>
        </div>

        </div>

        <div className="row">

        <div className="col weekHoursCol">
        <p className="weekHoursDay">Piątek</p>
        <span>otwarcie </span><br></br><input type="text" size="10" name="fridayOpen" value={placeData.fridayOpen} onChange={handleChange}></input>
        <br></br>
        <br></br>
        <span>zamknięcie </span><br></br><input type="text" size="10" name="fridayClose" value={placeData.fridayClose} onChange={handleChange}></input>
        <br></br>
        </div>

        <div className="col weekHoursCol">
        <p className="weekHoursDay">Sobota</p>
        <span>otwarcie </span><br></br><input type="text" size="10" name="saturdayOpen" value={placeData.saturdayOpen} onChange={handleChange}></input>
        <br></br>
        <br></br>
        <span>zamknięcie </span><br></br><input type="text" size="10" name="saturdayClose" value={placeData.saturdayClose} onChange={handleChange}></input>
        <br></br>
        </div>

        </div>

        <div className="row">
        
        <div className="col weekHoursCol">
        <p className="weekHoursDay">Niedziela</p>
        <span>otwarcie </span><br></br><input type="text" size="10" name="sundayOpen" value={placeData.sundayOpen} onChange={handleChange}></input>
        <br></br>
        <br></br>
        <span>zamknięcie </span><br></br><input type="text" size="10" name="sundayClose" value={placeData.sundayClose} onChange={handleChange}></input>
        <br></br>
        </div>

        </div>

        <hr></hr>
        

        </div>

        <div className="adminPanelButtons">
            {showForm ?
                        <div>
                        <button className={"btn btn-primary"} onClick={handleFormSendButton}>Prześlij</button>
                        </div>
                        :
                        <div>
                        <button className={"btn btn-danger " + (changesMade ? "" : "disabled")} onClick={handleResetButton}>Resetuj</button>
            
                        <button className={"btn btn-primary " + (changesMade ? "" : "disabled")} onClick={handleSubmitButton}>Zapisz</button>
            
                        </div>
            }
            </div>
            
            </div>
            }
            </div>
            
        </div>
    );
};

export default AdminPanel;