import React, { useRef, useState, useEffect } from "react";
import currentTags from "./currentTags";
import "./Search.css";
import Tag from "./Tag";
import { Link } from "react-router-dom";
import axios from "axios";
import Cards from "./Cards";

function Search(props){

    const ref = useRef(null);
    // const [tags, setTags] = useState([]);
    // const [searchCategory, setSearchCategory] = useState("miejsca");
    // const [searchText, setSearchText] = useState("");
    // const [results, setResults] = useState([]);
    // const [showAccordion, setShowAccordion] = useState(true);
    const removeTags = indexToRemove => {
		props.setTags([...props.tags.filter((_, index) => index !== indexToRemove)]);
    };
    const maxLengthText = 100;
    // const addTags = event => {
	// 	if (event.target.value !== "") {
	// 		setTags([...tags, event.target.value]);
	// 		props.selectedTags([...tags, event.target.value]);
	// 		event.target.value = "";
	// 	}
	// };
  useEffect(() =>{
    window.scrollTo({
        top: 0,
        behavior: "instant"
    });
}, []);

    function tagClicked(index){
        // console.log(index);
        // setTags([...tags, currentTags[index - 1].name]);
        if (props.tags.filter(item => item.id === index).length <= 0){
            props.setTags([...props.tags, currentTags[index - 1]]);
        }
        
        // setTags([...tags, tags.push(currentTags[index - 1])]);
    }

    function handleTabSwitch(value){
        props.setTags([]);
        props.setSearchText("");
        props.setSearchCategory(value);
    }

    function handleSearch(category){
        if (category === "tags"){
            fetchResultsByTags(props.tags);
        } else if (category === "text"){
            fetchResultsByText(props.searchText);
        }
    }

    function fetchResultsByTags(tagsArray){
        if (tagsArray === undefined || tagsArray.length <= 0){
            return;
        }

        props.setLoading(true);

        axios({
            method: "POST",
            data: {
              ip: props.addressIp,
              tags: tagsArray,
              category: props.searchCategory
            },
            withCredentials: true,
            url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_FETCH_RESULTS_BYTAG_URL,
          }).then((res) => {
                if (res.data !== null && res.data !== undefined && res.data.length > 0){
                props.setResults(res.data);
                props.setPageNumber(0);
                props.setLoading(false);
                }
          }).catch((err) =>[
            console.log(err)
          ]);
    }

    function fetchResultsByText(text){
        if (text === undefined || text.length <= 0 || text.length > maxLengthText){
            return;
        }

        // let textArray = [];
        // if (text.split(" ").length > 1){
        //     for (let i = 0; i < text.split(" ").length; i++) {
        //         textArray.push(text.split(" ")[i]);
        //     };
        // } else {
        //     textArray.push(text);
        // }


        props.setLoading(true);

        axios({
            method: "POST",
            data: {
              ip: props.addressIp,
            //   text: textArray,
              text: text,
              category: props.searchCategory
            },
            withCredentials: true,
            url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_FETCH_RESULTS_BYTEXT_URL,
          }).then((res) => {
                if (res.data !== null && res.data !== undefined && res.data.length > 0){
                props.setResults(res.data);
                props.setPageNumber(0);
                props.setLoading(false);
                }
          }).catch((err) =>[
            console.log(err)
          ]);
    }

    function scrollToElement(){
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }

    return (
        <div>
            <div>
            <h1>Szukaj</h1>

            <div className="accordion" id="searchAccordion">

  <div className="accordion-item search-section">
    <h2 className="accordion-header" id="headingOne">
      {/* <button className={"accordion-button " + props.results.length > 0 ? "collapsed" : ""} type="button"  */}
      <button className={props.results.length > 0 ? "accordion-button collapsed" : "accordion-button"}  
      type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" 
      aria-expanded={props.results.length > 0 ? "false" : "true"} aria-controls="collapseOne">
      <h4>Po tagach</h4>
      </button>
    </h2>
    <div id="collapseOne" 
    className={props.results.length > 0 ? "accordion-collapse collapse" : "accordion-collapse collapse show"}
    aria-labelledby="headingOne" data-bs-parent="#searchAccordion">
      <div className="accordion-body">
      <div>
                
                <div>
                    <button className={"btn " + (props.searchCategory === "miejsca" ? "btn-primary" : "btn-outline-primary")}
                     onClick={() => { handleTabSwitch("miejsca") }}>Miejsca</button>
                    <button className={"btn " + (props.searchCategory === "eventy" ? "btn-primary" : "btn-outline-primary")}
                    onClick={() => { handleTabSwitch("eventy") }}>Eventy</button>
                </div>

                <div className="search-section-tags">
                    {currentTags.filter((item) => item.category === props.searchCategory).map((tag) =>{
                        return (
                            <Tag 
                            key={tag.id}
                            id={tag.id}
                            tagName={tag.name}
                            clickFunction={tagClicked}
                            />
                        )
                    })}
                </div>

                <div className="row">
                <div className="col-8 search-section-tags-input">
			<ul id="search-tags">
				{props.tags.map((tag, index) => (
					<li key={index} className="search-tag">
						<span className='search-tag-title'>{tag.name}</span>
						<span className='search-tag-close-icon' onClick={() => removeTags(index)}>
							x
						</span>
					</li>
				))}
			</ul>
			{/* <input
				type="text"
				onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
				placeholder="Press enter to add tags"
			/> */}
		</div>
        {/* <Link to={} className="btn btn-primary">Szukaj</Link> */}
        <div className="col-4 search-tag-button">
        <button data-bs-toggle="collapse" data-bs-target="#collapseOne"
         onClick={() => { handleSearch("tags") }} className="btn btn-primary">Szukaj</button>
        </div>
        </div>

            </div>
      </div>
    </div>
  </div>

  <div className="accordion-item search-section">
    <h2 className="accordion-header" id="headingTwo">
      <button className={"accordion-button collapsed"} 
      type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded={"false"} aria-controls="collapseThree">
      <h4>Wpisując tekst</h4>
      </button>
    </h2>
    <div id="collapseThree" 
    className={"accordion-collapse collapse"}
     aria-labelledby="headingTwo" data-bs-parent="#searchAccordion">
      <div className="accordion-body">
      <div className="search-section-text">
                
                <div>
                    <button className={"btn " + (props.searchCategory === "miejsca" ? "btn-primary" : "btn-outline-primary")}
                     onClick={() => { handleTabSwitch("miejsca") }}>Miejsca</button>
                    <button className={"btn " + (props.searchCategory === "eventy" ? "btn-primary" : "btn-outline-primary")}
                    onClick={() => { handleTabSwitch("eventy") }}>Eventy</button>
                </div>

                <div className="row">
                    <div className="col-8">
                        <input className="col-12" type="text" onKeyUp={event => event.key === "Enter" ? 
                        () =>{ handleSearch("text") } : null} value={props.searchText} onChange={e => props.setSearchText(e.target.value)}>
                        </input>
                    </div>

                <div className="col-4 search-tag-button">
                <button data-bs-toggle="collapse" data-bs-target="#collapseThree" 
                onClick={() => { handleSearch("text") }} className="btn btn-primary">Szukaj</button>
                </div>
                </div>
            </div>
      </div>
    </div>
  </div>

</div>

            
            </div>

            <div className="results-section-pointer" ref={ref}></div>

            <div className="results-section">
                {props.loading === true ?
                <div className="loader"></div> :
                props.results !== undefined && props.results.length > 0 ?
                    <div>

                    <Cards places={ props.results } setPlaces={props.setResults} category={ "Wyniki" } loading={props.loading} 
                    setLoading={props.setLoading} pageNumber={props.pageNumber} 
                    setPageNumber={props.setPageNumber} search={scrollToElement} />
                    
                    </div>
                    : null
            } 
            </div>
        </div>
    )
}

export default Search;