import React from "react";
import parse from 'html-react-parser';
import "./Home.css";

function UpcomingInfo(props){

    return(
        <div className="upcoming-info">
        <div className="jumbotron">
        <h1 className="display-4">{parse(props.info.title)}</h1>
        {/* <p className="lead upcoming-event-date">{props.event.date}</p> */}
        {/* <p className="lead upcoming-event-date">
          {getDayName(props.event.day, props.event.month, new Date().getFullYear())}, {props.event.day} {getSecondaryMonthName(props.event.month)}
          </p> */}
        <hr className="my-4"></hr>
        <p>{parse(props.info.content)}</p>
        <p className="info-author">autor: {props.info.author}</p>
        {/* <p class="lead">
          <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
        </p> */}
      </div>
      </div>
    )
}

export default UpcomingInfo;