import React from "react";
import { getPlaceStarsRating, getShortDate, getStarsImages } from "./App";

function Comment(props){
    return (
                    <div className="comment">
                        {/* <p><span>🧑</span> Autor: {props.item.username === undefined || props.item.username === "" ?  */}
                        <p><span className="user-avatar"><img src={process.env.PUBLIC_URL + "/icons/user.png"} />
                        {/* </span> Autor: {props.item.username === undefined || props.item.username === "" ?  */}
                        </span>{props.item.username === undefined || props.item.username === "" ? 
                        "użytkownik" : props.item.username}</p>
                        <hr></hr>
                        {/* <p>Ocena: {getPlaceStarsRating(props.item)}</p> */}
                        {/* <p>{getPlaceStarsRating(props.item)}</p> */}
                        <div className="profile-stars-rating">
                            <span>
                            <p>{getStarsImages(getPlaceStarsRating(props.item))}</p>
                            </span>
                        </div>
                        {/* <p>Komentarz: </p> */}
                        {/* <hr></hr> */}
                        <p>{props.item.comment}</p>
                        <div className="comment-date">
                        <p>Dodano: {getShortDate(props.item.date)}</p>
                        </div>
                        {/* <p className="comment-date">{props.item.date}</p> */}
                    </div>
    )
};

export default Comment;