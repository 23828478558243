import React, { useState, useEffect } from "react";
import "./Cards.css";
import { Link } from "react-router-dom";
import Card from "./Card";
import Pagination from "./Pagination";
import SortingPanel from "./SortingPanel";
import settings from "./settings";
import CategoryTitle from "./CategoryTitle";

function Events(props){

    const [resultsOnPage, setResultsOnPage] = useState(settings.resultsOnPageEvents);
    const [cardsSize, setCardsSize] = useState(settings.cardsSizeEvents);
    const [isSorted, setIsSorted] = useState(false);
    let eventsCheck = false;

    useEffect(() => {
        checkEvents();

        window.scrollTo({
          top: 0,
          behavior: "instant"
      })
      }, []);

    function checkEvents(){
        if (eventsCheck){
            return;
        } else{
            eventsCheck = true;
        }

        if (props.events !== undefined && props.events.length <= 0){
            props.fetchEvents();
        }
    }

    function setResultsOnPageWithSave(value){
        setResultsOnPage(value);
        settings.resultsOnPageEvents = value;
     }

     function setCardsSizeWithSave(value){
        setCardsSize(value);
        settings.cardsSizeEvents = value;
     }

    function handleCardsSizeClick(){
        // setCardsSize(!cardsSize);
        setCardsSizeWithSave(!cardsSize);
    }

    return (
        <div>
            <div className="">
                <div className="">
                {/* <h1>{props.category}</h1> */}
                <CategoryTitle category={props.category}></CategoryTitle>
                </div>

                {props.events !== undefined && props.events.length > 0 && props.loading === false ?
                <div>
                <SortingPanel resultsOnPage={resultsOnPage} setResultsOnPage={setResultsOnPageWithSave} cardsSize={handleCardsSizeClick} 
                collection={props.events} setCollection={props.setEvents} setLoading={props.setLoading} 
                target={"event"} setPageNumber={props.setPageNumber} isSorted={isSorted} setIsSorted={setIsSorted} />
                
                <Pagination resultsOnPage={resultsOnPage} pageNumber={props.pageNumber} setPageNumber={props.setPageNumber} collection={props.events} />
                </div>
                : null }
            </div>

            
            
            {props.events !== undefined && props.events.length <= 0 && props.loading || isSorted === false ?
            <div className="loader"></div> :
            cardsSize === true ?
            // props.events.map((event) =>{
                
                // props.events.slice((0 + (pageNumber * resultsOnPage)), ((pageNumber * resultsOnPage) + resultsOnPage)).map((event) =>{
                    props.events.slice((0 + (props.pageNumber * resultsOnPage)), 
                    (props.pageNumber * resultsOnPage) + resultsOnPage).map((event) =>{
                return(
                  <Card
                key={event._id}
                cardsCategory={"events"}
                event={event}
                // showProfile={props.showProfile}
                cardsSize={cardsSize}
                />
                )
              })
            :
            <div className="card-deck card-deck-cards">
                {
                // props.events.map((event) =>{
                // props.events.slice((0 + (pageNumber * resultsOnPage)), ((pageNumber * resultsOnPage) + resultsOnPage)).map((event) =>{
                    props.events.slice((0 + (props.pageNumber * resultsOnPage)), 
                    (props.pageNumber * resultsOnPage) + resultsOnPage).map((event) =>{
                return(
                  <Card
                key={event._id}
                cardsCategory={"events"}
                event={event}
                // showProfile={props.showProfile}
                cardsSize={cardsSize}
                />
                )
              })}
                </div>
        }

            <Pagination resultsOnPage={resultsOnPage} pageNumber={props.pageNumber} setPageNumber={props.setPageNumber} collection={props.events} />
            
            {props.events !== undefined && props.events.length <= 0 && props.loading === false ? 
        <div className="cards-bottom-text">
            <p>Trochę tu pusto...</p>
            <p>możesz to zmienić</p>
            </div> :
            null 
        }

            <div className="cards-bottom-text">
                <Link to={"/kontakt"}>Znasz inne ciekawe wydarzenie? Kliknij i wyślij nam formularz</Link>
            </div>
        </div>
        
    );
};

export default Events;