import React, { useEffect, useState, useRef } from "react";
import "./Profile.css";
import { getTodayHours, getAddress, getPhone, getSortedTags, getPlaceStarsRating, 
    getRating, checkIsOpen, getPlaceHoursByDay, BackLink, getStarsImages, checkIsWholeTimeOpen, getPlaceHoursString,
    getEventDate, getEventHours, getDayName, getDistanceByUserLocation } from "./App";
import Tag from "./Tag";
import Comment from "./Comment";
import InputArea from "./InputArea";
import CreateComment from "./CreateComment";
import Review from "./Review";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from "react-router-dom";
import "./Profile2.css";
import Crunches from "./Crunches";

function Profile(props){

    const [showCreateComment, setShowCreateComment] = useState(true);
    const [responseText, setResponseText] = useState({
        color: "",
        content: ""
    });
    const [accordionState, setAccordionState] = useState(false);
    const { id } = useParams();
    const [target, setTarget] = useState(null);
    const [distance, setDistance] = useState("");
    let placeCheck = false;
    const descriptionLength = 600;
    const mapZoom = 15;

    useEffect(() => {
        checkPlace();
        
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
      }, []);

    function checkPlace(){
        if (placeCheck){
            return;
        } else{
            placeCheck = true;
        }

        if (props.target === "place"){
            getPlace(id);
        } else {
            getEvent(id);
        }
    }

    function getPlace(id){
        if (id === undefined || id === null){
            console.log("id = " + id);
            return;
        }

        if (props.places === undefined || props.places.length <= 0){
            axios({
                method: "POST",
                data: {
                  ip: props.addressIp
                },
                withCredentials: true,
                url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_FETCH_SINGLE_PLACE_URL + id,
              }).then((res) => {
                setTarget(res.data);
              }).catch((err) =>[
                console.log(err)
              ]);
        } else {
            props.places.find((element) =>{
                if (element._id === id){
                    setTarget(element);
                    sendReport(element._id);
                }
            });
        }
    }

    function getEvent(id){
        if (id === undefined || id === null){
            console.log("id = " + id);
            return;
        }

        if (props.events === undefined || props.events.length <= 0){
            axios({
                method: "POST",
                data: {
                  ip: props.addressIp
                },
                withCredentials: true,
                url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_FETCH_SINGLE_EVENT_URL + id,
              }).then((res) => {
                setTarget(res.data);
              }).catch((err) =>[
                console.log(err)
              ]);
        } else {
            props.events.find((element) =>{
                if (element._id === id){
                    setTarget(element);
                    sendReport(element._id);
                }
            });
        }
    }

    function sendReport(id){
        axios.post((props.target === "place" ? process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_COUNT_PLACE_URL 
        : process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_COUNT_EVENT_URL)
         + id).then(result =>{
        }).catch(err =>{
            console.log(err);
        })
    }

    function postNewComment(user, place, comment){
        axios.post((props.target === "place" ? process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_POST_COMMENT 
        : process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_POST_EVENT_COMMENT), {
            userId: user._id,
            username: user.username,
            targetId: place._id,
            comment: comment.content,
            rating: comment.rating,
            ip: props.addressIp
        }).then(response =>{
            newCommentResponse(response);
        }).catch(err =>{
            console.log(err);
            newCommentResponse(err.response);
        });
    };

    function newCommentResponse(response){
        console.log(response);
        if (response !== undefined)
        console.log(response.status);
    }

    function onAddComment(comment){
        if (checkForValidate(comment) === false){
            return;
        }

        postNewComment(props.userData, target, comment);
        setShowCreateComment(false);
        setResponseText({color: "green", content: "Komentarz został poprawnie dodany."});
    }

    function checkForValidate(comment){
        for (let i = 0; i < props.bannedWords.length; i++) {
            if (comment.content.includes(props.bannedWords[i])){
            //   console.log("Komentarz zawiera niedozwone słowo.");
            setResponseText({color: "red", content: "Komentarz zawiera niedozwone słowo."});
              return false;
            }
          }

          return true;
    }

    // function handleBack(){
    //     props.changeState(props.place.subcategory);
    // }

    function checkForCreateComment(){
        if (props.userData === undefined || target === undefined || target.commentsAndRatings === undefined){
            return null;
        }

        return props.userData === undefined || props.userData._id === undefined ? false :
        !target.commentsAndRatings.find(item => item.userId === props.userData._id)
    };

    let targetCommentsAndRating = "";
    if (target !== undefined && target !== null){
        targetCommentsAndRating = getRating(target.commentsAndRatings, target.reviews);
    }

    function hoursElement(){
        // let hoursArray = ["Ndz:", "Pon:", "Wt:", "Śr:", "Czw:", "Pt:", "Sob:"];
        let hoursArray = [
            {day: "Pon:", value: 1},
            {day: "Wt:", value: 2},
            {day: "Śr:", value: 3},
            {day: "Czw:", value: 4},
            {day: "Pt:", value: 5},
            {day: "Sob:", value: 6},
            {day: "Ndz:", value: 0}
        ];

        return(
    <ul >
  <li>
    <a className="menu">
      <p className="menu-title">
        {/* <span className={checkIsOpen(place) || checkIsWholeTimeOpen(place, new Date().getDay()) ? "itsOpen" : "itsClose"}> */}
        <span className={checkIsOpen(target) ? "itsOpen" : "itsClose"}>
        {/* {getTodayHours(place).weekDay + getTodayHours(place).open + " - " + getTodayHours(place).close + "⯆"} */}
        {/* {checkIsOpen(place) === false && (getPlaceHoursByDay(place, new Date().getDay()).open === "" || 
        getPlaceHoursByDay(place, new Date().getDay()).open === undefined) ? "nieczynne " : 
        checkIsWholeTimeOpen(place, new Date().getDay()) ?
        "całą dobę " :
        getTodayHours(place).weekDay + getTodayHours(place).open + " - " + getTodayHours(place).close + " "} */}
        {getPlaceHoursString(target) + " "}
        <img className="menu-title-arrow" src={process.env.PUBLIC_URL + '/icons/triangular down.png'} />
        </span>
        </p>
      <ul className="menu-dropdown">
        {hoursArray.map((item) =>(
            <li className={getPlaceHoursByDay(target, item.value).open === "" ? "itsClose" : "itsOpen"}
            key={item.value}>
                <p>
                {item.day + " "}
                {/* {getPlaceHoursByDay(place, item.value).open.length > 13 ? <br></br> : null} */}
                {getPlaceHoursByDay(target, item.value).open === "" ? "nieczynne" :
                checkIsWholeTimeOpen(target, item.value) ?
                "całą dobę" :
            getPlaceHoursByDay(target, item.value).open}{
                getPlaceHoursByDay(target, item.value).open !== "00:00" && getPlaceHoursByDay(target, item.value).close !== "00:00" &&
                getPlaceHoursByDay(target, item.value).open[0] !== "w" && getPlaceHoursByDay(target, item.value).open !== "" ? 
                " - " + getPlaceHoursByDay(target, item.value).close : null}
                </p>
            </li>
        ))}
      </ul>
    </a>
  </li>
  </ul>
        )
    }


    return(
        <div>
            {target === undefined || target === null ? <div className="loader"></div> :
            
        <div className="profile-header">
            
            <Crunches target={ target }/>

            <div className="profile">
            {/* {console.log("Propsy: " + props.place.title)} */}
            <div className="profile-body">
            <div>
                <div className="row">
                    <div className="col-xl-8 col-lg-12">
                    <div>
                <h2>{target.title}</h2>
                {/* {place.status === "verified" ? 
                <p style={{color: "green"}}>Status: zweryfikowany</p> : 
                <p style={{color: "red"}}>Status: nie zweryfikowany</p>} */}
                <hr></hr>
                </div>

                <div>
                
                <div className="d-flex profile-tags">
                <p className="profile-tags-title">Tagi:</p>
                <div className="d-flex profile-tags-tags flex-wrap">
                {target.tags === undefined || target.tags.length <= 0 || target.tags === undefined ? null : 
                            target.tags[0].name !== null && target.tags[0].name !== undefined && target.tags[0].name !== "" ? 
                            getSortedTags(target).map(tag =>{
                                // props.place.tags.map(tag =>{
                                    return <Tag
                                     key={tag._id}
                                     tagName={tag.name} 
                                     />
                            }) : null
                            }
                </div>
                        </div>
                        <hr></hr>
                </div>

                    </div>
                
                <div className="col-4">

                
                        <div className="profile-image">
            {target.images !== null && target.images !== undefined && target.images.length > 0 ?
            <div>
                <img src={target.images[0].img} alt=""></img> 
            <p>autor: {target.images[0].author}</p>
                </div> : null}
            </div>
            </div>

            </div>
                        <div className="row profile-section">
                        <div className="col-6 profile-stars-rating">
                            {props.target === "place" ? <p>Ocena miejsca: </p> : <p>Ocena wydarzenia: </p>}
                            <p>{targetCommentsAndRating === undefined ? "brak ocen" : "ocena: " + targetCommentsAndRating.finalRating + " - "}<span>{targetCommentsAndRating === undefined ? "" : 
                            getStarsImages(targetCommentsAndRating.stars)
                            }</span></p>
                            <p>{targetCommentsAndRating === undefined ? "bądź pierwszy i oceń" : "ilość ocen: " + targetCommentsAndRating.votes}</p>
                        </div>

                        <div className="col-6">

                        {props.target === "place" ?
                        <div className="">
                        <p>Czynne dzisiaj:</p>
                        { hoursElement() }
                        </div> 

                        :

                        <div className="event-date">
                        <p>
                        Data: <span>{getEventDate(target) + " "}</span>
                        {getDayName(target.startDate.split('-')[2], parseInt(target.startDate.split('-')[1]) - 1, target.startDate.split('-')[0])}
                        </p>
                        <p>Godzina: <span>{getEventHours(target)}</span></p>
                    </div>
                    }
                        

                    <hr></hr>
                    <div className="event-phone">
                        <p>Telefon</p>
                        {getPhone(target) === "-" ? "-" : 
                         <a href={"tel:+48" + getPhone(target) }>
                        <img className="profile-icon-telephone" 
                        src={process.env.PUBLIC_URL + '/icons/telephone.png'} />
                        {getPhone(target)}</a>}
                    </div>

                    <hr></hr>
                    <div className="event-address">
                        <p>Adres</p>
                        <p>{getAddress(target)}</p>

                        {props.target === "event" ? 
                                                <a className="btn btn-primary" 
                                                href={target.placeWebpage} rel="noreferrer" 
                                                target="_blank">
                                                    <img className="profile-icon" 
                                                    src={process.env.PUBLIC_URL + '/icons/web.png'} /> Strona miejsca</a>
                                                : null}

                    {target.location !== undefined && target.location !== null ?
                    // distance === "" ? getDistanceInMeters(target.location, setDistance) :
                    distance === "" ? setDistance(getDistanceByUserLocation(target.location)) :
                    distance === undefined || distance === null ? null :
                    distance < 1000 ? <p>Odległość: {Math.floor(distance)} m</p> : 
                    <p>Odległość: {(distance / 1000).toString().split(".")[0]}.
                        {(distance / 1000).toString().split(".")[1].slice(0, 2)} km</p> 
                    : null

                    }                            
                    </div>
                        </div>
                        </div>

                        <hr></hr>

                        <div className="container">
                            <div className="row">
                        
                        {props.target === "event" ?
                        <div className="profile-section col-xl-6">
                        <div className="profile-tickets">
                        <p>Bilety do kupienia na: </p>
                            { target.tickets.map((ticket, index) =>{
                                return(
                                    <div className="ticket" key={index}>
                                        <a className="btn btn-primary" 
                            href={ticket.link} rel="noreferrer" 
                            target="_blank">
                                {ticket.name}</a>
                                    </div>
                                )
                            })}
                            
                        </div>
                        </div> 
                        :
                        null
                        }
                        

                        <div className={props.target === "event" ? "profile-section col-xl-6" : "profile-section"}>
                            <div className="profile-webpage">
                                {props.target === "place" ? <p>Strona internetowa: </p> : <p>Strona internetowa wydarzenia: </p>}
                                {/* <a href={place.webpage} rel="noreferrer" target="_blank">{place.webpage}</a> */}
                                <a className="btn btn-primary" 
                                href={(props.target === "place" ? target.webpage : target.eventWebpage)} rel="noreferrer" 
                                target="_blank">
                                    <img className="profile-icon" 
                                    src={process.env.PUBLIC_URL + '/icons/web.png'} /> Strona</a>
                                {/* <form action={place.webpage} target="_blank">
                                <input className="btn btn-outline-primary" type="submit" 
                                value={<img src={process.env.PUBLIC_URL + '/icons/world-wide/web.png'} />} />
                                </form> */}
                            </div>
                        </div>

                        </div>
                        </div>
            </div>
            <div>
            
            <div className="profile-section profile-gallery">
                <h4>Galeria:</h4>

                <div id="carouselExampleAutoplaying3" className="carousel slide carousel-fade" data-bs-ride="carousel">
<div className="carousel-inner">
    
{target.images !== undefined && target.images[0] !== undefined && target.images[0].img ?
<div className="carousel-item active" data-bs-interval="1000000">
<div className="d-block w-100">
    <a href={target.images[0] !== undefined && target.images[0].img} rel="noreferrer" target="_blank">
  <img src={target.images[0] !== undefined && target.images[0].img} className="firstCarouselButton d-block w-100" alt="..."></img>
  </a>
  </div>
  <p>autor: {target.images[0].author}</p>
</div>
: null}

{target.images !== undefined && target.images[1] !== undefined && target.images[1].img ?
<div className="carousel-item" data-bs-interval="1000000">
{/* <a onClick={handleCarouselItemClick} href="#" className="d-block w-100"> */}
<div className="d-block w-100">
<a href={target.images[1].img} rel="noreferrer" target="_blank">
  <img src={target.images[1].img} className="secondCarouselButton d-block w-100" alt="..."></img>
  </a>
  {/* <div class="carousel-caption d-md-block">
    <h5>{places[1].title}</h5>
  </div> */}
  {/* </a> */}
  </div>
  <p>autor: {target.images[1].author}</p>
</div>
: null}

{target.images !== undefined && target.images[2] !== undefined && target.images[2].img ?
<div className="carousel-item" data-bs-interval="1000000">
{/* <a onClick={handleCarouselItemClick} href="#" className="d-block w-100"> */}
<div className="d-block w-100">
<a href={target.images !== undefined && target.images[2] !== undefined && target.images[2].img} rel="noreferrer" target="_blank">
  <img src={target.images !== undefined && target.images[2] !== undefined && target.images[2].img} className="thirdCarouselButton d-block w-100" alt="..."></img>
  </a>
  {/* <div class="carousel-caption d-md-block">
    <h5>{places[2].title}</h5>
  </div> */}
  {/* </a> */}
  </div>
  <p>autor: {target.images[2].author}</p>
</div>
: null}

</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="prev">
<span className="carousel-control-prev-icon" aria-hidden="true"></span>
<span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="next">
<span className="carousel-control-next-icon" aria-hidden="true"></span>
<span className="visually-hidden">Next</span>
</button>
</div>
            </div>


            </div>
            </div>

            <hr></hr>

            <div className="profile-section profile-description">
            {/* <h4>Opis:</h4> */}
            {/* <div>
            <p>{place.description.substring(0, descriptionLength)}</p>
            <div id="accordion">
  <div className="card">
    <div className="card-header" id="headingOne">
      <h5 className="mb-0">
        <button className="btn btn-link" onClick={handleAccordionClick} data-toggle="collapse" data-target="#collapseOne" 
        aria-expanded="true" aria-controls="collapseOne">
          {accordionState === true ? 
          "zwiń opis" : 
          "rozwiń więcej"}
        </button>
      </h5>
    </div>

    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body">
          <p>{place.description.substring(descriptionLength, place.description.length)}</p>
          <p className="profile-description-author">autor: {place.descriptionAuthor}</p>
      </div>
    </div>
  </div>
</div>
            </div> */}
            <div id="module" className="container">
            {/* <div className="hidden-text">
                {place.description.substring(descriptionLength, place.description.length)}
                </div> */}
            <h4>Opis:</h4>
            {target.description.length < descriptionLength ?
            <div>
                <p>{target.description}</p>
                <p className="profile-description-author">{target.descriptionAuthor}</p>
                </div> :

            <div className="profile-description-collapse">
            {/* <p>{place.description.substring(0, descriptionLength) + "..."}</p> */}
            {/* {place.description.substring(0, descriptionLength) + "..."} */}
            {target.description.substring(0, descriptionLength)}
            <div className="collapse" id="collapseExample" aria-expanded="true">
            {target.description.substring(descriptionLength, target.description.length)}
            <p className="profile-description-author">{target.descriptionAuthor}</p>
                </div>
                
            <a role="button" className="collapsed" data-toggle="collapse" href="#collapseExample" 
                aria-expanded="false" aria-controls="collapseExample">
            </a> 
            </div>


            // <div className="descContent">
            //     <p>{place.description.substring(0, descriptionLength) + "..."}
            //     <span className="long-text expand">
            //     {place.description.substring(descriptionLength, place.description.length)}
            //     </span>
            //     </p>
            //     <button>read more</button>
            // </div>

            }
</div>
            </div>

            {target.location !== undefined ?
            <div>
            <hr></hr>

            <div className="profile-section profile-location">
            <h4>Lokalizacja:</h4>
                {target.location !== undefined ?
                    <gmp-map center={target.location} zoom={mapZoom} map-id={target.title}>
                    <gmp-advanced-marker position={target.location} title={target.title}>
                    </gmp-advanced-marker>
                    </gmp-map>
                : 
                <div>
                    <p>Lokalizacja nie udostępniona</p>
                </div>}
                
            </div>
            </div> :
            null}

            <hr></hr>

            <div className="profile-section profile-reviews">
                <h4>Recenzje:</h4>
            <div>
            {target.reviews === undefined || target.reviews.length <= 0 ?
            <div>
                <p>Brak recenzji</p>
                </div> :
                <div>
                    {target.reviews.map(item =>{
                        return (
                            <Review
                            key={item._id}
                            // author={item.author}
                            // stars={getPlaceStarsRating(item)}
                            // content={item.content}
                            item={item}
                            />
                        )
                    })}
                    </div>
            }
            </div>
            </div>

            <hr></hr>

            <div className="profile-section profile-comments">
                <h4>Oceny i komentarze:</h4>
                <div>
                    { target.commentsAndRatings !== undefined && target.commentsAndRatings.length > 0 ?
                    target.commentsAndRatings.filter((item) => item.comment !== "").map(item =>{
                    return <Comment
                     key={item._id}
                    item={item}
                     />
                    }) : null }
                    
                </div>
                <div>
                    {/* {console.log(checkForCreateComment() === false)} */}
                    {/* {console.log(props.userData.active)} */}
                    {
                    // showCreateComment && props.userLogged ? <CreateComment /> : 
                    checkForCreateComment() && props.userLogged && showCreateComment && props.userData.active ? 
                    <CreateComment onAddComment={onAddComment} userData={props.userData} /> : 
                    (<div>
                        { props.userLogged ? props.userData.active ?
                        <div>
                            {/* {console.log(checkForCreateComment())} */}
                            <p>Dodałeś/aś już ocenę/komentarz</p>
                            {target.commentsAndRatings !== undefined && target.commentsAndRatings.find(function(item){
                                if (item === undefined){
                                    // console.log("znalazlo pusty element?!?!");
                                    return;
                                }
                                

                                <Comment
                     key={item._id}
                     username={item.username !== undefined && item.username !== "" ? item.username : ""}
                     stars={getPlaceStarsRating(item)} 
                     comment={item.comment}
                     userData={props.userData}
                     />
                            })}
                            </div>
                            : <div>
                                <p>Nie można dodać komentarza, email nie jest zweryfikowany.</p>
                                <p>W celu weryfikacji sprawdź swoją skrzynkę pocztową i kliknij w link aktywacyjny.</p>
                                </div>
                             : 
                    <p>Aby dodać komentarz musisz się zalogować</p>}                    
                    </div>)
                    }
                </div>
                <div>
                    <p style={{color: responseText.color}}>{responseText.content}</p>
                </div>
            </div>

            {/* <div className="profile-bottom-text">
                <Link to={"/kontakt"}>Problem z tym miejscem? Kliknij i zgłoś</Link>
            </div> */}
        </div>

        <div className="profile-bottom-text">
                {/* <a href="#" onClick={handleRedirectToForm}>Problem z tym miejscem? Kliknij i zgłoś</a> */}
                <Link to={"/kontakt"}>Problem z tym miejscem? Kliknij i zgłoś</Link>
            </div>

        </div>
        }
        </div>
    );
// };
};

export default Profile;