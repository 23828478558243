import React, { useEffect } from "react";

function UsingCookies(props){

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, []);

    return(
        <div>
            <h1>Pliki "cookies"</h1>
            <div className="home-sections">
                <p><b>Czym są pliki „cookies”?</b></p>
            <p>
            Pliki „cookies” (tzw. „ciasteczka”) to małe pliki tekstowe wysyłane przez odwiedzane witryny internetowe i przechowywane w urządzeniach końcowych użytkowników (w katalogu plików przeglądarki). Pliki te pozwalają rozpoznać urządzenie użytkownika i wyświetlić stronę internetową dostosowaną do jego indywidualnych preferencji. „Cookies” zazwyczaj zawierają nazwę strony internetowej z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer. Więcej informacji o plikach cookies znajdą Państwo na stronie <a href="https://allaboutcookies.org">allaboutcookies.org</a>.
            </p>
            <p><b>Do czego używamy plików „cookies”?</b></p>
            <p>
            Pliki „cookies” używane są w celu dostosowania zawartości stron internetowych do preferencji użytkownika oraz optymalizacji korzystania ze stron internetowych. Używamy cookies, żeby zrozumieć, w jaki sposób użytkownicy korzystają z witryny i dostosować ją tak, aby korzystanie z niej było dla nich przyjemniejsze i ciekawsze. Cookies dostarczają nam również danych statystycznych o ruchu użytkowników i korzystaniu przez nich z naszych serwisów internetowych. Cookies mogą być również stosowane przez współpracujących z nami reklamodawców (celem prezentacji treści promocyjnych za pośrednictwem serwisów internetowych) oraz przez firmy badawcze.
            </p>
            <p><b>Usuwanie plików „cookies”</b></p>
            <p>
            Korzystanie z plików „cookies” jest w pełni dobrowolne. Użytkownik może w każdym czasie zablokować i usunąć pliki „cookies” z poziomu używanego przez niego oprogramowania (przeglądarki internetowej).
            </p>
<ul>
Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej). W odniesieniu do najpopularniejszych przeglądarek internetowych konieczne informacje można uzyskać:
<li>• dla przeglądarki Mozilla Firefox pod adresem: <a href="www.support.mozilla.org">www.support.mozilla.org</a></li>
<li>• dla przeglądarki Internet Explorer pod adresem: <a href="www.support.microsoft.com">www.support.microsoft.com</a></li>
<li>• dla przeglądarki Google Chrome pod adresem: <a href="www.support.google.com">www.support.google.com</a></li>
<li>• dla przeglądarki Safari pod adresem: <a href="https://support.apple.com">www.support.apple.com</a></li>
</ul>
            <p>
            Administrator informuje, że standardowo przeglądarki internetowe domyślnie dopuszczają umieszczanie plików „cookies” na urządzeniu końcowym użytkownika. W konsekwencji w braku woli korzystania z plików „cookies” konieczne jest dokonania zmian w ustawieniach przeglądarki internetowej – aby blokować automatyczną obsługę plików „cookies” w ustawieniach przeglądarki internetowej lub informować o ich każdorazowym przesłaniu na urządzenie użytkownika.
            </p>
            <p>
            Administrator informuje, że usunięcie lub zablokowanie plików „cookies” może spowodować utrudnienia, a w niektórych przypadkach uniemożliwić korzystania z serwisu.
            </p>
            </div>
        </div>
    )
}

export default UsingCookies;