var settings = {
    "resultsOnPagePlaces": 12,
    "cardsSizePlaces": true,
    "sortingByPlaces" : 0,
    "resultsOnPageEvents": 12,
    "cardsSizeEvents": true,
    "sortingByEvents": 3,
    "userLocation": ""
}

export default settings;