import React, { useState } from "react";

function Pagination(props){

const paginationToShow = 5;

function getPages(){
  let result = [];
  const paginagtionCount = Math.ceil(props.collection.length / props.resultsOnPage);

  if (paginagtionCount <= 0){
    return result;
  }

    if (paginagtionCount <= paginationToShow){
    for (let i = 0; i < paginagtionCount; i++) {
      result.push(
    <li key={i} className={"page-item " + (props.pageNumber === i ? "active" : "")}>
        <a className="firstEventButton page-link" onClick={() => { paginationClick(i) }}>{i + 1}</a>
    </li>
      )
}
    } else {
      const halfIndexDown = Math.floor(paginationToShow / 2);

      let startIndex = 0;
      if (props.pageNumber <= 3){
        startIndex = 0;
      } else if ((props.pageNumber + 1) + halfIndexDown <= paginagtionCount){
        startIndex = props.pageNumber - halfIndexDown;
      } else {
        startIndex = paginagtionCount - paginationToShow;
      }
    
      for (let i = 0; i < paginationToShow; i++) {
        result.push(
          <li key={startIndex + i} className={"page-item " + (props.pageNumber === (startIndex + i) ? "active" : "")}>
                <a className="firstEventButton page-link" onClick={() => { paginationClick(startIndex + i) }}>{(startIndex + i) + 1}</a>
          </li>
            )
      }
    }

    return result;
}

function scrollToTop(){
  if (props.search !== undefined){
    props.search();
  } else {
    window.scrollTo({
      top: 0,
      behavior: "instant"
  })
  }
}

function paginationClick(value){
  props.setPageNumber(value);
  // console.log("page number: " + props.pageNumber);
  // console.log("resultsOnPage: " + props.resultsOnPage);
  scrollToTop();
}

function paginationClickPrevious(){
  if (props.pageNumber > 0){
    props.setPageNumber(props.pageNumber - 1);
  }
  scrollToTop();
}

function paginationClickNext(){
  if (props.pageNumber < (props.collection.length / props.resultsOnPage) - 1){
    props.setPageNumber(props.pageNumber + 1);
  }
  scrollToTop();
}

return (
      <div>
        <ul className="pagination justify-content-center">
        <li className={"page-item " + (props.pageNumber > 0 ? "" : "disabled")}>
          <a className="previousPagination page-link" onClick={() => { paginationClickPrevious() }} tabIndex="-1">Poprzednie</a>
        </li>
      {
        props.collection.length > 0 ? getPages() : null
      }
        <li className={"page-item " + (props.pageNumber < (props.collection.length / props.resultsOnPage) - 1 ? "" : "disabled")}>
          <a className="nextPagination page-link" onClick={() => { paginationClickNext() }}>Następne</a>
        </li>
      </ul>
      </div>
)
}

export default Pagination;