import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';

function CreateComment(props) {

  const [isExpanded, setExpanded] = useState(false);
  const [comment, setComment] = useState({
    rating: "5",
    content: ""
  });
  const [error, setError] = useState(null);

  function handleChange(event) {
    const { name, value } = event.target;

    setComment(prevComment => {
      return {
        ...prevComment,
        [name]: value
      };
    });
  }

  function submitComment(event) {
    if (props.userData === undefined || props.userData.status === undefined || props.userData.status < 0){
      return;
    }

    // if (comment.content.length <= 0){
    //   setError("Komentarz jest pusty.")
    //   return;
    // }

    props.onAddComment(comment);
    // setComment({
    //   rating: "5",
    //   content: ""
    // });
    event.preventDefault();
  }

  function expand(){
    setExpanded(true);
  }

  return (
    <div>
      <form className="create-comment">
        {
        //   isExpanded ? (<input
        //     name="title"
        //     onChange={handleChange}
        //     value={comment.title}
        //     placeholder="Title"
        //   />) : null
        isExpanded ? (
            <div className="comment-radio-stars d-flex justify-content-start">
                <p>Ocena: </p>
                <select name="rating" value={comment.rating} onChange={handleChange} id="">
                    <option value="5">5 ⭐⭐⭐⭐⭐</option>
                    <option value="4">4 ⭐⭐⭐⭐</option>
                    <option value="3">3 ⭐⭐⭐</option>
                    <option value="2">2 ⭐⭐</option>
                    <option value="1">1 ⭐</option>
                </select>

                {/* <div className="d-flex justify-content-around comment-radio-stars">
                <label>5</label>
                <input className="" checked="checked" name="radAnswer" type="radio"></input>
                <label>4</label>
                <input className="" name="radAnswer" type="radio"></input>
                <label>3</label>
                <input className="" name="radAnswer" type="radio"></input>
                <label>2</label>
                <input className="" name="radAnswer" type="radio"></input>
                <label>1</label>
                <input className="" name="radAnswer" type="radio"></input> */}

                {/* <button>⭐</button>
                <button>⭐</button> */}

            </div>

        )
        : null
        }
        <textarea
          name="content"
          onClick={expand}
          onChange={handleChange}
          value={comment.content}
          // placeholder="Napisz komentarz..."
          placeholder={isExpanded ? "Pozostawiając puste zostanie dodana jedynie ocena" : 
          "dodaj ocenę/komentarz"}
          rows={isExpanded ? 3 : 1}
        />
        {/* <button onClick={submitComment}>+</button> */}
        <Zoom in={isExpanded}>
          <div className="d-flex justify-content-start">
          <Button variant="contained" onClick={submitComment}>
          {/* <AddIcon /> */}
          Dodaj
        </Button>
          <p className="create-comment-error">{error}</p>
          </div>
        
        </Zoom>

      </form>
    </div>
  );
}

export default CreateComment;