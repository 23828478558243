import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer(props) {
  const year = new Date().getFullYear();

  function handleClick(category){
    props.handleClick(category);
    scrollWindowTop();
  };

  function scrollWindowTop(){
    window.scrollTo({
      top: 0,
      behavior: "instant"
  });
  };

  return (
    <footer id="footer" className="text-body-tertiary pt-14 pb-12" data-bs-theme="dark">
        <div className="container">
            <div className="footer-section mb-9 pb-5">
                <div className="row justify-content-md-center" data-cues="fadeIn" data-disabled="true">
                    <div className="footer-logo col-6 col-lg-2 col-md-4" data-cue="fadeIn" data-show="true" style={{animationName: "fadeIn", animationDuration: "1000ms", animationTimingFunction: "ease", animationDelay: "0ms", animationDirection: "normal", animationFillMode: "both"}}>
                        <a href="https://www.krakow.pl/">
                            <figure>
                              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/logos/krakow.pl-logo-v2.webp'} alt=""></img>
                            </figure>
                        </a>
                    </div>
                    <div className="footer-logo col-6 col-lg-2 col-md-4" data-cue="fadeIn" data-show="true" style={{animationName: "fadeIn", animationDuration: "1000ms", animationTimingFunction: "ease", animationDelay: "0ms", animationDirection: "normal", animationFillMode: "both"}}>
                        <a href="https://armk.pl/">
                            <figure>
                              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/logos/ARMK-logo.png'} alt=""></img>
                            </figure>
                        </a>
                    </div>
                    <div className="footer-logo col-6 col-lg-2 col-md-4" data-cue="fadeIn" data-show="true" style={{animationName: "fadeIn", animationDuration: "1000ms", animationTimingFunction: "ease", animationDelay: "0ms", animationDirection: "normal", animationFillMode: "both"}}>
                        <a href="https://kbf.krakow.pl/">
                            <figure>
                              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/logos/kbf-logo.jpg'} alt=""></img>
                            </figure>
                        </a>
                    </div>
                    <div className="footer-logo col-6 col-lg-2 col-md-4" data-cue="fadeIn" data-show="true" style={{animationName: "fadeIn", animationDuration: "1000ms", animationTimingFunction: "ease", animationDelay: "0ms", animationDirection: "normal", animationFillMode: "both"}}>
                        <a href="https://european-union.europa.eu/index_pl">
                            <figure>
                              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/logos/fundusze-eu.png'} alt=""></img>
                            </figure>
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-section">
                <div className="row" data-cues="fadeIn" data-disabled="true">
                    <div className="col-12 col-xl-3 col-lg-12 col-md-6 footer-column" data-cue="fadeIn" data-show="true" style={{animationName: "fadeIn", animationDuration: "1000ms", animationTimingFunction: "ease", animationDelay: "0ms", animationDirection: "normal", animationFillMode: "both"}}>
                        <div className="mb-8">
                              <Link to={"/"} name="Start">
                                <span className="logo-name">znane<span>M</span>iasto.pl</span>
                              </Link>
                            <p>
                                <em className="footer-tone">Portal powstał pod koniec 2023 roku, w szczególności dla osób, które nie znają miasta, ale również dla wszystkich, którzy chcieliby na bieżąco wiedzieć co dzieje się w ich mieście.</em>
                                <br></br>
                                <Link to={"onas"}>czytaj dalej</Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-lg-4 col-md-6 footer-column" data-cue="fadeIn" data-show="true" style={{animationName: "fadeIn", animationDuration: "1000ms", animationTimingFunction: "ease", animationDelay: "0ms", animationDirection: "normal", animationFillMode: "both"}}>
                        <div className="mb-8">
                            <h5 className="h6 mb-3 text-uppercase ff-sub ls-1 footer-title">kontakt</h5>
                            <div className="pt-2">
                                <p>
                                    <span className="footer-light-tone">znanemiasto.pl</span>
                                </p>
                                <p>
                                    <span className="footer-light-tone">Plac Wolnica 14</span>
                                </p>
                                <p>
                                    <span className="footer-light-tone">31-060, Kraków</span>
                                </p>
                                <p>
                                    <span className="footer-light-tone">NIP: 6792975386</span>
                                </p>
                                <p>
                                  <a href="mailto:kontakt@znanemiasto.pl">kontakt@znanemiasto.pl</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-lg-4 col-md-6 footer-column" data-cue="fadeIn" data-show="true" style={{animationName: "fadeIn", animationDuration: "1000ms", animationTimingFunction: "ease", animationDelay: "0ms", animationDirection: "normal", animationFillMode: "both"}}>
                        <div className="mb-8">
                            <h5 className="h6 mb-3 text-uppercase ff-sub ls-1 footer-title">linki</h5>
                            <div className="row">
                                <div className="col-6">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link to={"/"} className="footer-link">Strona główna</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={"onas"} className="footer-link">O nas</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={"artykuly"} className="footer-link">Artykuły</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={"kontakt"} className="footer-link">Kontakt</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={"logowanie"} className="footer-link">Logowanie</Link>
                                        </li>
                                        <li className="nav-item">
                                          <Link to={"rejestracja"} className="footer-link">Rejestracja</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link to={"szukaj"} className="footer-link">Szukaj</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={"eventy"} onClick={() => handleClick("Events")} className="footer-link">Eventy</Link>
                                        </li>
                                        <li className="nav-item">
                                          <Link to={"kultura/muzea"} onClick={() => handleClick("Muzea")} className="footer-link">Muzea</Link>
                                        </li>
                                        <li className="nav-item">
                                          <Link to={"kultura/zabytki"} onClick={() => handleClick("Zabytki")} className="footer-link">Zabytki</Link>
                                        </li>
                                        <li className="nav-item">
                                          <Link to={"gastronomia/restauracje"} onClick={() => handleClick("Restauracje")} className="footer-link">Restauracje</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-lg-4 col-md-6 footer-column" data-cue="fadeIn" data-show="true" style={{animationName: "fadeIn", animationDuration: "1000ms", animationTimingFunction: "ease", animationDelay: "0ms", animationDirection: "normal", animationFillMode: "both"}}>
                        <div className="mb-8">
                            <div className="mb-6">
                                <h5 className="h6 mb-3 text-uppercase ff-sub ls-1 footer-title">Pobierz aplikacje</h5>
                                <p className="footer-light-tone">(dostępne wkrótce)</p>
                                <div className="pt-4">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                              <img src={process.env.PUBLIC_URL + "/images/logos/google-play-badge.png"} className="img-fluid" alt=""></img>
                                        </li>
                                        <li className="list-inline-item">
                                              <img src={process.env.PUBLIC_URL + "/images/logos/apple-badge.svg"} className="img-fluid" alt=""></img>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- Social --> */}
                            {/* <div class="mb-6">
                                <h5 class="h6 mb-3 text-uppercase ff-sub ls-1">Connect social</h5>
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        <a href="#" class="link-hover-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#" class="link-hover-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-twitter" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c0 -.249 1.51 -2.772 1.818 -4.013z"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#" class="link-hover-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-youtube" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 5m0 4a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4z"></path>
                                                <path d="M10 9l5 3l-5 3z"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#" class="link-hover-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-pinterest" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M8 20l4 -9"></path>
                                                <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#" class="link-hover-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-tripadvisor" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M6.5 13.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0"></path>
                                                <path d="M17.5 13.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0"></path>
                                                <path d="M17.5 9a4.5 4.5 0 1 0 3.5 1.671l1 -1.671h-4.5z"></path>
                                                <path d="M6.5 9a4.5 4.5 0 1 1 -3.5 1.671l-1 -1.671h4.5z"></path>
                                                <path d="M10.5 15.5l1.5 2l1.5 -2"></path>
                                                <path d="M9 6.75c2 -.667 4 -.667 6 0"></path>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                            {/* <!-- /Social --> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-9">
                <div className="row">
                    <div className="col-12 col-md-6 footer-tone footer-column">
                      <p>ⓒ {year} znanemiasto.pl. Wszelkie prawa zastrzeżone.</p>
                    </div>
                    <div className="col-12 col-md-6 footer-column">
                        <div className="text-start text-md-end">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <Link to={"polityka-prywatnosci"} className="footer-link">Polityka Prywatności</Link>
                                </li>
                                |&nbsp;&nbsp;
                                <li className="list-inline-item">
                                    <Link to={"cookies"} className="footer-link">Użycie plików cookies</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}

export default Footer;
