import React, { useState, useEffect } from "react";
// import Card from "./Card";
import FetchPlaces from "./FetchPlaces";
import "./Cards.css";
import { Link } from "react-router-dom";
import SortingPanel from "./SortingPanel";
import settings from "./settings.js";
import CategoryTitle from "./CategoryTitle.js";

function Cards(props){

    const [resultsOnPage, setResultsOnPage] = useState(settings.resultsOnPagePlaces);
    // const [pageNumber, setPageNumber] = useState(0);
    const [cardsSize, setCardsSize] = useState(settings.cardsSizePlaces);
    const [isSorted, setIsSorted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // let placesArray = props.places.slice((0 + (props.pageNumber * props.resultsOnPage)), 
    // ((props.pageNumber * props.resultsOnPage) + props.resultsOnPage));
    // const placesArray = props.places.slice((0 + (pageNumber * resultsOnPage)), 
    // ((pageNumber * resultsOnPage) + resultsOnPage));
    let placesCheck = false;

    useEffect(() => {
        checkPlaces();

        window.scrollTo({
          top: 0,
          behavior: "instant"
      });

      manualLoader();
      }, []);

    // function handleRedirectToForm(){
    //     props.changeState("Contact");
    // }

    async function manualLoader(){
        if (props.places !== undefined && props.places.length <= 0){
            await timeout(3000);
        }
        setIsLoading(false);
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
     };

     function setResultsOnPageWithSave(value){
        setResultsOnPage(value);
        settings.resultsOnPagePlaces = value;
     }

     function setCardsSizeWithSave(value){
        setCardsSize(value);
        settings.cardsSizePlaces = value;
     }

    function checkPlaces(){
        if (placesCheck){
            return;
        } else{
            placesCheck = true;
        }

        if (props.places !== undefined && props.places.length <= 0){
            props.fetchPlaces(props.category);
        }
    }

    function handleCardsSizeClick(){
        // setCardsSize(!cardsSize);
        setCardsSizeWithSave(!cardsSize);
        // console.log(cardsSize);
    }

    return (
        <div>
            <div className="">
                {/* <div className="">
                <h1>{getCategoryString(props.category)}</h1>
                </div> */}
                <CategoryTitle category={props.category}></CategoryTitle>

                {/* {props.places !== undefined && props.places.length > 0 && props.loading === false ? */}
                {props.places !== undefined && props.places.length > 0 && props.loading === false ?
                <SortingPanel resultsOnPage={resultsOnPage} setResultsOnPage={setResultsOnPageWithSave} cardsSize={handleCardsSizeClick} 
                collection={props.places} setCollection={props.setPlaces} setLoading={props.setLoading} 
                // target={"place"} setPageNumber={props.setPageNumber} />
                target={"place"} setPageNumber={props.setPageNumber} isSorted={isSorted} setIsSorted={setIsSorted} />
                : null}
            </div>
            
            {/* {props.loading || props.places.length <= 0 || isSorted === false ? <div className="loader"></div> : */}
            {props.loading || (props.places.length > 0 && isSorted === false) ? <div className="loader"></div> :
            isLoading === true ? <div className="loader"></div> :
            cardsSize === true ?
            <FetchPlaces places={props.places} showProfile={props.showProfile} cardsSize={cardsSize}
            resultsOnPage={resultsOnPage} setResultsOnPage={setResultsOnPageWithSave} 
            pageNumber={props.pageNumber} setPageNumber={props.setPageNumber} search={props.search} />
            :
            <div className="card-deck card-deck-cards">
                <FetchPlaces places={props.places} showProfile={props.showProfile} cardsSize={cardsSize} 
                resultsOnPage={resultsOnPage} setResultsOnPage={setResultsOnPageWithSave} 
                pageNumber={props.pageNumber} setPageNumber={props.setPageNumber} search={props.search} />
                </div>
        }
            

            {props.places !== undefined && props.places.length <= 0 && props.loading === false && isLoading === false ? 
        <div className="cards-bottom-text">
            <p>Trochę tu pusto...</p>
            <p>możesz to zmienić</p>
            </div> :
            null
        }

            <div className="cards-bottom-text">
                {/* <a onClick={handleRedirectToForm}>Znasz inne ciekawe miejsce? Kliknij i wyślij nam formularz</a> */}
                {/* <a href="#" onClick={handleRedirectToForm}>Znasz inne ciekawe miejsce? Kliknij i wyślij nam formularz</a> */}
                <Link to={"/kontakt"}>Znasz inne ciekawe miejsce? Kliknij i wyślij nam formularz</Link>
            </div>
        </div>
    );
};

export default Cards;