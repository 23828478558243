import React from "react";

const currentInfo = [
    {
        id: 1,
        date: "09/01/2023", //month / day till but not include / year
        title: "<b>1.09.2023</b> <br />Oficjalny start portalu",
        content: "Skoro uczniownie w tym roku będą szli do szkół później, to chociaż my wystartujemy tak jak należy. <br />Na 1szy września zaplanowany jest oficlajny start portalu wolne.info. <br />Gratutulemy i zarazem dziękujemy wszystkim którzy biorą udział w tej przygodzie i tworzą z nami znanemiasto.pl :)",
        author: "GG"
    }
]

export default currentInfo;