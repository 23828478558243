import React, { useEffect, useState } from "react";
import Card from "./Card";
import Pagination from "./Pagination";

function FetchPlaces(props){

  const placesArray = () => {
    return props.places.slice((0 + (props.pageNumber * props.resultsOnPage)), 
        (props.pageNumber * props.resultsOnPage) + props.resultsOnPage);
  };

return(
<div>

{props.places !== undefined && props.places.length > 0 ?
<Pagination resultsOnPage={props.resultsOnPage} pageNumber={props.pageNumber} setPageNumber={props.setPageNumber} 
collection={props.places} search={props.search} />
: null }

{
  props.places !== undefined && props.places.length > 0 ?
  (
    // props.places.slice((0 + (props.pageNumber * props.resultsOnPage)), 
    // // ((props.pageNumber * props.resultsOnPage) + props.resultsOnPage)).map((place) =>{
    //   // ((props.pageNumber * props.resultsOnPage)) + props.resultsOnPage).map((place) =>{
    //     (props.pageNumber * props.resultsOnPage) + props.resultsOnPage).map((place, index) =>{
      placesArray().map((place) => {
        return(
          <Card
        key={place._id}
        cardsCategory={"places"}
        place={place}
        showProfile={props.showProfile}
        cardsSize={props.cardsSize}
        />
        )
      })
  ) :
  // props.loading ? <div className="loader"></div> : null
   null}
    {props.places !== undefined && props.places.length > 0 ?
    <Pagination resultsOnPage={props.resultsOnPage} pageNumber={props.pageNumber} 
    setPageNumber={props.setPageNumber} collection={props.places} search={props.search} />
      : null}
      </div>
);
};

export default FetchPlaces;