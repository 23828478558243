import './App.css';
import React from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Home from "./Home";
import Cards from "./Cards";
import Profile from "./Profile";
import { useState, useEffect } from 'react';
import axios from "axios";
import Users from "./users/Users";
import Cookies from 'universal-cookie';
import raw from "./bannedWords.txt";
import ContactForm from './ContactForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route, Link, NavLink, Navigate, useParams, useNavigate } from 'react-router-dom';
import RegisterSuccess from './users/RegisterSuccess';
import CookieConsent from "react-cookie-consent";
import Articles from './articles/Articles';
import Login from './users/Login';
import Register from './users/Register';
import UserAccount from './users/UserAccount';
import Article from './articles/Article';
import Events from './Events';
import Search from './Search';
import settings from './settings';
import AdminPanel from './users/AdminPanel';
import About from './About';
import PasswordReset from './users/PasswordReset';
import PrivacyPolicy from './PrivacyPolicy';
import UsingCookies from './UsingCookies';
import CategoryInformation from './CategoryInformation';


function App() {

  const [places, setPlaces] = useState([]);
  const [events, setEvents] = useState([]);
  const [state, setState] = useState("Start");
  const [userLogged, setUserLogged] = useState(false);
  const [currentPlace, setCurrentPlace] = useState({});
  const [currentArticle, setCurrentArticle] = useState({});
  const [loading, setLoading] = useState(false);
  const [addressIp, setAddressIp] = useState("");

  //SEARCH RESULTS
  const [tags, setTags] = useState([]);
  const [searchCategory, setSearchCategory] = useState("miejsca");
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);

  //SORTING
  const [pageNumber, setPageNumber] = useState(0);

  let [userData, setUserData] = useState({
        username: "",
        email: "",
        active: false,
        created: {},
        status: 0,
        _id: "",
        adminId: ""
  });
  let cookieCheck = false;
  let bannedWords = [];
  let loginChecked = false;
  const sessionHoldTime = 10800;

  // const getData = async () => {
  //   const res = await axios.get("https://api.ipify.org/?format=json");
  //   setAddressIp(res.data.ip);
  //   sendReport(res.data.ip);
  // };

  useEffect(() => {

    checkForCookie();
    // checkForLoggedIn();
    refreshUserLocation();
    checkForWww();
    // getData();
    // sendReport();
  },[]);

  // const [sortdown, setsortdown] = useState("domyślnie");

  // function sortPlaces() {
  //   const copy = [...places];
  //   if (sortdown) {
  //     // copy.sort(function(a, b) {
  //     //   return b - a;
  //     // });
  //     copy.sort(function(a,b){
  //       let x = a.title.toLowerCase();
  //       let y = b.title.toLowerCase();
    
  //        if(x>y){return 1;} 
  //        if(x<y){return -1;}
  //        return 0;
  //      });
  //   } else {
  //     copy.sort(function(a, b) {
  //       return a - b;
  //     });
  //   }
  //   setsortdown((prev) => !prev);
  //   setPlaces(copy);
  //   console.log("sorted");
  // }

  function sendReport(ip){
    // axios.post(process.env.REACT_APP_COUNT_VIEW_URL).then(result =>{
    // }).catch(err =>{
    //     console.log(err);
    // })
    axios({
      method: "POST",
      data: {
        ip: ip
      },
      withCredentials: true,
      url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_COUNT_VIEW_URL,
    }).catch((err) =>[
      console.log(err)
    ]);
}

  function checkForWww(){
    if (window.location.host.startsWith("www")){
      if (window.location.protocol === "https:"){
        window.location = window.location.protocol + "//" + window.location.host.slice(4, window.location.host.length) + window.location.pathname;
      }
    }

    if (window.location.host[0] === "w") {
      window.location = process.env.REACT_APP_HOME_PAGE;
    }
  }

  function changeState(category){
    // console.log("change state: " + category);
    switch (category){
      case "Start":
        setPageNumber(0);
        break;
        case "Articles":
          setPageNumber(0);
          break;
        case "User":
          case "UserLogin":
            case "UserRegister":
          break;
          case "UserLoggedIn":
            case "zalogowano pomyślnie":
            setUserLogged(true);
            toast.success("zalogowano pomyślnie");
            break;
            case "UserLoggedOut":
              case "wylogowano pomyślnie":
                userLoggedOut();
              // setUserLogged(false);
              // userData = null;
              // userData = undefined;
              // userData.username = "";
              // setUserData({
              //   username: "",
              //   email: "",
              //   active: false,
              //   created: {},
              //   status: 0,
              //    _id: ""
              // })
              // destroyCookie();
              // toast.success("wylogowano pomyślnie");
              break;
              case "Contact":
                break;
        case "Muzea":
          case "Teatry":
            case "Kina":
              case "Zabytki":
                case "KulturaInne":
                  case "Biblioteki":
                    case "Kursy":
                      case "Księgarnie":
                        case "TargiIKiermasze":
                          case "NaukaInne":
                            case "Restauracje":
                              case "FastFoody":
                                case "Fast Foody":
                                  case "fastFoody":
                                case "Kawiarnie":
                                  case "Desery":
                                    case "GastronomiaInne":
                          setPageNumber(0);
        fetchPlaces(category);
        break;
        default:
          // console.log("Uknknown behaviour");
          break;
    }

    handleChangeState(category);
  };

  function userLoggedOut(){
    setUserLogged(false);
    setUserData({
      username: "",
      email: "",
      active: false,
      created: {},
      status: 0,
       _id: "",
       adminId: ""
    })
    destroyCookie();
    toast.success("wylogowano pomyślnie");
  }

  function checkUser(user){
    axios.post(process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_USER_CHECK_URL, {
        user: user
    }).then(response =>{
        checkUserResponse(response);
    }).catch(err =>{
        console.log(err);
        // destroyCookie();
        checkUserResponse(err);
        return;
    });
  };

  function checkUserResponse(response){

    if (response === undefined || response._id === undefined || response.username === undefined || response.status === undefined){
      console.log("Check for null user");
      destroyCookie();
      return;
    };

    if (response.status < 0){
      destroyCookie();
      return;
    }

    setUserData({
      username: response.username,
      email: response.email,
      active: response.active,
      created: response.created,
      status: response.status,
      _id: response._id,
      adminId: response.adminId
    });

    setUserLogged(true);

  }

  function createCookie(data){

    if (data === null)
    return;

    const cookies = new Cookies();
    data.sessionStarted = new Date();
    cookies.set('account', data, { path: '/' });
  };

  function destroyCookie(){
    const cookies = new Cookies();
    cookies.remove("account");
    // console.log("Cookies destroyed: " + cookies.get('account'));
  }

  function checkForCookie()
  {
    const cookies = new Cookies();
    if (cookieCheck === false){
      cookieCheck = true;

      const cookie = cookies.get("account");

      if (cookie === undefined || cookie === null || cookie === "")
      {
        return;
      }

      if (cookie.username === undefined || cookie.email === undefined || cookie._id === undefined || cookie.status === undefined
      || cookie.status < 0){
        destroyCookie();
        return;
      }

        if (cookie){
          // console.log(cookie);
          // console.log(Math.round((new Date() - new Date(cookie.sessionStarted)) / 1000));

          if (Math.round((new Date() - new Date(cookie.sessionStarted)) / 1000) > sessionHoldTime){
            destroyCookie();
            return;
          }

        setUserData({
          username: cookie.username,
          email: cookie.email,
          active: cookie.active,
          created: cookie.created,
          status: cookie.status,
          _id: cookie._id,
          adminId: cookie.adminId
        });

        setUserLogged(true);
      }
    }
  }

  function checkForLoggedIn(){
    if (loginChecked){
      return;
    } else {
      loginChecked = true;
    };

    axios.get(process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_LOGGED_IN_CHECK).then(result =>{
      console.log(result);

      const user = result.data;
      setUserData({
        username: user.username,
        email: user.email,
        active: user.active,
        created: user.created,
        status: user.status,
        _id: user._id,
        adminId: user.adminId
      });
    }).catch(err =>{
      console.log(err);
    })
  }

  function fetchPlaces(category){
    // console.log("fetch: " + category);
    if (category === undefined || category === null)
      return;

      if (category === "Fast Foody"){
        category = "FastFoody";
      }

    setLoading(true);
    setPageNumber(0);

    // axios.get(process.env.REACT_APP_FETCH_PLACES_URL + category).then(result =>{
    //   setLoading(false);
    //   setPlaces(result.data);
    // }).catch(err =>{
    //   console.error(err);
    // });
    axios({
      method: "POST",
      data: {
        ip: addressIp
      },
      withCredentials: true,
      url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_FETCH_PLACES_URL + category,
    }).then((res) => {
      setPlaces(res.data);
      setLoading(false);
    }).catch((err) =>[
      console.log(err)
    ]);
  };

  function fetchEvents(){
    setLoading(true);
    setPageNumber(0);

    // axios.get(process.env.REACT_APP_FETCH_EVENTS_URL).then(result =>{
    //   setLoading(false);
    //   setEvents(result.data);
    // }).catch(err =>{
    //   console.error(err);
    // });
    axios({
      method: "POST",
      data: {
        ip: addressIp
      },
      withCredentials: true,
      url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_FETCH_EVENTS_URL,
    }).then((res) => {
      setEvents(res.data);
      setLoading(false);
    }).catch((err) =>[
      console.log(err)
    ]);
  };

  // async function getPlaces(category, id = null){
  //   if (places === undefined){
  //     await fetchPlaces(category);
  //   }

  //   if (id === null){
  //     return places;
  //   }
  //   else{
  //     return 
  //   }
  // }

  function handleChangeState(value){
    setState(value);
    // console.log("State changed to: " + value);
  }

  function showProfile(place){
    setCurrentPlace(places.find(element => element._id === place.id));
    changeState("Profile");
  }

  function getBannedWords(){
    let bannedArray = [];
    fetch(raw).then(r => r.text()).then(text =>{
      const textArray = text.split(/\r?\n/);
      textArray.forEach(item =>{
        bannedArray.push(item);
      })
    })
    return bannedArray;
  };

  return (
    <BrowserRouter>
    
      <Navbar handleClick={changeState} userLogged={userLogged} setUserState={setState} userData={userData} />
      <div className='appBody'>
      <Routes>
        <Route path='/' element={<Home cookieExist={checkForCookie} setCurrentPlace={setCurrentPlace} 
        changeState={changeState} setCurrentArticle={setCurrentArticle} />} />
        <Route path='artykuly' element={<Articles currentArticle={currentArticle} setCurrentArticle={setCurrentArticle} />} />
          {/* <Route path='artykuly/:id' element={<Article article={currentArticle} setCurrentArticle={setCurrentArticle} />} /> */}
          {/* <Route path='artykuly/:id' element={<Article article={currentArticles[0]} />} /> */}
          <Route path='artykuly/:id' element={<Article />} />
        <Route path='kontakt' element={<ContactForm userLogged={userLogged} userData={userData} changeState={changeState}/>} />
        <Route path='onas' element={<About userLogged={userLogged} userData={userData} changeState={changeState}/>} />

        <Route path='polityka-prywatnosci' element={<PrivacyPolicy userLogged={userLogged} userData={userData} changeState={changeState}/>} />
        <Route path='cookies' element={<UsingCookies userLogged={userLogged} userData={userData} changeState={changeState}/>} />
        {/* <Route path=':category/:subcategory/profil' element={<Profile place={ currentPlace } userLogged={userLogged} 
        userData={userData} bannedWords={getBannedWords()} changeState={changeState} />} /> */}

        
        <Route path=':category/:subcategory/profil/:id' element={<Profile target={"place"} places={ places } userLogged={userLogged} loading={loading} 
        userData={userData} bannedWords={getBannedWords()} addressIp={addressIp} />} />

        {/* <Route path=':category/:subcategory/profil_event/:id' element={<ProfileEvent userLogged={userLogged} loading={loading}
        userData={userData} bannedWords={getBannedWords()} addressIp={addressIp} />} /> */}

        
        <Route path=':category/:subcategory/profil_event/:id' element={<Profile target={"event"} userLogged={userLogged} loading={loading}
        userData={userData} bannedWords={getBannedWords()} addressIp={addressIp} />} />

        <Route path="szukaj" element={<Search changeState={changeState} loading={loading} setLoading={setLoading}
         pageNumber={pageNumber} setPageNumber={setPageNumber} addressIp={addressIp} 
         tags={tags} setTags={setTags} searchCategory={searchCategory} setSearchCategory={setSearchCategory}
         searchText={searchText} setSearchText={setSearchText} results={results} setResults={setResults}/>} />

        <Route path="eventy" element={<Events category={ "Eventy" } changeState={changeState} loading={loading} setLoading={setLoading}
         events={events} setEvents={setEvents} fetchEvents={fetchEvents} pageNumber={pageNumber}
         setPageNumber={setPageNumber} />} />
        
        {/* <Route path='logowanie' element={<Login showLoginBeforeRegister={setState} />} /> */}
        <Route path='logowanie' element={
          userData.username === "" ?
          <Login setUserData={setUserData} setUserState={changeState} createCookie={createCookie} addressIp={addressIp} /> :
          <Navigate to={"/"} />
        } />
        {/* <Route path='logowanie' element={<Login setUserData={setUserData} setUserState={setState} createCookie={createCookie} />} /> */}
        {/* <Route path='uzytkownik' element={<Users state={state} setUserState={changeState} userData={userData} 
        setUserData={setUserData} createCookie={createCookie} bannedWords={getBannedWords()} userLogged={userLogged} />} /> */}
        {/* <Route path='uzytkownik' element={<UserAccount userData={userData} setState={setState} />} /> */}
        <Route path='uzytkownik' element={
          userData.username !== "" ?
        <UserAccount userData={userData} setState={changeState} addressIp={addressIp} /> :
        <Navigate to={"/"} />
        } />

        <Route path='adminPanel' element={
          userData.username !== "" && checkUserTargetId() !== undefined && checkUserTargetId() !== null ?
        <AdminPanel userData={userData} setState={changeState} addressIp={addressIp} /> :
        <Navigate to={"/"} /> } />

        {/* <Route path='rejestracja' element={<Register showLoginBeforeRegister={setState} bannedWords={getBannedWords()} />} /> */}
        <Route path='rejestracja' element={
          userData.username === "" ?
        <Register setUserData={setUserData} setUserState={changeState} createCookie={createCookie} 
        showLoginBeforeRegister={setState} bannedWords={getBannedWords()} addressIp={addressIp} /> :
        <Navigate to={"/"} />
        } />
        <Route path='rejestracja/:status' element={<RegisterSuccess />} />

        <Route path='resetHasla' element={
        <PasswordReset userData={userData} setState={changeState} addressIp={addressIp} />} />

        <Route path='resetHasla/:veriString' element={
        <PasswordReset userData={userData} setState={changeState} addressIp={addressIp} />} />

        <Route path='informacje/:category' element={<CategoryInformation userLogged={userLogged} userData={userData} changeState={changeState}/>} />

        <Route path='kultura'>
        {/* <Route path={"muzea"} element={<Cards places={ places } category={ state } changeState={changeState} showProfile={showProfile} />} /> */}
        {/* <Route path="muzea" element={<Cards places={ places } category={ state } changeState={changeState} showProfile={showProfile} />}>
          <Route path=':id' element={<Profile places={ places } userLogged={userLogged} 
        userData={userData} bannedWords={getBannedWords()} />} />
          </Route> */}

{/* <Route path=':subcategory/:id' element={<Profile places={ places } userLogged={userLogged} 
                  userData={userData} bannedWords={getBannedWords()} />} /> */}

        <Route path="muzea" element={<Cards places={ places } setPlaces={setPlaces} category={ "Muzea" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"teatry"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Teatry" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"kina"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Kina" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"zabytki"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Zabytki" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"kulturaInne"} element={<Cards places={ places } setPlaces={setPlaces} category={ "KulturaInne" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        {/* <Route path=':places/profile' element={<Profile place={ currentPlace } userLogged={userLogged} 
        userData={userData} bannedWords={getBannedWords()} changeState={changeState} />} /> */}
        </Route>

        <Route path='gastronomia'>
        <Route path={"restauracje"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Restauracje" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"fastFoody"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Fast Foody" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"kawiarnie"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Kawiarnie" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"desery"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Desery" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        />} />
        <Route path={"gastronomiaInne"} element={<Cards places={ places } setPlaces={setPlaces} category={ "GastronomiaInne" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        </Route>

        <Route path='nauka'>
        <Route path={"biblioteki"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Biblioteki" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"ksiegarnie"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Księgarnie" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"kursy"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Kursy" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        <Route path={"targi"} element={<Cards places={ places } setPlaces={setPlaces} category={ "Targi i kiermasze" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        />} />
        <Route path={"naukaInne"} element={<Cards places={ places } setPlaces={setPlaces} category={ "NaukaInne" } changeState={changeState} 
        showProfile={showProfile} fetchPlaces={fetchPlaces} loading={loading} setLoading={setLoading} 
        pageNumber={pageNumber} setPageNumber={setPageNumber} />} />
        </Route>

        <Route path='imprezy'>
          <Route path={"koncerty"} element={<Events category={ "Eventy" } changeState={changeState} loading={loading} setLoading={setLoading}
         events={events} setEvents={setEvents} fetchEvents={fetchEvents} pageNumber={pageNumber}
         setPageNumber={setPageNumber} />} />
        </Route>
      </Routes>

      {/* <div className="appBody">
        {
          state === "Loading" ?
          <div>Ładowanie</div> :
          state === "Articles" ?
          <Articles currentArticle={currentArticle} setCurrentArticle={setCurrentArticle} /> :
          state === "Contact" ? 
          <ContactForm userLogged={userLogged} userData={userData} changeState={changeState}/> :
        state === "Start" || state === "UserLoggedIn" || state === "zalogowano pomyślnie" || state === "UserLoggedOut" ||
        state === "wylogowano pomyślnie" ?
        <Home cookieExist={checkForCookie} setCurrentPlace={setCurrentPlace} changeState={changeState} setCurrentArticle={setCurrentArticle} /> 
        : state === "Profile" ? 
        <Profile place={ currentPlace } userLogged={userLogged} userData={userData} bannedWords={getBannedWords()} changeState={changeState} /> : 
        state === "UserLogin" || state === "UserRegister" || state === "UserAccount" || state === "UserRegisterSuccess" || 
        state === "UserVerificationSuccess" || state === "UserVerificationFailure"
        ? <Users state={state} setUserState={changeState} userData={userData} setUserData={setUserData} createCookie={createCookie} 
        bannedWords={getBannedWords()} userLogged={userLogged} /> : 
        <Cards places={ places } category={ state } changeState={changeState} showProfile={showProfile} />
        }
        
      </div> */}
</div>
      <Footer handleClick={changeState} userData={userData} />
      <ToastContainer
position="top-left"
autoClose={1100}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<CookieConsent
  location="bottom"
  buttonText="Zgadzam się"
  cookieName="cookieInfo"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
>
Ta strona wykorzystuje pliki cookies w celu prawidłowego działania jej wszystkich funkcji.
</CookieConsent>
    
    </BrowserRouter>
  );
}

export function getTodayHours(place){

  if (place === undefined || place.hours === undefined){
    // return null;
    return {};
  }

  const day = new Date().getDay();
  let weekDay = "";

      switch (day) {
      case "monday":
          case 1:
          weekDay = "Pon: "
          break;
          case "thuesday":
              case 2:
              weekDay = "Wt: "
              break;
              case "wednesday":
                  case 3:
          weekDay = "Śr: "
          break;
          case "thursday":
              case 4:
          weekDay = "Czw: "
          break;
          case "friday":
              case 5:
          weekDay = "Pt: "
          break;
          case "saturday":
              case 6:
          weekDay = "Sob: "
          break;
          case "sunday":
              case 0:
          weekDay = "Nie: "
          break;
      default:
          break;
  }

          var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

          // if (place.hours[day].day !== days[day])
          // {
          //     return weekDay + "zamknięte";
          // };

          let searchDay = null;

          for (let i = 0; i < place.hours.length; i++) {
            if (place.hours[i].day === days[day]){
              searchDay = place.hours[i];
            }
          }

          if (searchDay === null){
            // return weekDay + "zamknięte";
            return {
              weekDay: weekDay,
              open: "",
              close: "nieczynne",
            }
          }
  
          let hours = {
            weekDay: "",
            open: "",
            close: ""
          };
          

  // hours = weekDay + place.hours[day].open + " : " + place.hours[day].close;
  // hours = weekDay + searchDay.open + " : " + searchDay.close;
  hours = {
    weekDay: weekDay,
    open: searchDay.open,
    // open: searchDay.open.length < 5 ? "0" + searchDay.open : searchDay.open,
    close: searchDay.close
    // close: searchDay.close.length < 5 ? "0" + searchDay.close : searchDay.close
  };
  return hours;
}

export function getPlaceHoursByDay(place, day){
  let hours = {
    weekDay: "",
    open: "",
    close: ""
  };

  const hoursArray = [{x: "Ndz", y: "sunday"}, {x: "Pon", y: "monday"}, {x: "Wt", y: "tuesday"},
    {x: "Śr", y: "wednesday"}, {x: "Czw", y: "thursday"}, {x: "Pt", y: "friday"},
    {x: "Sob", y: "saturday"}];

    if (place === undefined || day === undefined){
      return hours;
    }

    let tags = [];

    for (let i = 0; i < place.tags.length; i++) {
      tags.push(place.tags[i].name);
    }
  
    if (tags.includes("kościoły")){
      hours.open = "według mszy i nabożeństw"
      // hours.open = "wg mszy i nabożeństw"
      return hours;
    } else if (tags.includes("teatry") || tags.includes("kina")){
      hours.open = "według repertuaru"
      return hours;
    }

    let target = place.hours.find(item => item.day === hoursArray[day].y);

    if (target === undefined){
      return hours;
    }

    hours = {
      weekDay: hoursArray[day].x,
      open: target.open,
      // open: target.open.length < 5 ? "0" + target.open : target.open,
      close: target.close,
      // close: target.close.length < 5 ? "0" + target.close : target.close
    }

  return hours;
}

export function getShortDate(date){

  if (date === undefined || date === null || date === ""){
    return "";
  }

  const tempDay = new Date(date);
  let result = "";

  result = (tempDay.getDate().toString().length === 1 ? "0" + tempDay.getDate() : tempDay.getDate())
   + "." + ((tempDay.getUTCMonth() + 1).toString().length === 1 ? "0" + (tempDay.getUTCMonth() + 1) : (tempDay.getUTCMonth() + 1))
    + "." + tempDay.getFullYear();

  return result;
}

export function getDayNameByNumber(number){

  let returnDay = "";

  switch (number){
    case 0:
      returnDay = "Niedziela";
      break;
      case 1:
        returnDay = "Poniedziałek";
        break;
        case 2:
          returnDay = "Wtorek";
          break;
          case 3:
            returnDay = "Środa";
            break;
            case 4:
              returnDay = "Czwartek";
              break;
              case 5:
                returnDay = "Piątek";
                break;
                case 6:
                  returnDay = "Sobota";
                  break;
                  default:
                    returnDay = "none";
                    break;
  }

  return returnDay;
}

export function getDayName(day, month, year){

  let currentDay = new Date();
  currentDay.setDate(day);
  currentDay.setMonth(month.length > 1 && month.split("")[0] === "0" ? month.split("")[1] : month);
  currentDay.setFullYear(year);

  let returnDay = "";

  switch (currentDay.getDay()){
    case 0:
      returnDay = "Niedziela";
      break;
      case 1:
        returnDay = "Poniedziałek";
        break;
        case 2:
          returnDay = "Wtorek";
          break;
          case 3:
            returnDay = "Środa";
            break;
            case 4:
              returnDay = "Czwartek";
              break;
              case 5:
                returnDay = "Piątek";
                break;
                case 6:
                  returnDay = "Sobota";
                  break;
                  default:
                    returnDay = "none";
                    break;
  }

  return returnDay;
}

export function getMonthName(month){
  return month === 0 ? "Styczeń" : month === 1 ? "Luty" : month === 2 ? "Marzec" : month === 3 ? "Kwiecień" : month === 4 ? 
  "Maj" : month === 5 ? "Czerwiec" : month === 6 ? "Lipiec" : month === 7 ? "Sierpień" : month === 8 ?
  "Wrzesień" : month === 9 ? "Październik" : month === 10 ? "Listopad" : month === 11 ? "Grudzień" : "none";
}

export function getSecondaryMonthName(month){
  return month === 0 ? "Stycznia" : month === 1 ? "Luty" : month === 2 ? "Marca" : month === 3 ? "Kwietnia" : month === 4 ? 
  "Maja" : month === 5 ? "Czerwca" : month === 6 ? "Lipca" : month === 7 ? "Sierpnia" : month === 8 ?
  "Września" : month === 9 ? "Października" : month === 10 ? "Listopada" : month === 11 ? "Grudnia" : "none";
}

export function getEventDate(event){
  let eventArray = event.startDate.split('-');
  // let eventString = eventArray[2] + "." + eventArray[1] + "." + eventArray[0] + " " + 
  // getDayName(eventArray[2], parseInt(eventArray[1]) - 1, eventArray[0]);
  let eventString = eventArray[2] + "." + eventArray[1] + "." + eventArray[0];
  return eventString;
}

export function getEventHours(event){
  return event.hours !== undefined && event.hours !== null && event.hours[0] !== undefined &&
  event.hours[0] !== null ? event.hours[0].open : "";
}

export function getPhone(place){
  if (place.phone === null || place.phone === undefined){
      return "-"
  } else {
      return place.phone;
  };
};

export function getAddress(place){
  if (place.address === null || place.address === undefined){
      return "-"
  } else {
      return place.address;
  };
};

export function getSortedTags(place){
  if (place.tags === null || place.tags === undefined || place.tags.length <= 0){
      return;
  };

  let tagsArray = [];
  place.tags.forEach(element => {
      tagsArray.push(element);
  });
  return bblSort(tagsArray);
}

export function bblSort(arr) {

  for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < (arr.length - i - 1); j++) {
          if (arr[j] > arr[j + 1]) {
              var temp = arr[j]
              arr[j] = arr[j + 1]
              arr[j + 1] = temp
          }
      }
  }

  return arr;
};

export function getPlaceStarsRating(commentsAndRatings){
  if (commentsAndRatings === null || commentsAndRatings === undefined){
    return;
  };

  if (commentsAndRatings.rating === null || commentsAndRatings.rating === undefined || commentsAndRatings.rating <= 0){
    return;
  };

  return getStarsString(commentsAndRatings.rating);
};

export function getStarsString(value){

  const maxStarsRating = 5;

  if (value <= 0 || value > maxStarsRating){
    return;
  }

  // let stars = "";
  let stars = [];
  for (let i = 0; i < maxStarsRating; i++) {
    // stars = (i + 1) > value ? stars + "⭐" : stars + "⭐";
    // stars = stars + (i + 1 > value ? <img src={process.env.PUBLIC_URL + "/icons/stars-empty.png"} /> : 
    // <img src={process.env.PUBLIC_URL + "/icons/stars-full.png"} />);

    // stars.push(i + 1 > value ? <img key={i} src={process.env.PUBLIC_URL + '/icons/stars-empty.png'} /> : 
    // <img key={i} src={process.env.PUBLIC_URL + '/icons/stars-full.png'} />);

    stars.push(i + 1 <= value ? 1 : value.toString().length > 1 ? 2 : 0);
  };

  return stars;
}

export function getStarsImages(array){
  return(
    array.map((item, index) =>{
      return(
          <img key={index} src={process.env.PUBLIC_URL + (item === 0 ? '/icons/star-empty-white.png' : 
          item === 1 ? '/icons/star-full.png' : '/icons/star-half.png')} />
      )
  })
  )
}

export function getRating(commentsAndRatings, reviews){
  if (commentsAndRatings === null || commentsAndRatings === undefined || commentsAndRatings.length <= 0){
    return;
  };

  let value = 0;
  let index = 0;

  commentsAndRatings.forEach(item =>{
    value += item.rating;
    index++;
  })

  if (reviews !== undefined && reviews !== null && reviews.length > 0){
    reviews.forEach(item =>{
      value += item.rating; 
      index++;
    })
  }

  let rating = {
    finalRating: Math.round(((value / index) + Number.EPSILON) * 100) / 100,
    // finalRating: (value / index),
    votes: index,
    // stars: getStarsString(Math.floor((value / index)))
    stars: getStarsString(Math.round(((value / index) + Number.EPSILON) * 100) / 100)
  };

  return rating;
}

export function getPlaceHoursString(place){
  let tags = [];
  let result = "";

  for (let i = 0; i < place.tags.length; i++) {
    tags.push(place.tags[i].name);
  }

  if (tags.includes("kościoły")){
    // result = "wg. mszy i nabożeństw";
    result = "według mszy i nabożeństw";
  } else if (tags.includes("teatry") || tags.includes("kina")){
    result = "według repertuaru";
  } else {
    checkIsOpen(place) === false && (getPlaceHoursByDay(place, new Date().getDay()).open === "" || 
        getPlaceHoursByDay(place, new Date().getDay()).open === undefined) ? result = "nieczynne" : 
        checkIsWholeTimeOpen(place, new Date().getDay()) ? result = "całą dobę" :
        result = getTodayHours(place).weekDay + (getTodayHours(place).open === "" ? "" : 
        getTodayHours(place).open + " - ") + getTodayHours(place).close
  }

  return result;
}

export function checkIsOpen(place){
  const placeHours = getTodayHours(place);
  const dateNow = new Date();
  let tags = [];

  for (let i = 0; i < place.tags.length; i++) {
    tags.push(place.tags[i].name);
  }

  if (tags.includes("kościoły")){
    return true;
  } else if (tags.includes("teatry") || tags.includes("kina")){
    return true;
  }
  
  if (placeHours.open === "" || placeHours.close === ""){
    return false;
  }

  if (checkIsWholeTimeOpen(place, new Date().getDay())){
    return true;
  }

  // console.log(dateNow.getHours());
  // console.log(placeHours.open.split(":")[0]);
  // console.log(dateNow.getHours() < placeHours.close.split(":")[0]);

  if (dateNow.getHours() >= placeHours.open.split(":")[0] && dateNow.getHours() < placeHours.close.split(":")[0]){
    return true;
  }

  return false;
}

export function checkIsWholeTimeOpen(place, day){
  if(getPlaceHoursByDay(place, day).open === "00:00" && getPlaceHoursByDay(place, day).close === "00:00"){
    return true;
  }

  return false;
}

export const BackLink = (props) => {
  let navigate = useNavigate();
  return (
        <button className='btn btn-outline-primary' onClick={() => navigate(-1)}>{props.content}</button> 
        // <p className='backlink' onClick={() => navigate(-1)}>{props.content}</p> 
  );
};

export function refreshUserLocation(){
  if (navigator.geolocation){
    navigator.geolocation.getCurrentPosition((position) =>{
      var positionString = (position.coords.latitude).toString() + "," + (position.coords.longitude).toString();
      if (positionString !== settings.userLocation){
        settings.userLocation = positionString;
      }
    })
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
}

function getDistanceFromLatLng(lat1, lon1, lat2, lon2) {
  // var R = 6371; // Radius of the earth in km
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

// export function getDistanceInMeters(destination, setDistance){
//   if (navigator.geolocation){
//     var currentUserLocation = {};

//     function getDist(){
//       navigator.geolocation.getCurrentPosition((position) =>{
//         setPostion(position);
//       });
//   };

//     getDist();

//     function setPostion(position){
//       currentUserLocation = {lat: position.coords.latitude, lng: position.coords.longitude};

//       var pointLocation = destination;

//       if (typeof pointLocation === 'string' || pointLocation instanceof String){
//         pointLocation = {lat: parseFloat(pointLocation.split(",")[0]), lng: parseFloat(pointLocation.split(",")[1])};
//       }

//       if (currentUserLocation === undefined || currentUserLocation === null || pointLocation === undefined 
//         || pointLocation === null){
//           console.log("Cant calculate something is null.");
//           return;
//         }

//       var result = getDistanceFromLatLng(currentUserLocation.lat, currentUserLocation.lng, pointLocation.lat, pointLocation.lng);
//       setDistance(parseInt(result));
//     }
//   } else {
//     console.log("Geolocation is not supported by this browser.");
//     return null;
//   }
// }

export function getDistanceByUserLocation(destination){
  if (settings.userLocation === "" || settings.userLocation === undefined || settings.userLocation === null){
    return;
  }

  var currentUserLocation = {lat: parseFloat(settings.userLocation.split(",")[0]), 
  lng: parseFloat(settings.userLocation.split(",")[1])};
  var pointLocation = destination;

      if (typeof pointLocation === 'string' || pointLocation instanceof String){
        pointLocation = {lat: parseFloat(pointLocation.split(",")[0]), lng: parseFloat(pointLocation.split(",")[1])};
      }
  
      if (currentUserLocation === undefined || currentUserLocation === null || pointLocation === undefined 
        || pointLocation === null){
          console.log("Cant calculate something is null.");
          return;
        }

  var result = getDistanceFromLatLng(currentUserLocation.lat, currentUserLocation.lng, pointLocation.lat, pointLocation.lng);
  return result;
}

export function checkUserTargetId(){
  // return null;
  return 1;
}

export default App;