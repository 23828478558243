import React from "react";
import { BackLink } from "./App";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import "./Profile.css";
import { Link, useParams } from "react-router-dom";

function Crunches(props){
    const titleMaxSize = 15;

    function getShortedTitle(title){
        if (title.length > titleMaxSize){
            // return title.slice(0, titleMaxSize) + "...";
            return title;
        } else {
            return title;
        }
    }


    return(
        <div className="crunches container">
                <div className="row row-cols-auto">
            <div className="profile-header-back crunches-element">
            {/* <Link to={"/" + place.category.toLowerCase() + "/" + place.subcategory.toLowerCase()}><h4><i>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></i> Wróć</h4></Link> */}
            
            {/* <Link to={"javascript:history.back()"}><h4><i>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></i> Wróć</h4></Link> */}

                {/* <p onClick={() => useNavigate().goBack()}>
                <h4><i>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></i> Wróć
                </h4>
                </p> */}
                <BackLink content={<p><i>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></i>
                &nbsp;
                {props.customText === "" || props.customText === undefined ? "Wróć" : props.customText}
                </p>}/>
            </div>

            {props.target !== "" ?
            <div className="crunches-element">
            {/* <p>{place.category} - {place.subcategory} - {place.title}</p> */}
            <p className="crunches-crop">{props.target.category !== undefined && props.target.category !== "" ?
            (props.target.category + " " + (props.target.subcategory !== undefined ? "-" : "")) : ""}
            <Link to={
            (props.target.category !== undefined && props.target.category !== "" ? 
            ("/" + props.target.category.toLowerCase()) : "") + 
            (props.target.subcategory !== undefined && props.target.subcategory !== "" ? 
            ("/" + props.target.subcategory.toLowerCase()) : "")}>
                 {props.target.subcategory !== undefined ? (" "+ props.target.subcategory + " ") : ""}
                 </Link>
                 - {getShortedTitle(props.target.title)}</p>
                {/* <Link to={"/" + place.category.toLowerCase()}>
                 {place.subcategory}</Link> -  */}
            {/* <Link to={"javascript:history.back()"}> {place.subcategory}</Link>  */}

                 {/* <i>
                <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                </i>  {place.title}</p> */}
            </div>
            :
            null}
                </div>
            </div>
    )
};

export default Crunches;