import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import LoginGoogle from "./LoginGoogle";

function Login(props){

    const [userData, setUserData] = useState({
        email: "",
        password: "",
    });
    // const [googleUser, setGoogleUser ] = useState([]);
    const [error, setError] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "instant"
    })
    }, []);

    function handleChange(event){
        const newValue = event.target.value;
        const inputName = event.target.name;

        setUserData(prevValue =>{
            if (inputName === "email"){
                return {
                    email: newValue,
                    password: prevValue.password,
                };
            } else if (inputName === "password"){
                return {
                    email: prevValue.email,
                    password: newValue,
                };
            };
        });
    };

    function loginUser(email, pass){
      setLoading(true);
      axios({
        method: "POST",
        data: {
          email: email,
          password: pass,
          ip: props.addressIp
        },
        withCredentials: true,
        url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_LOGIN_USER_URL,
      }).then((res) => {
        setLoading(false);
        loginResponse(res);
      }).catch((err) =>{
        console.log(err);
      });

  //     fetch("http://localhost:4747/api/login", {
  //   method: "POST",
  //   body: {
      
  //   },
  //   headers: {
  //     "Content-Type": "application/json"
  //   },
  //   credentials: 'include'
  // })

    }

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }

    function isVallidPassword(pass){
        if (pass.length <= 0){
            setError("Wprowadź hasło.");
            return false;
        }
        // else if (pass.length < 6)
        // {
        //     setError("Hasło musi zawierać minimum 6 znaków.");
        //     return false;
        // }
        else{
            return true;
        }
    }

    const handleSubmit = (event) =>{
      event.preventDefault();
        if (!isValidEmail(userData.email))
        {
            if (userData.email === undefined || userData.email === "" || userData.email.length <= 0){
                setError("Wpisz email.");
            }else {
                setError("Nieprawidłowy email.");
            }
            return;
        }

        if (!isVallidPassword(userData.password))
        {
            return;
        }

        setError(null);

        // props.loginUser(userData.email, userData.password, handleLoginResponse);
        loginUser(userData.email, userData.password);
    }

    function loginResponse(response){

      if (response.data === "0"){
        // return "Niepoprawny login lub hasło.";
        setError("Niepoprawny login lub hasło.");
        return;
      }

      const status = response.status;
      // console.log(status);
      switch (status){
          case 881:
          return "Użytkownik o podanym emailu nie istnieje.";
          case 882:
          return "Podane hasło jest niepoprawne.";
          case 883:
              console.log(response);
              return ("Konto zostało zbanowane. Powód: " + (response.data.statusString !== undefined ? response.data.statusString : "nieznany" ));
          case 200:
              const { username, email, active, dataStatus, _id } = response.data;

              props.setUserData(response.data);
              props.setUserState("zalogowano pomyślnie");
              props.createCookie(response.data);

              // console.log(response.data);
              // redirect("/");
              // this.props.history.push("/");
              // redirect("/");
              // <Navigate to={"/"} replace={true} />
              setUserLoggedIn(true);

              break;
              default:
                  return "Unknown login error!";
      };
  };

    function handleLoginResponse(response){
        if (response === "+"){
            // setRegisterStatus(true);
            // toast("Logowanie poprawne")
        }
        else{
            setError(response);
        }
    };

    if (userLoggedIn){
      return <Navigate to={"/"} />
    } else {
    return(
      
        <div className="container">
  <h1>Logowanie</h1>

  <div className="row">
    <div className="col">
      <div className="card register-row">
        <div className="card-body">

          <form onSubmit={handleSubmit}>
            {/* <div className="form-group">
              <label htmlFor="email">email:</label>
              <input type="email" className="form-control" name="email" onChange={handleChange} value={userData.email}></input> */}
            <div className="form-group form-floating">
              <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com" name="email" onChange={handleChange} value={userData.email}></input>
              <label htmlFor="floatingEmail">E-mail</label>
            </div>
            {/* <div className="form-group">
              <label htmlFor="password">hasło:</label>
              <input type="password" className="form-control" name="password" onChange={handleChange} value={userData.password}></input> */}
            <div className="form-group form-floating">
              <input type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password" onChange={handleChange} value={userData.password}></input>
              <label htmlFor="floatingPassword">Hasło</label>
            </div>
            {/* <div className="form-group">
              <label for="password2">powtórz hasło:</label>
              <input type="password" className="form-control" name="password2" onChange={handleChange} value={userData.password2}></input>
            </div>
            <div className="recaptcha">
            <ReCAPTCHA sitekey={key} onChange={handleRecaptcha} />
            </div> */}
            {loading ? <div className="loader"></div> :
            <button type="submit" className="btn btn-primary">Zaloguj</button>
          }
          </form>
          <p className="register-error">{error}</p>
        </div>

        <div>
            <p className="d-flex justify-content-center">lub&nbsp;<Link className="login-register-link" to={"/resetHasla"}>Nie pamiętasz hasła?</Link></p>
        </div>

        <hr></hr>

        <div>
            {/* <a className="d-flex justify-content-center" href="#/" onClick={loginRegisterButtonClick}>Nie masz konta? Zarejestruj się</a> */}
            <p className="d-flex justify-content-center">Nie masz konta?&nbsp;<Link className="login-register-link" to={"/rejestracja"}>Zarejestruj się</Link></p>
        </div>



      </div>
    </div>

    <div className="col">
      <div className="card social-block register-row">
        <LoginGoogle loginResponse={loginResponse} />
      </div>
    </div>

  </div>
</div>
    );
  };

};

export default Login;