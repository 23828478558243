import React, { useEffect } from "react";
// import "./UserAccount.css";
import "./Users.css";
import Zoom from '@mui/material/Zoom';
import axios from "axios";
import { Link } from "react-router-dom";

function UserAccount(props){

    const formName = "Formularz";

    // console.log(props);
    // const {username, active, status, id} = props.userData;
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "instant"
      })
      }, []);

    function handleLogoutClick(){
        // props.setState("UserLoggedOut");
        // props.setState("wylogowano pomyślnie");
        userLoggedOut();
    };

    function userLoggedOut(){
        axios.get(process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_USER_LOGGED_OUT).then(result =>{
            props.setState("wylogowano pomyślnie");
        }).catch(err =>{
            console.log(err);
        })
    }

    function handleLoginClick(){
        props.setState("UserLogin");
    }

    // function handleAdminPanelClick(){
    //     window.location.href = "/adminPanel";
    // }

    function getAccountDate(){

        let date = props.userData.created
        // date = date.split("-").reverse().join("-");

        // let date = new Date().toUTCString();

        const minute = 1000 * 60;
        const hour = minute * 60;
        const day = hour * 24;

        const newDate = Date.parse(date);
        const dateNow = new Date();
        let days = Math.round((dateNow - newDate) / day);

        // let date = new Date(props.userData.created);

        // let date = props.userData.created;

        // let date = new Date.parse(props.userData.created);

        // date = props.userData.created;
        // console.log(newDate);

        return days;
        
        // console.log(props.userData.created);

        // return date;
    }

    return(
        props.userLogged ? 
        <div className="jumbotron">
            <p className="lead">Zarejestrowano pomyślnie</p>
            <p>możesz teraz się <a href="#/" onClick={handleLoginClick}>zalogować</a></p>
        </div> :
        <div className="userAccount">
            <h1>Konto</h1>
            <div className="jumbotron">
                {/* <button onClick={handleAdminPanelClick}>Panel Administracyjny</button> */}

                {props.userData.adminId === undefined || props.userData.adminId === null || props.userData.adminId === ""
                || props.userData.adminId === "0" || props.userData.adminId === 0 ?
                null :
                <div className="accountTabs">
            <ul className="nav nav-pills nav-tabs">
            <li className="nav-item">
            <Link to={"/uzytkownik"}><p className="nav-link active disabled">Konto</p></Link>
            </li>
            <li className="nav-item">
            <Link to={"/adminPanel"}><p className="nav-link">
                {/* Panel Administracyjny */}
                { props.userData.adminId === "1" || props.userData.adminId === "2" ?
                formName :
                "Panel Administracyjny"}
                </p></Link>
            </li>
            </ul>               
            </div>
            }

  <p className="lead">Witaj</p>
  <p className="user-data">{props.userData.username}</p>
  <hr className="my-4"></hr>
  <p className="lead">Jesteś z nami od: </p>
  {/* <p>{new Date(props.userData.created).getDay()}</p> */}
  <p>{getAccountDate() > 0 ? getAccountDate() : null} 
  {getAccountDate() > 1 ? " dni" : getAccountDate() === 1 ? " dnia" : "dziś"}</p>
  <p>Dziękujemy :)</p>
  <hr className="my-4"></hr>
  <p className="lead">Twój email to: </p>
  <p>{props.userData.email}</p>
  <hr className="my-4"></hr>
  <p>Twój status konta:</p>
  <p style={{color: props.userData.active ? "green" : "red"}}>{props.userData.active ? "aktywne" : "nie aktywne"}</p>
  {/* <hr className="my-4"></hr> */}
    {props.userData.active ? null :
    <div>
        <p>Na podany email została wysłana wiadomość z linkiem weryfikacyjnym do aktywacji konta.
                <br></br>
                Sprawdź swój email.</p>
    {/* <hr className="my-4"></hr> */}
    </div>
    }
  <hr className="my-4"></hr>

  <Zoom in={true}>
  <button className="btn btn-danger" onClick={handleLogoutClick}>Wyloguj</button>
  </Zoom>

</div>
            {/* <p>Zalogowany jako: {props.userData.username}</p>
            <p>Twój email: {props.userData.email}</p>
            <p>Twój status konta: {props.userData.active ? "aktywne" : "nie aktywne"}</p>
            {props.userData.active ? null : 
            <p>
                Na podany email została wysłana wiadomość z linkiem do weryfikacji konta.
                <br></br>
                Sprawdź swój email.
            </p>
                }
                <div>
                    <button className="btn btn-danger" onClick={handleLogoutClick}>Wyloguj</button>
                </div> */}
        </div>
    );
};

export default UserAccount;