import React, { useEffect } from "react";
import "./Articles.css";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import currentArticles from "./currentArticles";
import parse from 'html-react-parser';
import { BackLink } from "../App";
import Crunches from "../Crunches";

function Article(props){
    
    // function handleClick(){
    //     props.setCurrentArticle(props.article);
    // }
    const { id } = useParams();

    // const article = currentArticles.find({ id: id});
    const article = currentArticles.find((element) =>{
        // console.log(id);
        // console.log(element.id === id);
        return element.id === parseInt(id);
    });

    const target = {
        title: article.title,
        subcategory: "Artykuly"
    }

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "instant"
      })
      }, []);

    // let article = currentArticles.filter(obj => {
    //     return obj.id === id;
    //   });

    return (
        // <div className="article">
        //     <div>
        //         <h3>{props.article.title}</h3>
        //     </div>

        //     <div>
        //         <p>
        //         {props.article.content.substring(0, 200) + "..."}
        //         </p>
        //         <p className="article-author">autor: {props.article.author}</p>
        //     </div>
        //     <div>
        //         <Link to={"/artykuly/" + props.article.id} className="btn btn-primary">czytaj dalej</Link>
        //     </div>
        // </div>
        <div className="single-article">

            {/* <p>Artykuły - {article.title}</p>
            <Link to={"/artykuly"}><h4><i><FontAwesomeIcon icon={faArrowLeft}>
            </FontAwesomeIcon></i> Wróć</h4></Link> */}

            {/* <div className="crunches">

            <p><Link to={"/artykuly"}>Artykuły</Link> - {article.title}</p>

            <div className="profile-header-back">
                <BackLink content={<p><i>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></i> Wróć</p>}/>
            </div>
            </div> */}

            <Crunches target={target} />
            {/* <p><Link to={"/artykuly"}>Artykuły</Link> - {article.title}</p> */}

            <div className="single-article-article">

                    <img className="rounded" src={article.image} alt="zdjęcie artykułu"/>

                        <div>
                            {/* <h3>{props.article.title}</h3> */}
                            <h3>{article.title}</h3>
                            </div>

                            <div >
                                {/* <p>{props.article.content}</p> */}
                                {/* <p className="article-content">{article.content}</p> */}
                                <div className="article-content">{parse(article.content)}</div>
                                {/* {article.content} */}
                                {/* {parse(article.content)} */}
                                {/* <div dangerouslySetInnerHTML={{ __html: this.state.article.content }} /> */}
                                </div>

                                <div>
                                    {/* <p className="article-author">autor: {props.article.author}</p> */}
                                    {/* <p className="article-author">dodane: {article.date}</p> */}
                                    <p className="article-author">
                                        {article.date}
                                        <br></br>
                                        autor: {article.author}
                                    </p>
                                    </div>
                                </div>
                        </div>
    );
};

export default Article;