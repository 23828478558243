import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import LoginGoogle from "./LoginGoogle";

function Register(props){

    const [userData, setUserData] = useState({
        username: "",
        email: "",
        password: "",
        password2: ""
    });
    const [error, setError] = useState(null);
    const [recaptcha, setRecaptcha] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState(false);
    // const bannedWords = props.bannedWords;
    
    // const recaptchaDone = false;
    // const [registerStatus, setRegisterStatus] = useState(false);

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "instant"
    })
    }, []);

    function handleChange(event){
        const newValue = event.target.value;
        const inputName = event.target.name;

        setUserData(prevValue =>{
            if (inputName === "username"){
                return {
                    username: newValue,
                    email: prevValue.email,
                    password: prevValue.password,
                    password2: prevValue.password2
                };
              } else if (inputName === "email"){
                return {
                    username: prevValue.username,
                    email: newValue,
                    password: prevValue.password,
                    password2: prevValue.password2
                };
            } else if (inputName === "password"){
                return {
                    username: prevValue.username,
                    email: prevValue.email,
                    password: newValue,
                    password2: prevValue.password2
                };
            } else if (inputName === "password2"){
                return {
                    username: prevValue.username,
                    email: prevValue.email,
                    password: prevValue.password,
                    password2: newValue
                }
            }
        });
    };

    function registerUser(name, email, pass){
      // console.log("trying to register");
      setLoading(true);
      // axios.post(process.env.REACT_APP_REGISTER_USER_URL, {
      //     username: name,
      //     email: email,
      //     password: pass
      // }).then(response =>{
      //     setLoading(false);
      //     registerResponse(response);
      // }).catch(err =>{
      //     console.log(err);
      // });
      axios({
        method: "POST",
        data: {
          username: name,
          email: email,
          password: pass,
          ip: props.addressIp
        },
        withCredentials: true,
        url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_REGISTER_USER_URL,
      }).then((res) => {
        setLoading(false);
        registerResponse(res);
      }).catch((err) =>{
        console.log(err);
      });
  };

  function registerResponse(response){

    if (response.data === "0"){
      setError("Użytkownik o podanej nazwie istnieje. Wpisz inną nazwę użytkownika.");
      registerSuccess(false);
      return;
    } else if (response.data === "1"){
      setError("Użytkownik o podanym emailu istnieje. Wpisz inny email.");
      registerSuccess(false);
      return;
    }

    switch (response.status){
        case 880:
          setError("Użytkownik o podanej nazwie istnieje. Wpisz inną nazwę użytkownika.");
          registerSuccess(false);
        return "Użytkownik o podanej nazwie istnieje. Wpisz inną nazwę użytkownika.";
        case 881:
          setError("Użytkownik o podanym emailu istnieje. Wpisz inny email.");
          registerSuccess(false);
            return "Użytkownik o podanym emailu istnieje. Wpisz inny email.";
        case 200:
            //successfully registered!
            // setRegisterStatus(true);
            // setUserStatus("UserRegisterSuccess");
            // props.setUserState("UserRegisterSuccess");
            window.location.href = '/rejestracja/sukces';
            break;
            default:
              registerSuccess(false);
                return "Unknown register error!";
    };
};

function loginResponse(response){

  if (response.data === "0"){
    // return "Niepoprawny login lub hasło.";
    setError("Niepoprawny login lub hasło.");
    return;
  }

  const status = response.status;
  switch (status){
      case 881:
      return "Użytkownik o podanym emailu nie istnieje.";
      case 882:
      return "Podane hasło jest niepoprawne.";
      case 883:
          console.log(response);
          return ("Konto zostało zbanowane. Powód: " + (response.data.statusString !== undefined ? response.data.statusString : "nieznany" ));
      case 200:
          const { username, email, active, dataStatus, _id } = response.data;

          props.setUserData(response.data);
          props.setUserState("zalogowano pomyślnie");
          props.createCookie(response.data);
          setUserLoggedIn(true);

          break;
          default:
              return "Unknown login error!";
  };
};

    // function handleRegisterResponse(response){
    //     if (response === "+"){
    //     }
    //     else{
    //         setError(response);
    //     }
    // };

    function isValidUsername(username){
      // if (props.bannedWords === undefined){
      //   setError("Banned words is empty");
      //   return false;
      // }

      // console.log();

      for (let i = 0; i < props.bannedWords.length; i++) {
        if (username.includes(props.bannedWords[i])){
          setError("Nazwa użytkownika zawiera niedozwone słowo.");
          return false;
        }
      }
      
      // props.bannedWords.forEach(element => {
      //   if (username.includes(element)){
      //     setError("Nazwa użytkownika zawiera niedozwone słowo.");
      //     return false;
      //   }
      // });

      // if (username === "chuj"){
      //   setError("Nazwa użytkownika zawiera niedozwone słowo.");
      //   return false;
      // }

      if (userData.username.length <= 0){
        setError("Wpisz nazwę użytkownika.");
        return false;
      }

      if (userData.username.length > 30){
        setError("Nazwa użytkownika nie może przekraczać 30 znaków.");
        return false;
      }

      return true;
      // return true;
    };

    function containsSpecialString(text){
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if(format.test(text)){
    return true;
  } else {
   return false;
  }
    };

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    };

    function isVallidPassword(pass){

        if (pass.length <= 0){
            setError("Wprowadź hasło.");
            return false;
        }
        else if (pass.length < 6)
        {
            setError("Hasło musi zawierać minimum 6 znaków.");
            return false;
        }
        else if (pass.length > 100){
          setError("Hasło nie może zawierać więcej niż 100 znaków.");
            return false;
        }

        if (pass !== userData.password2)
        {
            setError("Hasła nie są jednakowe.");
            return false;
        }

        if (recaptcha === false){
            setError("Nieprawidłowy kod reCaptcha. Jeśli nie widać kodu reCaptcha odśwież stronę.");
            return false;
        }
        
        return true;
    };

    const handleSubmit = (event) =>{
      event.preventDefault();
      setRegisterSuccess(true);
        if (isValidUsername(userData.username) === false){
          return;
        }

        // if (containsSpecialString(userData.username) === true){
        //   setError("Nazwa użytkownika nie może zawierać znaków specjalnych.");
        //   return;
        // }

        if (!isValidEmail(userData.email))
        {
            if (userData.email.length <= 0){
                setError("Wpisz email.");
            }else {
                setError("Nieprawidłowy email.");
            }
            return;
        }

        if (!isVallidPassword(userData.password))
        {
            return;
        }

        setError(null);

        // props.register(userData.username, userData.email, userData.password, handleRegisterResponse);
        // registerUser(userData.username, userData.email, userData.password, handleRegisterResponse);
        registerUser(userData.username, userData.email, userData.password);
    };

    function handleRecaptcha(){
        setRecaptcha(!recaptcha);
    };

    if (userLoggedIn){
      return <Navigate to={"/"} />
    } else {
    return(
        <div className="container">
  <h1>Rejestracja</h1>

  <div className="row">
    <div className="col">
      <div className="card register-row">
        <div className="card-body">

          {/* <form action="/register" method="POST"> */}
          <form onSubmit={handleSubmit}>
            {/* <div className="form-group">
              <label htmlFor="username">nazwa użytkownika:</label>
              <input type="text" className="form-control" name="username" onChange={handleChange} value={userData.username}></input>
            </div> */}
            <div className="form-group form-floating">
              <input type="text" className="form-control" id="floatingUserName" placeholder="username" name="username" onChange={handleChange} value={userData.username}></input>
              <label htmlFor="floatingUserName">Nazwa użytkownika</label>
            </div>

            {/* <div className="form-group">
              <label htmlFor="email">email:</label>
              <input type="email" className="form-control" name="email" onChange={handleChange} value={userData.email}></input>
            </div> */}
            <div className="form-group form-floating">
              <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com" name="email" onChange={handleChange} value={userData.email}></input>
              <label htmlFor="floatingEmail">E-mail</label>
            </div>

            {/* <div className="form-group">
              <label htmlFor="password">hasło:</label>
              <input type="password" className="form-control" name="password" onChange={handleChange} value={userData.password}></input>
            </div> */}
            <div className="form-group form-floating">
              <input type="password" className="form-control" id="floatingPassword" placeholder="password" name="password" onChange={handleChange} value={userData.password}></input>
              <label htmlFor="floatingPassword">Hasło</label>
            </div>

            {/* <div className="form-group">
              <label htmlFor="password2">powtórz hasło:</label>
              <input type="password" className="form-control" name="password2" onChange={handleChange} value={userData.password2}></input>
            </div> */}
            <div className="form-group form-floating">
              <input type="password" className="form-control" id="floatingPassword2" placeholder="password" name="password2" onChange={handleChange} value={userData.password2}></input>
              <label htmlFor="floatingPassword2">Powtórz hasło</label>
            </div>

            <div className="recaptcha">
            {/* <label for="recaptcha">kod reCaptcha:</label> */}
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleRecaptcha} />
            </div>
            {loading == true ? <div className="loader"></div> 
            :
            registerSuccess === true ? <div className="loader"></div>
            :
            <button type="submit" className="btn btn-primary">Zarejestruj</button>
            }
          </form>
          <p className="register-error">{error}</p>
        </div>

        <hr></hr>

        <div>
            {/* <a className="d-flex justify-content-center" href="#/" onClick={loginRegisterButtonClick}>Masz już konto? Zaloguj się</a> */}
            <p className="d-flex justify-content-center">Masz już konto?&nbsp;<Link className="login-register-link" to={"/logowanie"}>Zaloguj się</Link></p>
        </div>

      </div>
    </div>

    <div className="col">
      <div className="card social-block register-row">
        <LoginGoogle loginResponse={loginResponse} />

        {/* <div className="card-body">
          <a className="btn btn-block btn-social btn-facebook" href="/auth/google" role="button">
            <i className="fab fa-facebook company-icon"></i>
            Sign Up with Facebook
          </a>
        </div> */}

      </div>
    </div>

  </div>
</div>
    );
      }
};

export default Register;