import React, { useEffect, useState } from "react";
import DelayComponent from "./DelayComponent";
import { checkIsOpen, getDistanceByUserLocation, getDistanceInMeters, getRating, getTodayHours, refreshUserLocation } from "./App";
import settings from "./settings";

function SortingPanel(props){

    const resultsArray = [
        {value: 8, label: "8 wyników"}, 
        {value: 12, label: "12 wyników"}, 
        {value: 20, label: "20 wyników"}, 
        {value: 40, label: "40 wyników"}];

        const sortingArray = props.target === "event" ?
        [
        {value: 0, label: "↓ data dodania"},
        {value: 1, label: "↓ alfabetycznie"},
        {value: 2, label: "↓ wg. oceny"},
        {value: 3, label: "↓ wg. rozpoczęcia"},
        // {value: 3, label: "↓ rozpoczęcie"},
        {value: 4, label: "↓ najbliższe"},
        ] : [
        {value: 0, label: "↓ data dodania"},
        {value: 1, label: "↓ alfabetycznie"},
        {value: 2, label: "↓ wg. oceny"},
        {value: 3, label: "↓ wg. otwarcia"},
        {value: 4, label: "↑ wg. otwarcia"},
        {value: 5, label: "↓ najbliższe"},
        // {value: 5, label: "↓ wg. popularności"}
        ];
        // const [sortingDropdown, setSortingDropdown] = useState(props.target === "event" ? sortingArray[3].value : sortingArray[0].value);
        const [sortingDropdown, setSortingDropdown] = useState(props.target === "event" ? sortingArray[settings.sortingByEvents].value 
        : sortingArray[settings.sortingByPlaces].value);
        const [soringImage, setSortingImage] = useState("/icons/grid-icon-black.png");

        useEffect(() => {
            refreshUserLocation();
            sortPlaces(sortingArray[sortingDropdown].label);
        }, []);
    
    function sortPlaces(sortBy){
        // console.log(sortBy);
        props.setLoading(true);
        // setSortDown(sortingArray[1].label);
        let targetsToSort = [...props.collection];

        if (sortBy === sortingArray[1].label){
            targetsToSort.sort(function(a,b){
                let x = a.title.toLowerCase();
                let y = b.title.toLowerCase();
            
                 if(x>y){return 1;} 
                 if(x<y){return -1;}
                 return 0;
               });

            // setSortDown(sortingArray[1].label);
        } else if (sortBy === sortingArray[0].label){
            targetsToSort.sort(function(a,b){
                if (a.created === undefined || a.created === ""){
                    return 1;
                }

                if (b.created === undefined || b.created === ""){
                    return -1;
                }

                let x = new Date(a.created);
                let y = new Date(b.created);
            
                 if(x>y){return 1;} 
                 if(x<y){return -1;}
                 return 0;
               });
        } else if (sortBy === sortingArray[2].label){
            targetsToSort.sort(function(a,b){
                if (a.commentsAndRatings === undefined  || a.reviews === "" || a.commentsAndRatings.length <= 0){
                    return 1;
                }

                if (b.commentsAndRatings === undefined || b.reviews === "" || b.commentsAndRatings.length <= 0){
                    return -1;
                }

                let x = getRating(a.commentsAndRatings, a.reviews);
                let y = getRating(b.commentsAndRatings, b.reviews);
            
                 if(x.finalRating>y.finalRating){return -1;} 
                 if(x.finalRating<y.finalRating){return 1;}
                 if (x.finalRating===y.finalRating){return x.votes > y.votes ? -1 : 1;}
                 return 0;
               });
        }

        if (props.target === "event"){
            if (sortBy === sortingArray[3].label){
                targetsToSort.sort(function(a,b){
                    let x = new Date(a.startDate);
                    let y = new Date(b.startDate);

                    if (x.getDate() === y.getDate() && x.getMonth() === y.getMonth() && x.getFullYear() === y.getFullYear() ){
                        console.log("found");
                        if (a.hours[0].open > b.hours[0].open){
                            return 1;
                        }
                        else {
                            return -1;
                        }
                    }
                
                     if(x>y){return 1;} 
                     if(x<y){return -1;}
                     return 0;
                   });
            } else if (sortBy === sortingArray[4].label){
                addDistanceToTargets(targetsToSort);

                targetsToSort.sort(function(a,b){
                    let x = a.distanceFromUser;
                    let y = b.distanceFromUser;
                    if (x>y){return 1;}
                    if (x<y){return -1;}
                    return 0;
                });
            }
        } else if (props.target === "place"){
            if (sortBy === sortingArray[3].label){
                targetsToSort.sort(function(a,b){
                    if (checkIsOpen(a) === false){
                        return 1;
                    };

                    if (checkIsOpen(b) === false){
                        return -1;
                    }
    
                    let x = getTodayHours(a);
                    let y = getTodayHours(b);
                
                     if(x.close.split(":")[0]>y.close.split(":")[0]){return -1;} 
                     if(x.close.split(":")[0]<y.close.split(":")[0]){return 1;}
                     if (x.close.split(":")[0]===y.close.split(":")[0]){return x.close.split(":")[1] > y.close.split(":")[1] ? -1 : 1;}
                     return 0;
                   });
            } else if (sortBy === sortingArray[4].label){
                targetsToSort.sort(function(a,b){
                    if (checkIsOpen(a) === false){
                        return 1;
                    };

                    if (checkIsOpen(b) === false){
                        return -1;
                    }
    
                    let x = getTodayHours(a);
                    let y = getTodayHours(b);
                
                     if(x.close.split(":")[0]>y.close.split(":")[0]){return 1;} 
                     if(x.close.split(":")[0]<y.close.split(":")[0]){return -1;}
                     if (x.close.split(":")[0]===y.close.split(":")[0]){return x.close.split(":")[1] > y.close.split(":")[1] ? -1 : 1;}
                     return 0;
                   });
            } else if (sortBy === sortingArray[5].label){
                addDistanceToTargets(targetsToSort);

                targetsToSort.sort(function(a,b){
                    let x = a.distanceFromUser;
                    let y = b.distanceFromUser;
                    if (x>y){return 1;}
                    if (x<y){return -1;}
                    return 0;
                });
            }
        }

         props.setCollection(targetsToSort);
         props.setIsSorted(true);
         props.setLoading(false);
     }

     function setSortingDropdownWithSave(value){
        setSortingDropdown(value);
        switch (props.target) {
            case "event":
                settings.sortingByEvents = value;
                break;
            case "place":
                settings.sortingByPlaces = value;
            default:
                break;
        }
     }

    //  async function addDistanceToTargets(targets){
    //     targets.forEach(element => {
    //         element.distanceFunction = function getDistance(distance){
    //             element.distanceFromUser = distance;
    //         }
        
    //         getDistanceInMeters(element.location, element.distanceFunction);
    //     });
    //  }

    function addDistanceToTargets(targets){
        targets.forEach(element => {
            element.distanceFromUser = getDistanceByUserLocation(element.location);
        });
    }

    function sortingClick(value){
        // setSortingDropdown(value);
        setSortingDropdownWithSave(value);
        // sortPlaces(sortingArray[value].label, props.collection, props.setCollection, props.setLoading);
        props.setPageNumber(0);
        sortPlaces(sortingArray[value].label);
        // sortEvent();
        // props.setCollection((num) => [...num.sort()]);
        // App.sort();
        // props.sort();
    }

    function resultsClick(value){
        props.setPageNumber(0);
        props.setResultsOnPage(parseInt(value));
    }

    function cardsSizeClick(){
        setSortingImage(soringImage === "/icons/grid-icon-black.png" ? "/icons/list-icon.png" : "/icons/grid-icon-black.png")
        props.cardsSize();
    }

    const CustomDropdown = (props) => {
        const { options, changePoint } = props;
        return (
            // <select
            // value={changePoint}
            // onChange={e => {props.handleChange(e.target.value)}}>
            //   {options.map(o => (
            //     <option key={o.value} value={o.value}>{o.label}</option>
            //   ))}
            // </select>

    <div className="btn-group hours-btn-group">
  <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
  {/* {changePoint} */}
  {options[changePoint === undefined ? props.startingPosition : options.findIndex(x => x.value === changePoint)].label}
  </button>
  <ul className="dropdown-menu">
    {options.map(o =>(
        <li key={o.value} value={o.value} onClick={() => {props.handleChange(o.value)}}>
            <a className="dropdown-item" href="#">{o.label}</a>
     </li>
    ))}
  </ul>
</div>
        );
      };

    return(
        <div className="sorting-panel d-flex flex-md-row-reverse">
        {props.collection !== undefined && props.collection.length > 0 ?
        <div className="sorting-panel-deck">

            <div className="sorting-panel-element col-5-auto">
            <CustomDropdown className="dropdown" options={sortingArray} handleChange={sortingClick} 
            changePoint={sortingDropdown} startingPosition={sortingDropdown} />
            </div>

            <div className="sorting-panel-element col-5-auto">
            <CustomDropdown className="dropdown" options={resultsArray} handleChange={resultsClick} 
            changePoint={props.resultsOnPage} startingPosition={1} />
            </div>

            <div className="sorting-panel-element col-2-auto">
                <a className="changeSizeButton" onClick={cardsSizeClick}><img src={soringImage}></img></a>
            </div>
        </div>
        : null}
        </div>
    )
}

export default SortingPanel;