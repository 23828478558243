import React from "react";

const currentTags = [
    {
        id: 1,
        category: "miejsca",
        name: "Kultura",
        value: "kultura",
        power: 1
    },
    {
        id: 2,
        category: "miejsca",
        name: "Nauka",
        value: "nauka",
        power: 1
    },
    {
        id: 3,
        category: "miejsca",
        name: "Muzea",
        value: "muzea",
        power: 2
    },
    {
        id: 4,
        category: "miejsca",
        name: "Teatry",
        value: "teatry",
        power: 2
    },
    {
        id: 5,
        category: "miejsca",
        name: "Kina",
        value: "kina",
        power: 2
    },
    {
        id: 6,
        category: "miejsca",
        name: "Kultura Inne",
        value: "kulturaInne",
        power: 2
    },
    {
        id: 7,
        category: "eventy",
        name: "Imprezy",
        value: "imprezy",
        power: 2
    },
    {
        id: 8,
        category: "eventy",
        name: "Imprezy Inne",
        value: "imprezyInne",
        power: 2
    },
    {
        id: 9,
        category: "eventy",
        name: "Koncerty",
        value: "koncerty",
        power: 2
    },
    {
        id: 10,
        category: "miejsca",
        name: "Restauracje",
        value: "restauracje",
        power: 2
    },
    {
        id: 11,
        category: "miejsca",
        name: "Fast Foody",
        value: "fastFoody",
        power: 2
    },
]

export default currentTags;