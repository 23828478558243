import React, { useEffect, useState } from "react";
import "./Card.css";
import Tag from "./Tag";
import { getTodayHours, getAddress, getPhone, getSortedTags, getEventDate, getEventHours, 
    getDayName, checkIsOpen, getPlaceHoursByDay, checkIsWholeTimeOpen, getPlaceHoursString, getDistanceByUserLocation } from "./App";
import { Link } from "react-router-dom";

function Card(props){

    const descriptionLength = 200;
    const [distance, setDistance] = useState("");

    function handleClick(){
        if (props.showProfile !== undefined && props.showProfile !== null){
            props.showProfile(props);
        }
    };

    function handleEventClick(){

    }

    return(

    props.cardsCategory === "places" ?
    <div className={(props.cardsSize === true ? "infoCard" : "card infoCardSmall col-xxl-3 col-lg-4 col-md-5 col-sm-12")}>
    <div className={"row"}>
    <div className={"card-info-left-side row " + (props.cardsSize === true ? "col-lg-4 col-md-12" : "col-sm-12")}>
        <div className="col-12 card-location-text-parent">
        <p className="card-location-text">Kraków</p>
        </div>
        
        <div className="card-info-left-side-image col-12">
        <img className="card-img-top" src={props.place.images[0].img} alt="Card image cap"></img>
        </div>

</div>

    <div className={(props.cardsSize === true ? "col-lg-8 col-md-12" : "col-12")}>
<div className="card-body">
  <h5 className="card-title">{props.place.title}</h5>
  <p className="card-text">{props.place.description.length > descriptionLength ? 
  props.place.description.substring(0, descriptionLength) + "..." :
  props.place.description.substring(0, descriptionLength)}</p>
  <div className="row">
    <div className="card-data col-md-4 col-sm-12">
    <Link to={"/" + props.place.category.toLowerCase() + "/" + props.place.subcategory.toLowerCase() + "/profil/" + props.place._id} 
    className="btn btn-primary infoCard-profile-button" onClick={handleClick}>sprawdź</Link>
    </div>

    <div className="card-data col-md-4 col-sm-12">
        <p>Czynne dzisiaj: <br></br>
        <span className=
        // {props.place.subcategory === "Teatry" || props.place.subcategory === "Kina" ? "itsOpen" :
        //     checkIsOpen(props.place) || checkIsWholeTimeOpen(props.place, new Date().getDay()) ? "itsOpen" : "itsClose"}
            {checkIsOpen(props.place) ? "itsOpen" : "itsClose"}
            >
            {/* {props.place.subcategory === "Teatry" || props.place.subcategory === "Kina" ?
        "według repertuaru"
        : checkIsOpen(props.place) === false && (getPlaceHoursByDay(props.place, new Date().getDay()).open === "" || 
        getPlaceHoursByDay(props.place, new Date().getDay()).open === undefined) ? "nieczynne" : 
        checkIsWholeTimeOpen(props.place, new Date().getDay()) ? "całą dobę" :
         getTodayHours(props.place).weekDay + (getTodayHours(props.place).open === "" ? "" : 
        getTodayHours(props.place).open + " - ") + getTodayHours(props.place).close} */}
        {getPlaceHoursString(props.place)}
        </span>
        </p>
    </div>

    <div className="card-data col-md-4 col-sm-12">
    <p>Adres: <br></br>{getAddress(props.place)}</p>
    {props.place.location !== undefined && props.place.location !== null ?
    // distance === "" ? getDistanceInMeters(props.place.location, setDistance) :
    distance === "" ? setDistance(getDistanceByUserLocation(props.place.location)) :
    distance === undefined || distance === null ? null :
    distance < 1000 ? <p>Odległość: {Math.floor(distance)} m</p> : 
    <p>Odległość: {(distance / 1000).toString().split(".")[0]}.
        {(distance / 1000).toString().split(".")[1].slice(0, 2)} km</p> 
    : null}
    
    </div>

  </div>
  
</div>
</div>
    </div>
    </div>
    
    : props.cardsCategory === "events" ?

    <div className={(props.cardsSize === true ? "infoCard" : "card infoCardSmall col-xxl-3 col-lg-4 col-md-5 col-sm-12")}>
    {/* {console.log(props.cardsSize)} */}
{/* <div className={"row " + (props.cardsSize === true ? "" : "col-4")}> */}
<div className={"row"}>
{/* <div className="col-md-4 col-sm-12"> */}
<div className={"card-info-left-side row " + (props.cardsSize === true ? "col-lg-4 col-md-12" : "col-sm-12")}>
    <div className="col-12 card-location-text-parent">
    <p className="card-location-text">Kraków</p>
    </div>
    
    <div className="card-info-left-side-image col-12">
    <img className="card-img-top" src={props.event.images[0].img} alt="Card image cap"></img>
    </div>

</div>

{/* <div class="card col-md-8 col-sm-12"> */}
<div className={(props.cardsSize === true ? "col-lg-8 col-md-12" : "col-12")}>
{/* <img class="card-img-top" src={props.images[0].img} alt="Card image cap"></img> */}
<div className="card-body">
<h5 className="card-title">{props.event.title}</h5>
<p className="card-text">{props.event.description.length > descriptionLength ? 
props.event.description.substring(0, descriptionLength) + "..." :
props.event.description.substring(0, descriptionLength)}</p>
<div className="row">
<div className="card-data col-md-4 col-sm-12">
{/* <a className="btn btn-primary infoCard-profile-button" onClick={handleClick}>sprawdź</a> */}
{/* <Link to={"profil"} className="btn btn-primary infoCard-profile-button" onClick={handleClick}>sprawdź</Link> */}
<Link to={"/" + props.event.category.toLowerCase() + "/" + props.event.subcategory.toLowerCase() + "/profil_event/" + props.event._id} 
className="btn btn-primary infoCard-profile-button" onClick={handleEventClick}>sprawdź</Link>
</div>

<div className="card-data col-md-4 col-sm-12">
    {/* <p>Czynne dzisiaj: <br></br>{getTodayHours(props.event)}</p> */}
    <p>
        Data: <span>{getEventDate(props.event) + " "}</span>
        {getDayName(props.event.startDate.split('-')[2], parseInt(props.event.startDate.split('-')[1]) - 1, props.event.startDate.split('-')[0])}
        </p>
        <p>Godzina: <span>{getEventHours(props.event)}</span></p>
</div>

<div className="card-data col-md-4 col-sm-12">
<p>Adres: <br></br>{getAddress(props.event)}</p>
{props.event.location !== undefined && props.event.location !== null ?
    // distance === "" ? getDistanceInMeters(props.event.location, setDistance) :
    distance === "" ? setDistance(getDistanceByUserLocation(props.event.location)) :
    distance === undefined || distance === null ? null :
    distance < 1000 ? <p>Odległość: {Math.floor(distance)} m</p> : 
    <p>Odległość: {(distance / 1000).toString().split(".")[0]}.
        {(distance / 1000).toString().split(".")[1].slice(0, 2)} km</p> 
    : null}
</div>

</div>

</div>
</div>
</div>
</div>
    
    : null




    );
};

export default Card;