import React from "react";
import { Link, useParams } from "react-router-dom";
import "../App.css";

function RegisterSuccess(props){

    // function handleLoginClick(){
    //     props.changeState("UserLogin");
    // }
    const { status } = useParams();

    return (
        <div className="registerSuccess">
            {/* {props.state === "UserRegisterSuccess" ?  */}
            {status === "sukces" ? 
            <div>
            <h1>Rejestracja</h1>
            <div className="info-row">
                
                <p>Rejestracja zakończona sukcesem.<br></br>Na podany email została wysłana wiadomość z linkiem do aktywacji konta.</p>
                <br></br>
                <p>Powrót na <Link className="login-register-link" to={"/"}>stronę główną</Link></p>
            </div>
            </div>
            :
            // props.state === "UserVerificationSuccess" ?
            status === "weryfikacja" ?
            <div>
                <h1>Weryfikacja</h1>
                <div className="info-row">
                {/* <p>Pomyślnie zweryfikowano konto, możesz teraz się <a href="#" onClick={handleLoginClick}>zalogować</a></p> */}
                <p>Pomyślnie zweryfikowano konto, możesz teraz się <Link to={"/logowanie"} className="login-register-link">zalogować</Link></p>
                </div>
            </div>
            : 
            status === "blad" ?
            <div>
                <h1>Weryfikacja</h1>
                <div className="info-row">
                <p>Błąd weryfikacji konta. Powodem może być: </p>
                <ul>
                    <li>konto zostało już wcześniej aktywowane</li>
                    <li>problem z linkiem weryfikacyjnym</li>
                </ul>
                <p>Spróbuj się zalogować i sprawdź w profilu użytkownika czy konto zostało aktywowane, jeśli nie <Link to={"/kontakt"}>zgłoś</Link> nam problem.</p>
                </div>
            </div>
                :
                null
        }
            
        </div>
    );
};

export default RegisterSuccess;