import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../App.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

function PasswordReset(props){

    const { veriString } = useParams();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [recaptcha, setRecaptcha] = useState(false);
    const [linkSendSuccess, setLinkSendSuccess] = useState(false);
    const [passwordChangedSuccess, setPasswordChangedSuccess] = useState(false);
    const [userData, setUserData] = useState({
        email: "",
        password: "",
        password2: ""
    });

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "instant"
      });
      }, []);

      function checkUserEmail(){
        axios({
            method: "POST",
            data: {
                email: userData.email
            },
            withCredentials: true,
            url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_PASSWORD_RESET
        }).then((result) =>{
            setSending(false);
            resetPasswordEmailCheckResponse(result.data);
        }).catch((err) =>{
            console.log(err);
        });
      };

      function checkPasswordReset(){
        axios({
            method: "POST",
            data: {
                password: userData.password
            },
            withCredentials: true,
            url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_PASSWORD_RESET + "/" + veriString
        }).then((result) =>{
            setSending(false);
            resetPasswordResponse(result.data);
        }).catch((err) =>{
            console.log(err);
        });
      }

    function isVallidPassword(pass){

        if (pass.length <= 0){
            setError("Wprowadź hasło.");
            return false;
        }
        else if (pass.length < 6)
        {
            setError("Hasło musi zawierać minimum 6 znaków.");
            return false;
        }
        else if (pass.length > 100){
          setError("Hasło nie może zawierać więcej niż 100 znaków.");
            return false;
        }

        if (pass !== userData.password2)
        {
            setError("Hasła nie są jednakowe.");
            return false;
        }

        if (recaptcha === false){
            setError("Nieprawidłowy kod reCaptcha. Jeśli nie widać kodu reCaptcha odśwież stronę.");
            return false;
        }
        
        return true;
    };

      function handleChange(event){
        const newValue = event.target.value;
        const inputName = event.target.name;

        setUserData(prevValue =>{
            if (inputName === "email"){
                return {
                    email: newValue
                };
            } else if (inputName === "password"){
                return {
                    email: prevValue.email,
                    password: newValue,
                    password2: prevValue.password2
                };
            } else if (inputName === "password2"){
                return {
                    email: prevValue.email,
                    password: prevValue.password,
                    password2: newValue
                }
            };
        });
    };

    function resetPasswordEmailCheckResponse(data){
        switch(data){
            case 0:
                setLinkSendSuccess(true);
                break;
            case 1:
                setError("Podany email nie istnieje. Zarejestruj swoje konto już teraz.");
                break;
            case 2:
                setError("Na podany adres email został już wysłany link do weryfikacji.");
                break;
            case -1:
                setError("Wystąpił błąd. Spróbuj ponownie za chwilę.");
                break;
            default:
                setError("Wystąpił błąd. Spróbuj ponownie za chwilę.");
                break;
        };
    };

    function resetPasswordResponse(data){
        switch(data){
            case 0:
                setPasswordChangedSuccess(true);
                break;
            case 1:
                setError("Nieprawidłowy link werifikacyjny.");
                break;
            case 2:
                setError("Nie można zmienić hasła na takie samo.");
                break;
            case -1:
                setError("Wystąpił błąd. Spróbuj ponownie za chwilę.");
                break;
            default:
                setError("Wystąpił błąd. Spróbuj ponownie za chwilę.");
                break;
        };
    };

    const handleSubmit = (event) =>{
        event.preventDefault();

        if (!isValidEmail(userData.email))
        {
            if (userData.email === undefined || userData.email === "" || userData.email.length <= 0){
                setError("Wpisz email.");
            }else {
                setError("Nieprawidłowy email.");
            }
            return;
        }

        if (recaptcha === false){
            setError("Nieprawidłowy kod reCaptcha. Jeśli nie widać kodu reCaptcha odśwież stronę.");
            return false;
        }

        setError(null);
        setSending(true);
        checkUserEmail();
    }

    const handleResetSubmit = (event) =>{
        event.preventDefault();
        if (!isVallidPassword(userData.password))
        {
            return;
        };

        setError(null);
        setSending(true);
        checkPasswordReset();
    }

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }


    return (
        <div className="password-reset">
            
            {loading ? <div className="loader"></div> :
            veriString === undefined || veriString === "" ?
            <div>
                <h1>Nie pamiętam hasła</h1>
            {linkSendSuccess === true ?
            <div className="info-row">
                <p>Link weryfikacyjny został poprawnie wysłany na podany e-mail. <br></br>Sprawdź swoją skrzynkę.</p>
                <br></br>
                <p>Powrót na <Link className="login-register-link" to={"/"}>stronę główną</Link></p>
            </div>
            :
            <div className="row">
            <div className="col">
              <div className="card register-row">
                <div className="card-body">
        
                  <form onSubmit={handleSubmit}>
                    <div className="form-group form-floating">
                        <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com" name="email" onChange={handleChange} value={userData.email}></input>
                        <label htmlFor="floatingEmail">E-mail</label>
                    </div>

                    <div className="recaptcha">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={() => setRecaptcha(!recaptcha)} />
                    </div>

                    {sending ? <div className="loader"></div> :
                    <button type="submit" className="btn btn-primary">Zresetuj hasło</button>
                  }
                  </form>
                  <p className="register-error">{error}</p>
                </div>

                <hr></hr>
        
                <div>
                    <p className="d-flex justify-content-center">lub&nbsp;<Link className="login-register-link" to={"/logowanie"}>Zaloguj się</Link></p>
                </div>
        
              </div>
            </div>
        
          </div>}
          </div>
            :
            <div>
                <h1>Zresetuj hasło</h1>
                {passwordChangedSuccess === true ?
                <div className="info-row">
                    <p>Pomyślnie zresetowano hasło, możesz teraz się <Link to={"/logowanie"} className="login-register-link">zalogować</Link></p>
                </div>
                :
            <div className="card register-row">
                        <div className="card-body">

<form onSubmit={handleResetSubmit}>

  <div className="form-group form-floating">
    <input type="password" className="form-control" id="floatingPassword" placeholder="password" name="password" onChange={handleChange} value={userData.password}></input>
    <label htmlFor="floatingPassword">Hasło</label>
  </div>

  <div className="form-group form-floating">
    <input type="password" className="form-control" id="floatingPassword2" placeholder="password" name="password2" onChange={handleChange} value={userData.password2}></input>
    <label htmlFor="floatingPassword2">Powtórz hasło</label>
  </div>

  <div className="recaptcha">
  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={() => setRecaptcha(!recaptcha)} />
  </div>
  {sending ? <div className="loader"></div> :
  <button type="submit" className="btn btn-primary">Potwierdź</button>
  }
</form>
<p className="register-error">{error}</p>
</div>
            </div>}
            </div>
            }
            
        </div>
    );
};

export default PasswordReset;