import React from "react";
import axios from "axios";
// import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

function LoginGoogle(props){

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => googleLoginSuccess(codeResponse),
        onError: (error) => googleLoginError(error)
    });

    function loginGoogleUser(user){
        axios({
          method: "POST",
          data: {
            user: user,
            ip: props.addressIp
          },
          withCredentials: true,
          url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_LOGIN_GOOGLEUSER_URL,
        }).then((res) => {
          props.loginResponse(res);
        }).catch((err) =>{
          console.log(err);
        });
      }
  
    function googleLoginSuccess(codeResponse){
      getGoogleUser(codeResponse);
    }
  
    function getGoogleUser(user){
      axios
                      .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                          headers: {
                              Authorization: `Bearer ${user.access_token}`,
                              Accept: 'application/json'
                          }
                      })
                      .then((res) => {
                          // setProfile(res.data);
                          // console.log(res.data);
                          loginGoogleUser(res.data);
                      })
                      .catch((err) => console.log(err));
    }
  
    function googleLoginError(error){
      console.log("login error: " + error);
    }

    return (
        <div className="card-body">
          {/* <a className="btn btn-block btn-social btn-google" onClick={props.googleButton} role="button"> */}
          <a className="btn btn-block btn-social btn-google" onClick={() => login()} role="button">
            {/* <i className="fab fa-google company-icon"></i> */}
            <img className="socialLogo" src="images/google.png" ></img>
            zaloguj z Google
          </a>
        </div>
    )
}

export default LoginGoogle;