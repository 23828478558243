import React from "react";

const currentEvents = [
    {
        title: "Nowy Rok",
        day: 1,
        month: 0,
        content: "Międzynarodowe święto przypadające 1 stycznia każdego roku (w kalendarzu gregoriańskim), w Polsce jest to dzień wolny od pracy. W powszechnie przyjętym zwyczaju obchodzenie święta należy rozpocząć od otworzenia butelki szampana o północy z dnia 31 grudnia na 1 stycznia następnego roku. Dzień poprzedzający Nowy Rok nosi w polskiej tradycji miano sylwestra od imienia świętego, który wspominany jest w tym dniu w liturgii w Kościele rzymskokatolickim papież Sylwester I.",
        author: "wikipedia.org"
    },
    {
        title: "Trzech Króli (Objawienie Pańskie)",
        day: 6,
        month: 0,
        content: "Chrześcijańska uroczystość mająca uczcić objawienie się Boga człowiekowi (teofania), obecność Boga w historii człowieka, a także uzdolnienie człowieka do rozumowego poznania Boga. Symbolami święta jest historia opisana w Ewangelii Mateusza, według której Mędrcy ze Wschodu przybyli do Betlejem, aby oddać pokłon Jezusowi, chrzest Chrystusa w Jordanie oraz cud przemienienia wody w wino w Kanie Galilejskiej. W Kościele rzymskokatolickim Objawienie Pańskie jest świętem nakazanym.",
        author: "wikipedia.org"
    },
    {
        title: "Dzień Babci",
        day: 21,
        month: 0,
        content: "Święto obchodzone dla uhonorowania babć; w dzień ten wnuki składają życzenia swoim babciom. W Polsce obchodzone 21 stycznia, w tym samym dniu w Bułgarii, a w Brazylii i Hiszpanii 26 lipca.",
        author: "wikipedia.org"
    },
    {
        title: "Dzień Dziadka",
        day: 22,
        month: 0,
        content: "Rodzinne, świeckie święto, obchodzone w Polsce 22 stycznia w celu uhonorowania dziadków. Przyjęło się, że w ten dzień wnuki składają życzenia swoim dziadkom, a w przedszkolach, świetlicach i innych instytucjach opiekuńczo-wychowawczych organizowane są spotkania, pokazy artystyczne dzieci i poczęstunek. Zwykle towarzyszy temu wręczenie laurek.",
        author: "wikipedia.org"
    },
    {
        title: "Ofiarowanie Pańskie",
        day: 2,
        month: 1,
        content: "pot. Matki Boskiej Gromnicznej. Święto chrześcijańskie, upamiętniające ofiarowanie Jezusa Chrystusa w Świątyni Jerozolimskiej, zgodnie z prawem Mojżeszowym, w nadzwyczajnej formie rytu rzymskiego zwane Oczyszczeniem Najświętszej Maryi Panny (w 40. dzień od Narodzenia Jezusa), czym nawiązywano do święta spotkania i święta oczyszczenia w tradycji starotestamentalnej i chrześcijańskiej.",
        author: "wikipedia.org"
    },
    {
        title: "Walentynki",
        day: 14,
        month: 1,
        content: "Coroczne święto zakochanych przypadające 14 lutego. Nazwa pochodzi od św. Walentego, którego wspomnienie liturgiczne w Kościele katolickim obchodzone jest również tego dnia. Zwyczajem w tym dniu jest wysyłanie listów zawierających wyznania miłosne (często pisane wierszem). Na Zachodzie, zwłaszcza w Wielkiej Brytanii i Stanach Zjednoczonych, czczono św. Walentego jako patrona zakochanych. Dzień 14 lutego stał się więc okazją do obdarowywania się drobnymi upominkami.",
        author: "wikipedia.org"
    },
    {
        title: "Narodowy Dzień Pamięci Żołnierzy Wyklętych",
        day: 1,
        month: 2,
        content: "Narodowy Dzień Pamięci Żołnierzy Wyklętych stanowi dzień upamiętniający polskie powojenne podziemie antykomunistyczne. Po upadku powstania warszawskiego władzę w Polsce przejęli komuniści. Nieufne wobec nowych władz Polskie Państwo Podziemne przygotowywało się do walki, jednak niestety w wyniku aresztowania przywódców podziemia upadło, a jedyną walczącą organizacją pozostała NSZ (Narodowe Siły Zbrojne). W maju 1945 powstała Delegatura Sił Zbrojnych na Kraj, przekształcona we wrześniu tego samego roku w Wolność i Niezawisłość.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Dzień Kobiet",
        day: 8,
        month: 2,
        content: "Dzień Kobiet, to coroczne święto kobiet obchodzone 8 marca, jako wyraz szacunku dla ofiar walki o równouprawnienie kobiet. Jest to święto o stosunkowo długiej tradycji, ponieważ ustanowione zostało w 1910 r., a więc 100 lat temu.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Dzień Mężczyzn",
        day: 10,
        month: 2,
        content: "Jest to święto wyrażające uznanie dla osiągnięć mężczyzn z całego świata oraz na przestrzeni dziejów. Stanowi czas rozważań nad ich udziałem oraz zaangażowaniem we współtworzeniu postępów edukacyjnych, ekonomicznych i technologicznych w społeczeństwie.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Początek astronomicznej wiosny",
        day: 20,
        month: 2,
        content: "Jest to dzień rozpoczęcia jednego z czterech podstawowych okresów klimatycznych występujących w strefie klimatu umiarkowanego. Cechami charakterystycznymi opisywanej pory roku są umiarkowane temperatury powietrza oraz niewielkie ilości opadów atmosferycznych. Podczas trwania wiosny, wraz z upływem czasu przybywa dnia a nocy ubywa.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Prima Aprilis",
        day: 1,
        month: 3,
        content: "Prima aprilis (łac. 1 kwietnia), dzień żartów — święto obchodzone 1 kwietnia, z którym związane są tradycje robienia żartów, celowego wprowadzania w błąd rodziny, znajomych. W tym dniu również w mediach pojawiają się różne nieprawdziwe, żartobliwe informacje. W krajach anglojęzycznych dzień ten nazywany jest Dniem Głupca (ang. Fool's Day).",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Międzynarodowy Dzień Ziemi",
        day: 22,
        month: 3,
        content: "Dzień Ziemi lub też Międzynarodowy Dzień Ziemi to coroczne wydarzenie obchodzone w celu promowania ochrony środowiska, rozszerzania świadomości ekologicznej i życia w zgodzie z naturą. Idea obchodów Dnia Ziemi w tym dniu została zapoczątkowana w 1970 r. przez amerykańskiego senatora Gaylorda Nelsona.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Międzynarodowe Święto Pracy",
        day: 1,
        month: 4,
        content: "Międzynarodowe Święto Pracy (ang. International Workers Day), lub inaczej Międzynarodowy Dzień Solidarności Ludzi Pracy, to święto obchodzone corocznie 1 maja dla upamiętnienia i uznania osiągnięć klasy robotniczej. W Polsce dzień ten jest świętem państwowym ustawowo wolnym od pracy.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Dzień Flagi Rzeczypospolitej Polskiej",
        day: 2,
        month: 4,
        content: "Dzień Flagi Rzeczypospolitej to święto państwowe obchodzone 2 maja skupiające uwagę na symbolach państwowości polskiej, a w szczególności na Fladze RP. Jest to święto stosunkowo młode, bo obchodzone w Polsce dopiero od 2004 r., na mocy ustawy Sejmu RP z dnia 20 lutego 2004 roku. Dzień Flagi Rzeczypospolitej ma na celu propagować wiedzę o polskiej tożsamości oraz symbolach narodowych, które na przestrzeni wieków podlegały zmianie.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Święto Konstytucji 3 Maja",
        day: 3,
        month: 4,
        content: "Święto Konstytucji 3 maja jest świętem państwowym obchodzonym w Polsce na pamiątkę uchwalenia w 1791 roku konstytucji mającej na celu poprawę sytuacji politycznej Rzeczypospolitej Obojga Narodów. Jest to dzień ustawowo wolny od pracy.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Dzień Mamy",
        day: 26,
        month: 4,
        content: "Początki święta sięgają czasów starożytnych Greków i Rzymian. Kultem otaczano wtedy matki-boginie, symbole płodności i urodzaju. W późniejszych czasach cesarstwo rzymskie przyjęło chrześcijaństwo i tym samym zabroniono wyznawania innych bogów.",
        author: "wikipedia.org"
    },
    {
        title: "Dzień Dziecka",
        day: 1,
        month: 5,
        content: "Międzynarodowy Dzień Dziecka (MDD) dzień ustanowiony w 1954 przez Zgromadzenie Ogólne Organizacji Narodów Zjednoczonych (ONZ) dla upowszechniania ideałów i celów dotyczących praw dziecka zawartych w Karcie Narodów Zjednoczonych (1945) i obchodzony od 1955 w różne dni roku w różnych krajach członkowskich ONZ[2]; w Polsce MDD obchodzony jest 1 czerwca.",
        author: "wikipedia.org"
    },
    {
        title: "Dzień Taty",
        day: 23,
        month: 5,
        content: "Święto będące wyrazem szacunku i wdzięczności ojcom. W Polsce obchodzone jest corocznie 23 czerwca (od roku 1965).",
        author: "wikipedia.org"
    },
    {
        title: "Narodowy Dzień Pamięci Powstania Warszawskiego",
        day: 1,
        month: 7,
        content: "Narodowy Dzień Pamięci Powstania Warszawskiego jest polskim świętem państwowym, obchodzonym corocznie od 2010 roku. Święto zostało ustanowione w hołdzie bohaterom Powstania Warszawskiego, którzy w obronie kraju walczyli o wyzwolenie stolicy sprzeciwiając się okupacji niemieckiej oraz zagrażającej Polsce niewoli sowieckiej.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Święto Wojska Polskiego / Wniebowzięcie Najświętszej Maryi Panny",
        day: 15,
        month: 7,
        content: "Święto Wojska Polskiego to święto państwowe na pamiątkę zwycięstwa Polski w bitwie warszawskiej w 1920 r., stoczonej w ramach wojny polsko-bolszewickiej. Święto to wprowadzone ustawą Sejmu RP z 30 lipca 1992 r. jest dniem wolnym od pracy. / Wniebowzięcie NMP 15 sierpnia to w Kościele Katolickim bardzo ważne święto upamiętniające Wniebowzięcie Najświętszej Maryi Panny. Według kanonu wiary KK Maryja Matka Jezusa została po zakończeniu ziemskiego żywota wzięta do Nieba z duszą i ciałem. W Polsce i w wielu krajach europejskich czci się Matkę Boską Wniebowziętą jako patronkę ziemi i roślinności.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Dzień Solidarności i Wolności",
        day: 31,
        month: 7,
        content: "Dzień Solidarności i Wolności został ustanowiony przez Sejm RP w dniu 27 lipca 2005 roku świętem państwowym w celu upamiętnienia historycznego zrywu Polaków do wolności i niepodległości z 1980 roku, który zainicjował proces upadku komunizmu i wyzwolenia narodów Europy Środkowej i Wschodniej.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Dzień Chłopaka",
        day: 30,
        month: 8,
        content: "Dzień Chłopaka, to nowe, nieoficjalne święto obchodzone w Polsce 30 września, głównie wśród młodzieży szkolnej. Jest to w pewnym sensie męski, albo raczej chłopięcy, odpowiednik Dnia Kobiet. W dniu tym dziewczęta składają chłopcom życzenia i wręczają drobne upominki, aby wyrazić swoją sympatię. W Polsce jest to święto nieco bardziej popularne od Dnia Mężczyzn, co wiąże się zapewne z popularyzacją idei równości płci i większej aktywności ze strony kobiet, które dzięki niemu zyskują pretekst do okazania swojej sympatii dla chłopców.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Dzień Nauczyciela (Dzień Edukacji Narodowej)",
        day: 14,
        month: 9,
        content: "Dzień Edukacji Narodowej popularnie zwany Dniem Nauczyciela jest polskim świętem państwowym oświaty i szkolnictwa wyższego. Zostało ustanowione 27 kwietnia 1972 r. na mocy ustawy — Karta praw i obowiązków nauczyciela (Dz. U. z 1972 r. Nr 16, poz. 114) pod nazwą Dnia Nauczyciela, a następnie w 1982 r. przemianowane na Dzień Edukacji Narodowej (Dz. U. z 1982 r. Nr 3, poz. 19 z poźn. zm.). Święto to upamiętnia powstanie Komisji Edukacji Narodowej, która była pierwszym ministerstwem oświaty publicznej w Polsce.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Wszystkich Świętych",
        day: 1,
        month: 10,
        content: "Wszystkich Świętych to radosne chrześcijańskie święto ku czci wszystkich zmarłych, którzy osiągnęli stan zbawienia i przebywają w niebie, a więc są świętymi. Święto jest to zazwyczaj mylone z Dniem Zadusznym (2 listopada), kiedy to Kościół wspomina wszystkich wiernych zmarłych. Wynika to po części z faktu, że Wszystkich Świętych to w Polsce dzień wolny od pracy, dlatego to w tym dniu tradycyjnie odwiedzane są groby bliskich zmarłych.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Dzień zaduszny",
        day: 2,
        month: 10,
        content: "Dzień Zaduszny, często zwany Zaduszkami, w Kościele katolickim poświęcony jest wspomnieniu i modlitwie za wszystkich zmarłych wierzących w Chrystusa, których dusze, zgodnie z wierzeniami, przebywają w czyśćcu. W Polsce wierni najczęściej odwiedzają groby bliskich zmarłych i modlą się za nich dzień wcześniej, czyli w Dniu Wszystkich Świętych z racji tego, że jest to dzień ustawowo wolny od pracy. Nieodłączną tradycją w tym dniu jest również zapalanie zniczy na odwiedzanych grobach.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Narodowe święto Niepodległości",
        day: 11,
        month: 10,
        content: "Narodowe Święto Niepodległości jest to święto upamiętniające odzyskanie przez Polskę niepodległości w 1918 roku. Na 123 lata Polska została wymazana z mapy Europy, licząc od momentu ostatniego rozbioru dokonanego przez Austrię, Prusy i Rosję. Święto to - obchodzone 11 listopada każdego roku - zostało ustanowione w ostatnich latach II RP tuż przez rozpoczęciem II wojny światowej. Do kalendarza powróciło w 1989 r. Jest to dzień wolny od pracy.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Andrzejki (w nocy z 29 na 30)",
        day: 29,
        month: 10,
        content: "Andrzejki to wieczór wróżb odprawianych w nocy z 29 na 30 listopada w wigilię świętego Andrzeja. Data Andrzejek wypada zazwyczaj początku Adwentu, a więc na początku nowego Roku Liturgicznego, którego początek przypada w pierwszą niedzielę między 27 listopada, a 3 grudnia. Andrzejki były ostatnią okazją do zabawy przed rozpoczynającym się okresem Adwentu, w którym powstrzymywano się tradycyjnie od zabaw, podobnie jak w okresie Wielkiego Postu.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Barbórka (Dzień Górnika, Naftowca i Gazownika)",
        day: 4,
        month: 11,
        content: "Barbórka to inaczej Dzień Górnika, święto wszystkich górników obchodzone w dniu świętej Barbary z Nikomedii, które przypada na 4 grudnia [1]. W Kościele katolickim ma rangę wspomnienia dowolnego, tzn. jest dniem obchodów o najmniejszym znaczeniu [2,3]. W dniu tym swoje święto mają również przedstawiciele innych zawodów związanych z wydobywaniem paliw kopalnych, m.in. gazownicy i naftowcy.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Dzień św. Mikołaja",
        day: 6,
        month: 11,
        content: "Dzień Świętego Mikołaja obchodzony jest niezmiennie 6 grudnia każdego roku w wielu krajach świata. W Kościele Katolickim jest to wspomnienie świętego Mikołaja z Miry. Obecnie znana postać Świętego Mikołaja z kultury masowej to dobrotliwy starzec mieszkający w Laponii, ubrany w czerwony strój obszyty kożuchem, z charakterystyczną czapką z pomponem tego samego koloru, często z okularami na nosie i długą białą brodą.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Wigilia Bożego Narodzenia",
        day: 24,
        month: 11,
        content: "24 grudnia to dzień poprzedzający Boże Narodzenie, a więc jest to wigilia Bożego Narodzenia, święta obchodzonego przez Chrześcijan na pamiątkę przyjścia na świat Syna Bożego. Obchody Świąt Bożego Narodzenia pojawiły się prawdopodobnie w IV wieku naszej ery w Kościele na zachodzie Europy [1]. Również wtedy poprzedzał je Adwent, czas pełen modlitwy, uczestnictwa w nabożeństwach, ale też pokuty i postu w ramach przygotowania duchowego.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Boże Narodzenie (pierwszy dzień)",
        day: 25,
        month: 11,
        content: "Boże Narodzenie, to święto religijne (w tradycji chrześcijańskiej) upamiętniające narodziny Jezusa Chrystusa. Święto to obchodzone jest 25 grudnia (wg kalendarza gregoriańskiego). W kościołach, które nadal stosują kalendarz juliański (np. prawosławie), święto to obchodzone jest w późniejszym terminie (styczeń).",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Boże Narodzenie (drugi dzień)",
        day: 26,
        month: 11,
        content: "Boże Narodzenie, to święto religijne (w tradycji chrześcijańskiej) upamiętniające narodziny Jezusa Chrystusa. Święto to obchodzone jest 25 grudnia (wg kalendarza gregoriańskiego). W kościołach, które nadal stosują kalendarz juliański (np. prawosławie), święto to obchodzone jest w późniejszym terminie (styczeń).",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Narodowy Dzień Zwycięskiego Powstania Wielkopolskiego",
        day: 27,
        month: 11,
        content: "Powstanie wielkopolskie polskich mieszkańców zaboru pruskiego przeciwko państwu niemieckiemu (Republice Weimarskiej) trwało od 27 grudnia 1918 r. do 16 lutego 1919 r. Zakończyło się zwycięstwem. Większość ziem Prowincji Poznańskiej włączono do Rzeczypospolitej Polskiej i utworzono z niej województwo poznańskie.",
        author: "kalendarzswiat.pl"
    },
    {
        title: "Sylwester",
        day: 31,
        month: 11,
        content: "Wigilia Nowego Roku, dzień poprzedzający Nowy Rok – 31 grudnia (imieniny Sylwestra; w Kościele katolickim, jako wspomnienie liturgiczne papieża Sylwestra I), kiedy to świętuje się koniec starego roku i początek nowego, stanowiąca czas hucznych zabaw i balów, toastów, sztucznych ogni, petard lub pokazów laserowych.",
        author: "kalendarzswiat.pl"
    }
]

export default currentEvents;