import React, { useState, useRef } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faArrowRight, faArrowDown, faBars, faCompass, faXmark, faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { checkUserTargetId } from "./App";

function Navbar(props){

  // const [searchBox, setSearchBox] = useState("bx-search");
  const [navbar, setNavbar] = useState("");
  const [navlinks, setNavlinks] = useState("-100%");
  const [navDisplay, setNavDisplay] = useState("");

  // const [htmlcssArrow, setHtmlcssArrow] = useState("");
  // const [moreArrow, setMoreArrow] = useState("");
  // const [jsArrow, setJsArrow] = useState("");

  // const [arrows, setArrows] = useState([kultura = "", gastronomia = "", nauka = ""]);
  // const [arrows, setArrows] = useState(["", "", ""]);
  const [arrows, setArrows] = useState("");

    function handleClick(event){
      // console.log(event.target.name);
      scrollWindowTop();
        const category = event.target.name;
        toggleMenuButton();
        // toggleNavbar();
        turnOffNavbar();
        clearArrows();

        // props.handleClick("Muzea");
        props.handleClick(category);
        // console.log("Clicked value: " + event.target.name);
        // console.log(category);
    };

    function scrollWindowTop(){
      window.scrollTo({
        top: 0,
        behavior: "instant"
    });
    };

    // function handleClickStart(event){
    //   const category = event.target.name;
    //   setHtmlcssArrow("");
    //   setMoreArrow("");
    //   setJsArrow("");

    //   props.handleClick(category);
    // }

    function handleClickStart(event){
      scrollWindowTop();
      const category = event.target.name;
      setNavlinks("-100%");
      setNavbar("");
      turnOffNavbar();
      clearArrows();

      props.handleClick(category);
    }

    // function toggleSearchBox(){
    //   toggleNavbar();
    //   navbar === "showInput" ? setSearchBox("bx-x") : setSearchBox("bx-search");
    // };

    // function toggleNavbar(){
    //   navbar === "showInput" ? setNavbar("") : setNavbar("showInput");
    // };

    async function turnOffNavbar(){
      setNavDisplay("none");
      await timeout(300);
      setNavDisplay("");
    }

    function toggleMenuButton(){
      // console.log("Navlinks: " + navlinks);
      navlinks === "0" ? setNavlinks("-100%") : setNavlinks("0");
    };

    // function handleHtmlcssArrowClick(){
    //   htmlcssArrow === "" ? setHtmlcssArrow(" show1 ") : setHtmlcssArrow("");
    // };

    // function handleMoreArrowClick(){
    //   moreArrow === "" ? setMoreArrow(" show2 ") : setMoreArrow("");
    // };

    // function handleJsArrowClick(){
    //   jsArrow === "" ? setJsArrow(" show3 ") : setJsArrow("");
    // };

    function handleArrowClick(arrow){
      // clearArrows();
      switch (arrow) {
        case "kultura":
          // htmlcssArrow === "" ? setHtmlcssArrow(" show1 ") : setHtmlcssArrow("");
          // setArrows([...kultura === "" ? kultura = "show" : ""]);
          // setArrows([kultura === "" ? setHtmlcssArrow(" show1 ") : setHtmlcssArrow("")])
          setArrows(arrows === "" ? " show1 " : arrows === " show1 " ? "" : " show1 ");
          break;
          case "gastronomia":
            // moreArrow === "" ? setMoreArrow(" show2 ") : setMoreArrow("");
            // setArrows([...gastronomia === "" ? gastronomia = "show" : ""]);
            // setArrows(" show2 ");
            setArrows(arrows === "" ? " show2 " : arrows === " show2 " ? "" : " show2 ");
          break;
          case "nauka":
            // jsArrow === "" ? setJsArrow(" show3 ") : setJsArrow("");
            // setArrows([...nauka === "" ? nauka = "show" : ""]);
            // setArrows(" show3 ");
            setArrows(arrows === "" ? " show3 " : arrows === " show3 " ? "" : " show3 ");
          break;
      
        default:
          break;
      }
    }

    function clearArrows(){
      // setHtmlcssArrow("");
      // setMoreArrow("");
      // setJsArrow("");

      // setArrows(
      //   arrows.forEach((element) => element = "")
      // );
      setArrows("");
    }

    function timeout(delay) {
      return new Promise( res => setTimeout(res, delay) );
   };

   function getUserName(){
    if (props.userData === undefined || props.userData === null || props.userData.username === undefined){
      return "KONTO";
    }

    if (props.userData.username.length > 10){
      return props.userData.username.substring(0, 7) + "...";
    }
    
    return props.userData.username;
   }

    // function handleNavlinkClick(value){
    //   switch (value){
    //     case 0:
    //       htmlcssArrow === "" ? setHtmlcssArrow(" show1 ") : setHtmlcssArrow("");
    //       break;
    //       case 1:
    //         moreArrow === "" ? setMoreArrow(" show2 ") : setMoreArrow("");
    //         break;
    //         case 2:
    //           jsArrow === "" ? setJsArrow(" show3 ") : setJsArrow("");
    //           break;
    //           default:
    //             console.log("Wrong nav links value");
    //             break;
    //   };
    // };

    return (
<nav>
    <div className={"navbar " + navbar}>
      {/* <i className='bx bx-menu'></i> */}
      <a><i><FontAwesomeIcon onClick={toggleMenuButton} className='bx bx-menu' icon={faBars}>
        </FontAwesomeIcon></i></a>

      {/* <div className="logo"><a href="#" onClick={handleClickStart} name="Start"><FontAwesomeIcon icon={faCalendarDays}>
        </FontAwesomeIcon> wolne.info</a></div> */}

          {/* <div className="logo"><NavLink to={"/"} onClick={handleClickStart} name="Start"><FontAwesomeIcon icon={faCalendarDays}>
        </FontAwesomeIcon> wolne.info</NavLink></div> */}
        <div className="logo"><NavLink to={"/"} onClick={handleClickStart} name="Start">znane<span>M</span>iasto.pl</NavLink></div>
        
      {/* <div className={"nav-links" + htmlcssArrow + moreArrow + jsArrow} style={{ left: navlinks}} > */}
      <div className={"nav-links" + arrows} style={{ left: navlinks}} >
        <div className="sidebar-logo">
        <NavLink to={"/"} onClick={handleClickStart} name="Start">
          <span className="logo-name">znane<span>M</span>iasto.pl</span>
          </NavLink>
          {/* <i className='bx bx-x' ></i> */}
          <a><i><FontAwesomeIcon onClick={toggleMenuButton} className='bx bx-x' icon={faXmark}>
            </FontAwesomeIcon></i></a>
        </div>
        <ul className="links">
          {/* <li><a href="#" onClick={handleClick} name="Start">START</a></li> */}
          {/* <li><NavLink onClick={handleClick} to={"/"}>START</NavLink></li> */}
          <li><NavLink to={"eventy"} onClick={handleClick} name="Events">EVENTY</NavLink></li>

          <li>
            {/* <a href="#" onClick={handleHtmlcssArrowClick}>KULTURA</a> */}
            {/* <NavLink to={"muzea"} onClick={handleHtmlcssArrowClick}>KULTURA</NavLink> */}
            {/* <NavLink onClick={handleHtmlcssArrowClick}>KULTURA</NavLink> */}
            <NavLink onClick={() => {handleArrowClick("kultura")}}>KULTURA</NavLink>
            {/* <a href="#" onClick={handleHtmlcssArrowClick}><i>
              <FontAwesomeIcon className='bx bxs-chevron-down htmlcss-arrow arrow ' icon={faArrowDown}>
              </FontAwesomeIcon></i></a> */}
              {/* <NavLink onClick={handleHtmlcssArrowClick}><i> */}
              <NavLink onClick={() => {handleArrowClick("kultura")}}><i>
              {/* <FontAwesomeIcon className='bx bxs-chevron-down htmlcss-arrow arrow ' icon={faArrowDown}> */}
              <FontAwesomeIcon className='bx bxs-chevron-down kultura-arrow arrow ' icon={faArrowDown}>
              </FontAwesomeIcon></i></NavLink>
            {/* <ul className="htmlCss-sub-menu sub-menu" style={{display: navDisplay}}> */}
            <ul className="kultura-sub-menu sub-menu" style={{display: navDisplay}}>
              <li><NavLink to={"kultura/muzea"} onClick={handleClick} name="Muzea">MUZEA</NavLink></li>
              <li><NavLink to={"kultura/teatry"} onClick={handleClick} name="Teatry">TEATRY</NavLink></li>
              <li><NavLink to={"kultura/kina"} onClick={handleClick} name="Kina">KINA</NavLink></li>
              <li><NavLink to={"kultura/zabytki"} onClick={handleClick} name="Zabytki">ZABYTKI</NavLink></li>
              <li><NavLink to={"kultura/kulturaInne"} onClick={handleClick} name="KulturaInne">INNE</NavLink></li>
            </ul>
          </li>

          <li>
            <NavLink onClick={() => {handleArrowClick("gastronomia")}}>GASTRONOMIA</NavLink>
              <NavLink onClick={() => {handleArrowClick("gastronomia")}}><i>
              {/* <FontAwesomeIcon className='bx bxs-chevron-down js-arrow arrow ' icon={faArrowDown}> */}
              <FontAwesomeIcon className='bx bxs-chevron-down gastronomia-arrow arrow ' icon={faArrowDown}>
              </FontAwesomeIcon></i></NavLink>
            {/* <ul className="js-sub-menu sub-menu" style={{display: navDisplay}}> */}
            <ul className="gastronomia-sub-menu sub-menu" style={{display: navDisplay}}>
              <li><NavLink to={"gastronomia/restauracje"} onClick={handleClick} name="Restauracje">RESTAURACJE</NavLink></li>
              <li><NavLink to={"gastronomia/fastFoody"} onClick={handleClick} name="FastFoody">FAST FOODY</NavLink></li>
              <li><NavLink to={"gastronomia/kawiarnie"} onClick={handleClick} name="Kawiarnie">KAWIARNIE</NavLink></li>
              <li><NavLink to={"gastronomia/desery"} onClick={handleClick} name="Desery">DESERY</NavLink></li>
              <li><NavLink to={"gastronomia/gastronomiaInne"} onClick={handleClick} name="GastronomiaInne">INNE</NavLink></li>
            </ul>
          </li>

          <li>
            {/* <NavLink onClick={handleJsArrowClick}>NAUKA</NavLink>
              <NavLink onClick={handleJsArrowClick}><i> */}
              <NavLink onClick={() => {handleArrowClick("nauka")}}>NAUKA</NavLink>
              <NavLink onClick={() => {handleArrowClick("nauka")}}><i>
              {/* <FontAwesomeIcon className='bx bxs-chevron-down js-arrow arrow ' icon={faArrowDown}> */}
              <FontAwesomeIcon className='bx bxs-chevron-down nauka-arrow arrow ' icon={faArrowDown}>
              </FontAwesomeIcon></i></NavLink>
            {/* <ul className="js-sub-menu sub-menu" style={{display: navDisplay}}> */}
            <ul className="nauka-sub-menu sub-menu" style={{display: navDisplay}}>
              <li><NavLink to={"nauka/biblioteki"} onClick={handleClick} name="Biblioteki">BIBLIOTEKI</NavLink></li>
              <li><NavLink to={"nauka/ksiegarnie"} onClick={handleClick} name="Księgarnie">KSIĘGARNIE</NavLink></li>
              <li><NavLink to={"nauka/kursy"} onClick={handleClick} name="Kursy">KURSY</NavLink></li>
              <li><NavLink to={"nauka/targi"} onClick={handleClick} name="Targi">TARGI I KIERMASZE</NavLink></li>
              <li><NavLink to={"nauka/naukaInne"} onClick={handleClick} name="NaukaInne">INNE</NavLink></li>
            </ul>
          </li>

          {/* <li><a href="#">ROZRYWKA</a></li>

          <li><a href="#">EDUKACJA</a></li> */}

          {/* <li><a href="#" onClick={handleClick} name="Articles">ARTUKUŁY</a></li> */}
          {/* <li><NavLink to={"artykuly"} onClick={handleClick} name="Articles">ARTYKUŁY</NavLink></li> */}

          {/* <li><a href="#" onClick={handleClick} name="Contact">KONTAKT</a></li> */}
          {/* <li><NavLink to={"artykuly"} onClick={handleClick} name="Articles">ARTUKUŁY</NavLink></li> */}
          
          <li><NavLink to={"szukaj"} onClick={handleClick} name="Search">SZUKAJ</NavLink></li>
          <li><NavLink to={"kontakt"} onClick={handleClick} name="Contact">KONTAKT</NavLink></li>

          {/* { props.userLogged === true ? ( */}
          { props.userData.username !== "" ? 
          // checkUserTargetId() === undefined || checkUserTargetId() === null ? 
          (
          <li>
          {/* <NavLink to={"uzytkownik"} onClick={handleClick} name="UserAccount">{props.userData.username}</NavLink> */}

          {/* <NavLink to={"uzytkownik"} onClick={handleClick} name="UserAccount">KONTO</NavLink> */}
          <NavLink to={"uzytkownik"} onClick={handleClick} name="UserAccount">{getUserName()}</NavLink>

          {/* <NavLink to={"uzytkownik"} onClick={handleClick} name="UserAccount">UŻYTKOWNIK</NavLink> */}
          </li> 
        ) 
        // :
        // <li>
        //       <NavLink onClick={() => {handleArrowClick("uzytkownik")}}>{props.userData.username}</NavLink>
        //       <NavLink onClick={() => {handleArrowClick("uzytkownik")}}><i>
        //       <FontAwesomeIcon className='bx bxs-chevron-down nauka-arrow arrow ' icon={faArrowDown}>
        //       </FontAwesomeIcon></i></NavLink>
        //     <ul className="nauka-sub-menu sub-menu" style={{display: navDisplay}}>
        //       <li><NavLink to={"uzytkownik"} onClick={handleClick} name="UserAccount">KONTO</NavLink></li>
        //       <li><NavLink to={"panelAdmina"} onClick={handleClick} name="AdminPanel">PANEL ADMINA</NavLink></li>
        //     </ul>
        //   </li>
        : 
        (
          <li>
          {/* <a className="nav-link" onClick={handleClick} name="UserLogin">Logowanie</a> */}
          {/* <a href="#" onClick={handleClick} name="UserLogin">ZALOGUJ</a> */}
          {/* <NavLink to={"logowanie"} onClick={handleClick} name="UserLogin">ZALOGUJ</NavLink> */}
          <NavLink to={"logowanie"} onClick={handleClick} name="UserLogin">KONTO</NavLink>
        </li>
        )}

          {/* <li><a href="#">ZALOGUJ</a></li> */}

        </ul>
      </div>
      {/* <div className="search-box">
      <i><FontAwesomeIcon className='bx bx-search' onClick={toggleSearchBox} icon={faMagnifyingGlass} style={{color: "#ffffff",}} /></i>
        <div className="input-box">
          <input type="text" placeholder="Search..."></input>
        </div>
      </div> */}

    </div>
  </nav>
    );
};

export default Navbar;