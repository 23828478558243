import React, { useEffect } from "react";

function PrivacyPolicy(props){

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, []);

    return(
        <div>
            <h1>Polityka Prywatności</h1>
            <div className="home-sections">
                <p>Zgodnie z art. 13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w 
                sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej „RODO”), przedstawiamy Państwu podstawowe informacje dotyczące przetwarzania danych osobowych:
                </p>
                <p>
                    Administratorem, czyli podmiotem przetwarzającym Pani/Pana dane osobowe jest znanemiasto.pl z siedzibą przy ul. Plac Wolnica 14, 30-060 Kraków, gminna instytucja kultury wpisana do rejestru instytucji kultury prowadzonego przez Gminę Miejską Kraków pod numerem 19 (dalej „Administrator”).
                </p>
                <p>
                    Z Administratorem można się skontaktować w następujący sposób: elektronicznie na adres e-mail: biuro@znanemiasto.pl lub pisemnie pod adresem: znanemiasto.pl z siedzibą przy ul. Plac Wolnica 14, 30-060 Kraków.
                </p>
                <ul>
                Administrator przetwarza Pani/Pana dane w następujących celach:
<li>1. zawarcia i realizacji umowy (podstawa prawna: art. 6 ust 1 lit. b RODO)</li>
<li>2. realizacji obowiązków Administratora przewidzianych prawem, w tym w szczególności wystawiania i przechowywania faktur oraz dokumentów księgowych (podstawa prawna: art. 6 ust.1 lit. c RODO)</li>
<li>3. w celach i w zakresie związanym z udziałem w projektach realizowanych przez Administratora, w tym w celu rezerwacji biletu i dokonania rozliczeń związanych z udziałem uczestnika w projekcie podstawa prawna art. 6 ust. 1 lit. b. RODO</li>
<li>4. marketingu i promocji produktów i usług Administratora (podstawa prawna: art. 6 ust.1 lit. a i f RODO)</li>
<li>5. w celach archiwalnych (dowodowych), ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami, zapewnienia bezpieczeństwa osób i mienia na podstawie prawnie uzasadnionego interesu Administratora (podstawa prawna: art. 6 ust. 1 lit. f RODO).</li>
                </ul>
                <p>
                Pani/Pana dane osobowe będą przetwarzane przez okres niezbędny do realizacji wskazanych celów, do których zostały zebrane zgodnie z kryteriami określonymi w przepisach prawa w tym do zabezpieczenia ewentualnych roszczeń przez okres 10 lat.
                </p>
                <p>
                W przypadku przetwarzania w celu marketingowym na podstawie Państwa zgody (art. 6 ust 1 lit a RODO) do momentu wycofania przez Państwa takiej zgody.
                </p>
                <p>
                Pani/Pana dane osobowe mogą być udostępniane przez Administratora następującym kategoriom odbiorców:
                </p>
                <ul>
                <li>1. podmiotom uprawnionym do tego na mocy przepisów powszechnie obowiązujących</li>
<li>2. podmiotom przetwarzającym dane w imieniu Administratora tj. podmiotom, które przetwarzają dane jako podwykonawcy, na podstawie umowy i wyłącznie zgodnie z poleceniami Administratora.</li>
                </ul>
                <p>
                Administrator informuje, że przysługują Pani/Panu następujące prawa:
                </p>
                <ul>
                <li>1. dostępu do treści Pani/Pana danych osobowych oraz do uzyskania informacji m.in.
o kategoriach danych, celach ich przetwarzania, a także do uzyskania ich kopii</li>
<li>2. sprostowania nieprawidłowych i uzupełnienia brakujących danych, a także do bycia zapomnianym, co oznacza, że ma Pani/Pan prawo do usunięcia danych przetwarzanych bezpodstawnie i bezprawnie (np. dane nie są już niezbędne do celów, w których zostały zebrane)</li>
<li>3. wniesienia sprzeciwu wobec przetwarzania danych oraz prawo do ograniczenia przetwarzania danych. W takiej sytuacji po rozpatrzeniu wniosku Administrator nie będzie mógł przetwarzać danych osobowych udostępnionych przez Panią/Pana, chyba że wykaże istnienie ważnych, prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec interesów, praw i wolności osoby, której dane dotyczą lub podstaw do ustalenia, dochodzenia lub obrony roszczeń</li>
<li>4. przenoszenia danych przetwarzanych w sposób zautomatyzowany, co oznacza iż ma Pani/Pan prawo żądać, by dane osobowe zostały przesłane przez Administratora bezpośrednio innemu administratorowi, o ile jest to technicznie możliwe</li>
<li>5. prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem (jeżeli przetwarzanie odbywa się na podstawie art. 6 ust. 1 lit. a. lub art. 9 ust. 2 lit. a RODO)</li>
<li>6. prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, o ile uczestnik uzna, że przetwarzanie danych osobowych odbywa się z naruszeniem postanowień RODO.</li>
                </ul>
                <p>
                Podanie danych do celów zawarcia i realizacji umowy mam charakter dobrowolny, jednak konsekwencją niepodania danych będzie brak możliwości realizacji umowy.
                </p>
                <p>
                W przypadku zgody na przetwarzanie danych w celu wskazywanym przez Administratora i udzieleniu zgody na takie przetwarzanie danych, w tym w celach marketingowych – podanie danych osobowych jest zawsze dobrowolne a zgoda na ich przetwarzanie może być wycofana w każdej chwili.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy;