import React from "react";
import { Link } from "react-router-dom";

function Tag(props){

    function handleClick(){
        if (props.clickFunction !== undefined && props.clickFunction !== null){
            props.clickFunction(props.id);
        }
    }

    return(
        <div className="tag">
            {props.clickFunction !== undefined && props.clickFunction !== null ?
            <button type="button" className="btn btn-light" onClick={handleClick}>{props.tagName}</button> :
            <Link to={props.clickFunction !== undefined && props.clickFunction !== null ? "" : "/szukaj"}  
            type="button" className="btn btn-light" onClick={handleClick}>{props.tagName}</Link>
        }
            {/* <button type="button" className="btn btn-light" onClick={handleClick}>{props.tagName}</button> */}
            {/* <Link to={props.clickFunction !== undefined && props.clickFunction !== null ? "" : "/szukaj"}  
            type="button" className="btn btn-light" onClick={handleClick}>{props.tagName}</Link> */}
        </div>
    )
}

export default Tag;