import React, { useEffect } from "react";
import CategoryTitle from "./CategoryTitle";

function About(props){

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, []);

    return(
        <div>
            {/* <h1>O nas</h1> */}
            <CategoryTitle category = { "O nas" } categoryInfo = { "" } />
            <div className="home-sections">
            <p>Portal powstał pod koniec 2023 roku, w szczególności dla osób, które nie znają miasta, ale również
                dla wszystkich, którzy chcieliby na bieżąco wiedzieć co dzieje się w ich mieście.
            </p>
            <p>Jesteśmy portalem zajmującym się promowaniem ciekawych miejsc oraz wydarzeń w Krakowie.</p>
            <p>Dla naszych użytkowników stanowimy cenne źródło informacji o pobliskich miejscach oraz wydarzeniach.
                <br></br>Pozwalamy dowolnie wyszukiwać, sortować i przeglądać dokładne informacje o miejscach oraz wydarzeniach 
                w sposób spójny, przejrzysty a także najszybszy.
                <br></br>Niezależnie od wieku lub zainteresowań, tego czy chcemy się gdzieś udać, czy dopiero planujemy wypad, 
                w naszej bazie danych każdy znajdzie coś dla siebie.
            </p>
            <p>Dla właścicieli punktów i organizatorów wydarzeń stanowimy najlepszą możliwą reklamę, trafiającą 
                dokładnie tam, gdzie oczekują, czyli do użytkowników, którzy chcieliby spędzić gdzieś swój czas wolny. 
                <br></br>Do tych, którzy szukają podobnych miejsc i być może trafiają do nich przypadkowo przy okazji 
                przeglądania podobnego miejsca lub wydarzenia.
            </p>
            <p>Łączymy więc oraz pomagamy jednym i drugim, dla wspólnego celu, mianowicie, aby wszystkim 
                żyło się <b>lepiej, łatwiej i przyjemniej</b>.
            </p>
            </div>
        </div>
    )
}

export default About;