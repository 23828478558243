import React, {useState, useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
// import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "./ContactForm.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

function ContactForm(props) {
    const [contactForm, setContactForm] = useState({
        title: "Zgłoszenie nowego miejsca",
        email: props.userData.email,
        content: ""
    });
    const [showContactForm, setShowContactForm] = useState(true);
    const [errorText, setErrorText] = useState({
        color: "",
        content: ""
    });
    const maxFormContent = 1000;
    // const [recaptcha, setRecaptcha] = useState(false);
    // const key = process.env.REACT_APP_RECAPTCHA_KEY;
    // const [showEmailField, setShowEmailField] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
      }, []);

    function handleChange(event) {
        // const newValue = event.target.value;
        // const inputName = event.target.name;
        const {name, value} = event.target;

        setContactForm(prevValue =>{
            return{
                ...prevValue,
                [name]: value
            }
        });
      }

      function submitForm(){
        if (isValid() === false){
            return;
        };

        // console.log("Form submitted: ");
        // console.log(contactForm);

        axios.post(process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SEND_FORM, {
            email: contactForm.email,
            title: contactForm.title,
            content: contactForm.content
        }).then(response =>{
            responseSubmitForm(response);
            // toast("Formularz wysłany poprawnie");
        }).catch(err =>{
            // console.log(err);
            responseSubmitForm(err.response);
            // toast("Błąd formularza!");
        });

        setErrorText({ color: "black", content: "..."});
        setShowContactForm(false);
      }

      function responseSubmitForm(response){
        if (response === undefined || response.status === undefined){
            return;
        };

        switch (response.status){
            case 200:
                setErrorText({ color: "green", content: "Formularz został wysłany poprawnie."});
                break;
                default:
                    setErrorText({ color: "red", content: "Błąd wysłania formularza."});
                    break;
        }
        
      }

      function isValid(){
        if (contactForm.content.length > maxFormContent){
            setErrorText({ color: "red", content: "Formularz nie może przekraczać " + maxFormContent + 
            " znaków. Teraz ma " + contactForm.content.length + " znaków."})
            return false;
        };

        if (contactForm.content.length <= 0){
            setErrorText({ color: "red", content: "Formularz jest pusty."})
            return false;
        }

        return true;
      }

      // function loginOnClick(){
      //   props.changeState("UserLogin");
      // }

    //   function handleRecaptcha(){
    //     setRecaptcha(!recaptcha);
    // };

  return (
    <div className="contactParent">
      <h1>Kontakt</h1>

        {props.userLogged === false ?
    <div className="contactForm-text">
        {/* <p>Aby się z nami skontaktować <a href="#" pointerEvents="none" onClick={loginOnClick}>zaloguj się</a>. */}
        {/* <p>Aby wyświetlić formularz kontaktowy <a href="#" pointerEvents="none" onClick={loginOnClick}>zaloguj się</a>. */}
        <p>Aby wyświetlić formularz kontaktowy <Link to={"/logowanie"}>zaloguj się</Link>.
        </p>
    </div> : props.userData.active === false ?
    <div className="contactForm-text">
        <p>Aby wyświetlić formularz kontaktowy kliknij w link weryfikacyjny wysłany na podany adres email.</p>
        </div> : showContactForm === false ? null :
    <div>
<form className="create-comment contactForm">
      <input
      className="contactForm-email"
            name="email"
            onChange={handleChange}
            value={contactForm.email}
            placeholder={props.userData.email}
            disabled
          />
          <hr></hr>

          {/* <input
            name="title"
            onChange={handleChange}
            value={contactForm.title}
            placeholder="tytuł"
          /> */}
          <label>temat kontaktu: </label>
          <select>
            <option>Zgłoszenie nowego miejsca</option>
            <option>Błąd na stronie</option>
            <option>Inny</option>
          </select>

          <hr></hr>

          {/* <div className="recaptcha">
            <ReCAPTCHA sitekey={key} onChange={handleRecaptcha} />
            </div> */}

        <textarea
          name="content"
          onChange={handleChange}
          value={contactForm.content}
          placeholder="treść..."
        />
        <Zoom in={true}>
        {/* <Button variant="contained" onClick={submitForm}> */}
          {/* <AddIcon /> */}
          
          {/* Wyślij */}
        {/* </Button> */}
        <button type="button" className="btn btn-primary" onClick={submitForm}>Wyślij</button>
        </Zoom>
        <p className="contact-form-error" style={{color: errorText.color}}>{errorText.content}</p>
      </form>
        </div>
    }

    {/* <div className="contact-form-error">
    <p style={{color: errorText.color}}>{errorText.content}</p>
    </div> */}

    <div className="contactForm-text">
        <p>Lub spróbuj bezpośrednio wyłać wiadomośc na nasz adres email:</p>
        <a href="mailto:kontakt@znanemiasto.pl">kontakt@znanemiasto.pl</a>
    </div>

    <ToastContainer />
    </div>
  );
}

export default ContactForm;