import React from "react";
import { getPlaceStarsRating, getShortDate, getStarsImages } from "./App";

function Review(props){
    return (
                    <div className="review">
                        {/* <p>Autor: {props.item.author === undefined || props.item.author === "" ?  */}
                        <p><span className="user-avatar"><img src={process.env.PUBLIC_URL + "/icons/user.png"} />
                        </span>{props.item.author === undefined || props.item.author === "" ? 
                        "recenzent wolne.info" : props.item.author}</p>
                        <hr></hr>
                        {/* <p>Ocena: {getPlaceStarsRating(props.item)}</p> */}
                        <div className="profile-stars-rating">
                            <span>
                            <p>{getStarsImages(getPlaceStarsRating(props.item))}</p>
                            </span>
                        </div>

                        {/* <p>Komentarz: </p> */}
                        {/* <hr></hr> */}
                        <p>{props.item.content}</p>
                        <div className="comment-date">
                        <p>Dodano: {getShortDate(props.item.date)}</p>
                        </div>
                    </div>
    )
};

export default Review;