import React, {useEffect, useState} from "react";
import axios from "axios";
import "./Home.css";
import currentArticles from "./articles/currentArticles";
import UpcomingEvent from "./UpcomingEvent";
import currentEvents from "./currentEvents";
import { Link } from "react-router-dom";
import { getEventDate, getEventHours, getMonthName } from "./App";
import UpcomingInfo from "./UpcomingInfo";
import currentInfo from "./currentInfo";

function Home(props){

    const [places, setPlaces] = useState(null);
    const [events, setEvents] = useState(null);
    const [showCarousel, setShowCarousel] = useState(false);
    const [showEventCarousel, setShowEventCarousel] = useState(false);
    const [eventNumber, setEventNumber] = useState(0);
    const [eventDisabled, setEventDisabled] = useState({
      previous: "disabled",
      next: ""
    });
    const numberOfPlaces = 3;
    const numberOfCelebrations = 3;
    const numberOfEvents = 3;
    const [celebrationsToShow, setCelebrationsToShow] = useState([]);

    useEffect(() =>{
      window.scrollTo({
        top: 0,
        behavior: "instant"
    })

        fetchRandomEvents();
        fetchRandomPlaces();
        getCelebrations();
    },[]);

    function fetchRandomPlaces(){
        // axios.post(REACT_APP_RANDOM_PLACES_URL + numberOfPlaces, {
        //     setPlaces()
        // })
        // axios.get(process.env.REACT_APP_RANDOM_PLACES_URL + numberOfPlaces).then(result =>{

          if (places !== null)
          return;

        //     axios.get(process.env.REACT_APP_RANDOM_PLACES_URL + numberOfPlaces).then(result =>{
        //       if (result.data !== null && result.data !== undefined && result.data.length > 0){
        //         setPlaces(result.data);
        //         setShowCarousel(true);
        //       }
        // }).catch(err => {
        //     console.log(err);
        // });
        axios({
          method: "POST",
          data: {
            ip: props.addressIp
          },
          withCredentials: true,
          url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_RANDOM_PLACES_URL + numberOfPlaces,
        }).then((res) => {
            if (res.data !== null && res.data !== undefined && res.data.length > 0){
              setPlaces(res.data);
              setShowCarousel(true);
        }
        }).catch((err) =>[
          console.log(err)
        ]);
    };

    function fetchRandomEvents(){
        if (events !== null)
        return;

      //     axios.get(process.env.REACT_APP_RANDOM_EVENTS_URL + numberOfEvents).then(result =>{
      //       if (result.data !== null && result.data !== undefined && result.data.length > 0){
      //         setEvents(result.data);
      //         setShowEventCarousel(true);
      //       }
      // }).catch(err => {
      //     console.log(err);
      // });

      axios({
        method: "POST",
        data: {
          ip: props.addressIp
        },
        withCredentials: true,
        url: process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_RANDOM_EVENTS_URL + numberOfEvents,
      }).then((res) => {
            if (res.data !== null && res.data !== undefined && res.data.length > 0){
              setEvents(res.data);
              setShowEventCarousel(true);
            }
      }).catch((err) =>[
        console.log(err)
      ]);
  };

    // function handleCarouselItemClick(event){
    //     const key = event.target.className[0];
    //     let place = {};

    //     switch (key){
    //         case "f":
    //             place = places[0];
    //             break;
    //             case "s":
    //                 place = places[1];
    //                 break;
    //                 case "t":
    //                     place = places[2];
    //                     break;
    //                     default:
    //                         return;
    //     }
    //     props.setCurrentPlace(place);
    //     props.changeState("Profile");
    // }

    function compareInfoDate(info){

      if (info === undefined){
        return false;
      }

      let infoDate = new Date(info.date);
      let currentDate = new Date();

      if (infoDate >= currentDate){
        return true;
      } else {
        return false;
      }
    }

    function handleEventClick(event){
      // console.log(eventsToShow);
      const key = event.target.className[0];

      switch (key){
        case "f":
            setEventNumber(0);
            break;
            case "s":
              setEventNumber(1);
                break;
                case "t":
                  setEventNumber(2);
                    break;
                    default:
                        return;
    }

    // setEventDisabled({
    //   previous: eventNumber > 0 ? "" : "disabled",
    //   next: eventNumber < (numberOfEvents - 1) ? "" : "disabled"
    // })
  }

  function handleEventDirectionClick(event){
    const key = event.target.className[0];

    switch (key){
      case "p":
        if (eventNumber > 0){
          setEventNumber(eventNumber - 1)
        }
        break;
        case "n":
          if (eventNumber < (numberOfCelebrations - 1)){
            setEventNumber(eventNumber + 1)
          }
          break;
          default:
            break;
    }

    // setEventDisabled({
    //   previous: eventNumber > 0 ? "" : "disabled",
    //   next: eventNumber < (numberOfEvents - 1) ? "" : "disabled"
    // })
  }

  function handleGetAllArticlesClick(){
    props.changeState("Articles");
  }

  function handleeGetArticleClick(event){
    const key = event.target.className[0];

    switch(key){
      case "f":
        props.setCurrentArticle(currentArticles[0]);
        break;
        case "s":
          props.setCurrentArticle(currentArticles[1]);
          break;
          default:
            break;
    }
    props.changeState("Articles");
  }

  function getCelebrations(){

    if (celebrationsToShow.length > 0){
      return;
    }

    let celebrations = [];
    let currentMonth = new Date().getMonth();
    let celebrationsNeeded = numberOfCelebrations;

    // console.log(currentEvents);

    // while(events.length < 4){
    //   for (let i = 0; i < currentEvents; i++){
    //     if (currentEvents[i].month === currentMonth){
    //       events.push(currentEvents[i]);
    //     }
    //   }

    //   currentMonth += 1;
    // }

    // for (let i = 0; i < currentEvents.length; i++){
    //   if (currentEvents[i].month === currentMonth){
    //     if (eventsToShow.includes(currentEvents[i]) === false){
    //       events.push(currentEvents[i]);
    //     }
    //   }
    // }

    while(celebrations.length < celebrationsNeeded){
      for (let i = 0; i < currentEvents.length; i++){
        if (currentEvents[i].month === currentMonth){
          if (currentEvents[i].month === new Date().getMonth() && currentEvents[i].day < new Date().getDate()){
            continue;
          } else {
            if (celebrations.includes(currentEvents[i]) === false){
              celebrations.push(currentEvents[i]);
            }
          }
        }
      }

      currentMonth += 1;
      // currentMonth++;
    }

    setCelebrationsToShow(celebrations);
  }

    return(
        <div>
            {/* <h1>Strona główna</h1> */}

            {/*********************************** INFO ***************************************/ }

            { currentInfo !== null && currentInfo !== undefined && currentInfo.length > 0 && 
            compareInfoDate(currentInfo[currentInfo.length - 1]) ?
            <div>
                        <div className="home-sections section-info">

                        <div>
                        <h3>Informacje</h3>
          
                          <UpcomingInfo info={currentInfo[currentInfo.length - 1]} />
                        </div>
          
                      </div> 
                      
                      <hr className="section-hr"></hr>
                      </div>
                      : null
            }



            {/*********************************** NADCHODZĄCE WYDARZENIA ***************************************/ }

            

            {showEventCarousel && events !== null ?
            <div className="home-sections section-carousel">
            {/* <h3>Nadchodzące wydarzenia</h3> */}
            <h3>Ciekawe eventy</h3>
            <div className="section-carousel-events">
            <div id="carouselExampleAutoplayingEvents" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
    
<div className="carousel-item active" data-bs-interval="10000">
<Link to={"/" + events[0].category + "/" + events[0].subcategory + "/profil_event/" + events[0]._id}>
  <img src={events[0].images[0].img} className="firstCarouselButton d-block w-100" alt="..."></img>
  <div className="carousel-caption d-md-block">
    <h5>{events[0].title} - {getEventDate(events[0])} godz: {getEventHours(events[0])}</h5>
    <h5>{events[0].address !== undefined && events[0].address !== "" ? events[0].address.split(",")[0] : ""}</h5>
  </div>
  </Link>
</div>


<div className="carousel-item" data-bs-interval="10000">
<Link to={"/" + events[1].category + "/" + events[1].subcategory + "/profil_event/" + events[1]._id}>
  <img src={events[1].images[0].img} className="secondCarouselButton d-block w-100" alt="..."></img>
  <div className="carousel-caption d-md-block">
    <h5>{events[1].title} - {getEventDate(events[1])} godz: {getEventHours(events[1])}</h5>
    <h5>{events[1].address !== undefined && events[1].address !== "" ? events[1].address.split(",")[0] : ""}</h5>
  </div>
  </Link>
</div>


<div className="carousel-item" data-bs-interval="10000">
<Link to={"/" + events[2].category + "/" + events[2].subcategory + "/profil_event/" + events[2]._id}>
  <img src={events[2].images[0].img} className="thirdCarouselButton d-block w-100" alt="..."></img>
  <div className="carousel-caption d-md-block">
    <h5>{events[2].title} - {getEventDate(events[2])} godz: {getEventHours(events[2])}</h5>
    <h5>{events[2].address !== undefined && events[2].address !== "" ? events[2].address.split(",")[0] : ""}</h5>
  </div>
  </Link>
</div>

</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplayingEvents" data-bs-slide="prev">
<span className="carousel-control-prev-icon" aria-hidden="true"></span>
<span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplayingEvents" data-bs-slide="next">
<span className="carousel-control-next-icon" aria-hidden="true"></span>
<span className="visually-hidden">Next</span>
</button>
</div>
</div>
        </div> :
        <div className="loader"></div>
        }

        {/*********************************** CIEKAWE MIEJSCA ***************************************/ }

        <hr className="section-hr"></hr>

            {showCarousel && places !== null ?
            <div className="home-sections section-carousel">
            <h3>Ciekawe miejsca</h3>
            <div className="section-carousel-places">
            <div id="carouselExampleAutoplaying" className="carousel slide carousel-fade" data-bs-ride="carousel">
{/* <div class="carousel-indicators">
<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
</div> */}
<div className="carousel-inner">
    
<div className="carousel-item active" data-bs-interval="10000">
{/* <a onClick={handleCarouselItemClick} href="#"> */}
<Link to={"/" + places[0].category + "/" + places[0].subcategory + "/profil/" + places[0]._id}>
  <img src={places[0].images[0].img} className="firstCarouselButton d-block w-100" alt="..."></img>
  <div className="carousel-caption d-md-block">
    <h5>{places[0].title}</h5>
    {/* <p>{places[0].description}</p> */}
  </div>
  {/* </a> */}
  </Link>
</div>


<div className="carousel-item" data-bs-interval="10000">
{/* <a onClick={handleCarouselItemClick} href="#"> */}
<Link to={"/" + places[1].category + "/" + places[1].subcategory + "/profil/" + places[1]._id}>
  <img src={places[1].images[0].img} className="secondCarouselButton d-block w-100" alt="..."></img>
  <div className="carousel-caption d-md-block">
    <h5>{places[1].title}</h5>
    {/* <p>Some representative placeholder content for the second slide.</p> */}
  </div>
  {/* </a> */}
  </Link>
</div>


<div className="carousel-item" data-bs-interval="10000">
{/* <a onClick={handleCarouselItemClick} href="#"> */}
<Link to={"/" + places[2].category + "/" + places[2].subcategory + "/profil/" + places[2]._id}>
  <img src={places[2].images[0].img} className="thirdCarouselButton d-block w-100" alt="..."></img>
  <div className="carousel-caption d-md-block">
    <h5>{places[2].title}</h5>
    {/* <p>Some representative placeholder content for the third slide.</p> */}
  </div>
  {/* </a> */}
  </Link>
</div>

</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
<span className="carousel-control-prev-icon" aria-hidden="true"></span>
<span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
<span className="carousel-control-next-icon" aria-hidden="true"></span>
<span className="visually-hidden">Next</span>
</button>
</div>
</div>
        </div> :
        <div className="loader"></div>
        }

        {/*********************************** UROCZYSTOŚCI ***************************************/ }

        <hr className="section-hr"></hr>

{celebrationsToShow.length > 0 ?
        <div className="home-sections section-events">
        {/* <h3>Nadchodzące wydarzenia</h3> */}
        {/* <h3>Nadchodzące uroczystości { getMonthName(new Date().getMonth()) } - { getMonthName(new Date().getMonth() + 1) } { new Date().getFullYear() }</h3> */}

        <h3>Nadchodzące uroczystości</h3>
        <div className="section-events-event">

        {/* {eventNumber === 0 ?
      <div>
        <UpcomingEvent event={currentEvents[0]}/>
        </div> :
        eventNumber === 1 ?
        <div>
          <UpcomingEvent event={currentEvents[1]}/>
          </div> :
          eventNumber === 2 ?
          <div>
            <UpcomingEvent event={currentEvents[2]}/>
            </div> :
            null
      } */}

              {eventNumber === 0 ?
      <div>
        <UpcomingEvent event={celebrationsToShow[0]}/>
        </div> :
        eventNumber === 1 ?
        <div>
          <UpcomingEvent event={celebrationsToShow[1]}/>
          </div> :
          eventNumber === 2 ?
          <div>
            <UpcomingEvent event={celebrationsToShow[2]}/>
            </div> :
            null
      }

      {/* {
        eventsToShow.map((event) =>{
          return(
            <div>
            <UpcomingEvent
            key={event.title}
            event={event}
            />
            </div>
          )
        })
      } */}

        </div>

        {/* <nav aria-label="Page navigation example"> */}
  <ul className="pagination justify-content-center">
    {/* <li class={"page-item " + eventDisabled.previous}> */}
    <li className={"page-item " + (eventNumber > 0 ? "" : "disabled")}>
      <a className="previousPagination page-link" onClick={handleEventDirectionClick} tabIndex="-1">Poprzednie</a>
    </li>
    <li className={"page-item " + (eventNumber === 0 ? "active" : "")}><a className="firstEventButton page-link" onClick={handleEventClick}>1</a></li>
    <li className={"page-item " + (eventNumber === 1 ? "active" : "")}><a className="secondEventButton page-link" onClick={handleEventClick}>2</a></li>
    <li className={"page-item " + (eventNumber === 2 ? "active" : "")}><a className="thirdEventButton page-link" onClick={handleEventClick}>3</a></li>
    {/* <li class={"page-item " + eventDisabled.next}> */}
    <li className={"page-item " + (eventNumber < (numberOfCelebrations - 1) ? "" : "disabled")}>
      <a className="nextPagination page-link" onClick={handleEventDirectionClick}>Następne</a>
    </li>
  </ul>
{/* </nav> */}
        </div> :
        null }

        {/*********************************** ARTYKUŁY ***************************************/ }

        <hr className="section-hr"></hr>

            <div className="home-sections section-articles">
            <h3>Najnowsze artykuły</h3>

            <div className="card-deck">
  <div className="card section-articles-card">
    <img className="card-img-top" src={currentArticles[currentArticles.length - 1].image} alt="Card image cap"></img>
    <div className="card-body">
      <h5 className="card-title">#{currentArticles[currentArticles.length - 1].id}: {currentArticles[currentArticles.length - 1].title}</h5>
      <p className="card-text">{currentArticles[currentArticles.length - 1].rawContent.substring(0, 100) + "..."}</p>
      {/* <a href="#" class="frist-articles-button btn btn-primary" onClick={handleeGetArticleClick}>czytaj dalej</a> */}
      <Link to={"/artykuly/" + currentArticles[currentArticles.length - 1].id} className="frist-articles-button btn btn-primary">czytaj dalej</Link>
    </div>
  </div>

  <div className="card section-articles-card">
    <img className="card-img-top" src={currentArticles[currentArticles.length - 2].image} alt="Card image cap"></img>
    <div className="card-body">
      <h5 className="card-title">#{currentArticles[currentArticles.length - 2].id}: {currentArticles[currentArticles.length - 2].title}</h5>
      <p className="card-text">{currentArticles[currentArticles.length - 2].rawContent.substring(0, 100) + "..."}</p>
      {/* <a href="#" class="second-articles-button btn btn-primary" onClick={handleeGetArticleClick}>czytaj dalej</a> */}
      <Link to={"/artykuly/" + currentArticles[currentArticles.length - 2].id} className="frist-articles-button btn btn-primary">czytaj dalej</Link>
    </div>
  </div>

  {/* <div className="card section-articles-card">
    <img className="card-img-top" src={currentArticles[2].image} alt="Card image cap"></img>
    <div className="card-body">
      <h5 className="card-title">{currentArticles[2].title}</h5>
      <p className="card-text">{currentArticles[2].content.substring(0, 100)}</p>
      <a href="#" class="btn btn-primary">czytaj dalej</a>
    </div>
  </div> */}

</div>

      <div className="section-articles-button">
      {/* <button className="btn btn-info" onClick={handleGetAllArticlesClick}>sprawdź wszystkie artykuły</button> */}
      <Link to={"/artykuly"} className="btn btn-outline-primary" >sprawdź wszystkie artykuły</Link>
      {/* <a href="#">sprawdź wszystkie artykuły</a> */}
      </div>

            </div>

        </div>
    );
};

export default Home;
