import React from "react";
import "./CategoryTitle.css";
import { Link } from "react-router-dom";

function CategoryTitle(props){

    function getCategoryString(category){
        let slicedWord = category.slice(category.length > 4 ? category.length - 4 : 0, category.length);
        if (slicedWord === "Inne" || slicedWord === "inne"){
            if (category.length > 5){
                return category.slice(0, category.length - 4) + " " + slicedWord;
            }
        }

        return category;
    }

    function getCategoryImage(category){
        let result = "/images/category/";
        switch (category) {
            case "Eventy":
                result = result + "kategoria-eventy.jpg";
                break;
            case "Muzea":
                result = result + "kategoria-muzea.jpg";
                break;
            case "Teatry":
                result = result + "kategoria-teatry.jpg";
                break;
            case "Kina":
                result = result + "kategoria-kina.jpg";
                break;
            case "Zabytki":
                result = result + "kategoria-zabytki.jpeg";
                break;
            case "KulturaInne":
                result = result + "kategoria-kultura-inne.jpg";
                break;
            case "Restauracje":
                result = result + "kategoria-restauracje.jpg";
                break;
            case "Fast Foody":
                result = result + "kategoria-fastFoody.jpg";
                break;
            case "Kawiarnie":
                result = result + "kategoria-kawiarnie.jpg";
                break;
            case "Desery":
                result = result + "kategoria-desery.jpg";
                break;
            case "GastronomiaInne":
                result = result + "kategoria-restauracje.jpg";
                break;
            case "Biblioteki":
                result = result + "kategoria-biblioteki.jpg";
                break;
            case "Księgarnie":
                result = result + "kategoria-ksiegarnie.jpg";
                break;
            case "Kursy":
                result = result + "kategoria-kursy.jpg";
                break;
            case "Targi i kiermasze":
                result = result + "kategoria-targi.jpg";
                break;
            case "NaukaInne":
                result = result + "kategoria-nauka-inne.jpg";
                break;
            case "Artykuły":
                result = result + "category-article.jpg";
                break;
            case "O nas":
                result = "/images/o-nas.jpg";
                break;
            default:
                result = result + "kategoria-kultura-inne.jpg";
                break;
        }

        return result;
    }

    function getCategoryDescription(category){
        let result = "";
        switch (category) {
            case "Eventy":
                result = "to różnorodne wydarzenia organizowane w celu celebrowania, promowania lub uczczenia określonego tematu, jubileuszu";
                break;
            case "Muzea":
                result = "odgrywają kluczową rolę w życiu społecznym, kulturalnym i edukacyjnym, przyczyniając się do ochrony dziedzictwa";
                break;
            case "Teatry":
                result = "są miejscami magicznymi, gdzie świat rzeczywistości splata się z fikcją, a emocje oraz historie ożywają na scenie";
                break;
            case "Kina":
                result = "są miejscami, gdzie świat filmowy ożywa na wielkim ekranie, przynosząc widzom niezapomniane doświadczenia";
                break;
            case "Zabytki":
                result = "są świadkami przeszłości, które przetrwały przez wieki, pozostawiając ślad swojej historii i kultury";
                break;
            case "KulturaInne":
                result = "Kultura stanowi integralną część ludzkiego życia, definiując tożsamość społeczeństwa, grupy czy narodu";
                break;
            case "Restauracje":
                result = "są nie tylko miejscami, gdzie można zaspokoić głód, ale także przestrzeniami, gdzie odbywają się doświadczenia kulinarno-kulturowe";
                break;
            case "Fast Foody":
                result = "są popularnymi miejscami, gdzie można szybko i łatwo zaspokoić głód, wybierając spośród szerokiej gamy przekąsek";
                break;
            case "Kawiarnie":
                result = "to urocze miejsca, gdzie można delektować się aromatyczną kawą, pysznymi deserami i relaksującą atmosferą";
                break;
            case "Desery":
                result = "to miejsca, gdzie można zanurzyć się w świat słodyczy i cieszyć się wykwintnymi wypiekami oraz deserami";
                break;
            case "GastronomiaInne":
                result = "Gastronomia to sztuka przygotowywania i konsumowania jedzenia, która łączy w sobie kulinarną wiedzę, umiejętności kucharzy";
                break;
            case "Biblioteki":
                result = "są oazą wiedzy, kultury i nauki, stanowiącymi istotną część społeczności lokalnej i globalnej";
                break;
            case "Księgarnie":
                result = "to miejsca, gdzie miłośnicy literatury mogą odkrywać, przeglądać i nabywać książki z różnorodnych dziedzin";
                break;
            case "Kursy":
                result = "to strukturalne programy edukacyjne, które oferują uczestnikom możliwość pogłębienia wiedzy, zdobycia nowych umiejętności";
                break;
            case "Targi i kiermasze":
                result = "to wydarzenia, podczas których producenci, sprzedawcy i konsumenci spotykają się w celu prezentacji, sprzedaży";
                break;
            case "NaukaInne":
                result = "Nauka to proces badawczy, eksploracyjny i poznawczy, który ma na celu zgłębianie tajemnic świata fizycznego, biologicznego";
                break;
            default:
                result = "";
                break;
        }
        return (
            <div className="category-description-text">
                <p>
                { result }... <Link to={"/informacje/" + props.category}>czytaj dalej</Link>
                </p>
                
            </div>
        )
    }

    return (
        <div className="category-title">
            {/* <h1>{getCategoryString(props.category)}</h1> */}
            <div>
                <img src={ getCategoryImage(props.category) }></img>
            </div>

            <div className="category-description">
                <h1>{getCategoryString(props.category)}</h1>
                { props.categoryInfo !== undefined ? "" : getCategoryDescription(props.category) }
            </div>
        </div>
    )
};

export default CategoryTitle;