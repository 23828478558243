import React, { useEffect, useState } from "react";
import Article from "./Article";
import "./Articles.css";
import currentArticles from "./currentArticles";
import Pagination from "../Pagination";

import { Link } from "react-router-dom";
import CategoryTitle from "../CategoryTitle";

function Articles(props){

    // function handleBack(){
    //     props.setCurrentArticle({});
    // }
    const [resultsOnPage, setResultsOnPage] = useState(4);
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "instant"
      })
      }, []);

    return(
        <div className="articles">
            {/* <h1>Artykuły</h1> */}
            <CategoryTitle category = { "Artykuły" } categoryInfo = { "" } />

            <Pagination resultsOnPage={resultsOnPage} pageNumber={pageNumber} setPageNumber={setPageNumber} collection={currentArticles} />
            <div>
            <div className="card-deck aricles-card-deck">
                        {/* {currentArticles.map(article =>{ */}
                {currentArticles.slice((0 + (pageNumber * resultsOnPage)), ((pageNumber * resultsOnPage) + resultsOnPage)).map((article, index) =>{
                        return(
        //                     <div key={index} className="article single-article-article">
        //                     <div>
        //                         <img className="" src={article.image} alt={"zdjęcie artykułu numer " + index}></img>
        //                     </div>
        //                     <div>
        //                             <h3>{article.title}</h3>
        //                     </div>

        //     <div>
        //         <p>
        //         {article.content.substring(0, 200) + "..."}
        //         </p>
        //         <p className="article-author">autor: {article.author}</p>
        //     </div>
        //     <div>
        //         <Link to={"/artykuly/" + article.id} className="btn btn-primary">czytaj dalej</Link>
        //     </div>
        // </div>
        <div className="card" key={index}>
        <img className="card-img-top" src={article.image} alt="Card image cap"></img>
        <div className="card-body">
          {/* <h5 className="card-title">#{article.id}: {article.title}</h5> */}
          <h5 className="card-title">{article.title}</h5>
          <p className="card-text">{article.rawContent.substring(0, 200) + "..."}</p>
          {/* <a href="#" class="frist-articles-button btn btn-primary" onClick={handleeGetArticleClick}>czytaj dalej</a> */}
          <Link to={"/artykuly/" + article.id} className="frist-articles-button btn btn-primary">czytaj dalej</Link>
        </div>
      </div>
                            )
                        })}
                        </div>
        </div> 


                    <Pagination resultsOnPage={resultsOnPage} pageNumber={pageNumber} setPageNumber={setPageNumber} collection={currentArticles} />

        </div>
    );
};

export default Articles;