import React, { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./CategoryTitle.css";
import CategoryTitle from "./CategoryTitle";
import { BackLink } from "./App";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import "./Profile.css";
import Crunches from "./Crunches";

function CategoryInformation(props){

    const { category } = useParams();
    const navigate = useNavigate();

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, []);

    function getCategoryString(category){
        let slicedWord = category.slice(category.length > 4 ? category.length - 4 : 0, category.length)
        if (slicedWord === "Inne" || slicedWord === "inne"){
            if (category.length > 5){
                return category.slice(0, category.length - 4) + " " + slicedWord;
            }
        }

        return category;
    }

    function getCategoryDescription(category){
        switch (category) {
            case "Eventy":
                return (
                    <div>
                        <p>
                        Eventy to różnorodne wydarzenia organizowane w celu celebrowania, promowania lub uczczenia określonego tematu, jubileuszu, osiągnięcia lub tradycji. Mogą to być imprezy o charakterze kulturalnym, artystycznym, sportowym, biznesowym lub społecznym, które przyciągają uczestników z różnych środowisk i zainteresowań.
                        </p>
                        <p>
                        Istnieje wiele rodzajów eventów, w tym:
                        </p>
                        <ul>
                        <li>Koncerty i festiwale muzyczne: Imprezy muzyczne, które prezentują różnorodne gatunki muzyczne, od rocka i popu po muzykę klasyczną czy jazz. Festiwale mogą być jednodniowe lub wielodniowe, z udziałem znanych artystów i zespołów.</li>
        
                        <li>Imprezy kulturalne: Wystawy sztuki, przedstawienia teatralne, pokazy filmowe, czy warsztaty artystyczne, które promują kulturę i sztukę w różnych formach.</li>

                        <li>Imprezy sportowe: Mecze, zawody, maratony, turnieje, które skupiają się na różnych dyscyplinach sportowych i przyciągają zarówno zawodników, jak i kibiców.</li>

                        <li>Imprezy biznesowe: Konferencje, targi, seminaria, spotkania networkingowe, które służą wymianie wiedzy, doświadczeń i nawiązywaniu relacji biznesowych.</li>

                        <li>Imprezy społeczne: Wesela, przyjęcia, bankiety, urodziny, które mają na celu celebrowanie ważnych okazji życiowych w towarzystwie rodziny i przyjaciół.</li>
                        
                        <li>Wydarzenia charytatywne: Aukcje, koncerty charytatywne, biegi czy inne akcje mające na celu zbieranie funduszy na cele dobroczynne lub pomoc potrzebującym.</li>
                        </ul>
                        <p>
                        Organizacja eventów wymaga starannej planowania, koordynacji oraz współpracy z różnymi podmiotami, takimi jak dostawcy usług, sponsorki, media oraz instytucje publiczne. Kluczowymi elementami udanego eventu są także dbałość o bezpieczeństwo uczestników, odpowiednia promocja i marketing oraz zrównoważony rozwój, uwzględniający wpływ wydarzenia na środowisko naturalne i społeczność lokalną.
                        </p>
                        <p>
                        Eventy stanowią więc ważną część życia społecznego i kulturalnego, tworząc platformę do interakcji społecznej, promocji kultury i sztuki, rozwoju biznesu oraz wspierania innowacji i rozwoju społecznego.
                        </p>
                    </div>
                )
            case "Muzea":
                return (
                    <div>
                        <p>
                        Muzea odgrywają kluczową rolę w życiu społecznym, kulturalnym i edukacyjnym, przyczyniając się do ochrony 
                        dziedzictwa, promowania sztuki i kultury oraz edukowania społeczeństwa na temat różnorodnych aspektów 
                        ludzkiej historii i osiągnięć.
                        </p>
                        <ul>
                        <li>Wartość edukacyjna: Muzea są nie tylko miejscami przechowywania artefaktów, ale także ważnymi ośrodkami edukacyjnymi. Zapewniają one interaktywne doświadczenia, które pozwalają odwiedzającym na zgłębianie wiedzy na temat sztuki, historii, nauki i kultury. Poprzez wystawy, warsztaty, wykłady i inne działania edukacyjne muzea przyczyniają się do rozwoju intelektualnego i społecznego społeczności.</li>
        
                        <li>Ochrona dziedzictwa kulturowego: Muzea odgrywają kluczową rolę w ochronie dziedzictwa kulturowego, chroniąc i konserwując cenne zbiory historyczne, artystyczne i archeologiczne. Dzięki pracy kuratorów, konserwatorów i badaczy, muzea zapewniają długotrwałą ochronę dla artefaktów, które stanowią ważną część dziedzictwa narodowego i światowego.</li>
        
                        <li>Promocja sztuki i kultury: Muzea pełnią istotną rolę w promowaniu sztuki i kultury, prezentując dzieła sztuki różnych epok i kultur. Wystawy czasowe i stałe umożliwiają publiczne eksponowanie dzieł sztuki, co może przyczynić się do zwiększenia świadomości i zrozumienia sztuki przez społeczeństwo.</li>
        
                        <li>Turystyka i gospodarka: Muzea stanowią ważny element atrakcyjności turystycznej dla wielu regionów. Odwiedzający często podróżują, aby zobaczyć słynne muzea, co przynosi korzyści gospodarcze dla miejscowości, w której się znajdują. Poprzez turystykę kulturową muzea przyczyniają się do generowania przychodów, tworzenia miejsc pracy i wspierania lokalnych przedsiębiorstw.</li>
        
                        <li>Innowacje w prezentacji: W dzisiejszych czasach muzea wykorzystują nowoczesne technologie, takie jak wirtualna rzeczywistość, interaktywne ekspozycje i multimedia, aby zapewnić bardziej angażujące i interaktywne doświadczenia dla odwiedzających. Stale poszukują nowych sposobów prezentacji zbiorów, aby przyciągnąć nowe publiczności i zainteresować młodsze pokolenie.</li>
        
                        <li>Wykorzystanie społeczne: Muzea nie tylko prezentują zbiory, ale także działają na rzecz społeczności lokalnych poprzez organizację wydarzeń społecznych, warsztatów dla dzieci, kursów dla dorosłych i innych aktywności. Dzięki temu integrują się z lokalną społecznością i stają się miejscem spotkań i wymiany kulturalnej.</li>
                        </ul>
                    </div>
                )
            case "Teatry":
                return (
                    <div>
                        <p>
                        Teatry są miejscami magicznymi, gdzie świat rzeczywistości splata się z fikcją, a emocje oraz historie ożywają na scenie. To instytucje kulturalne, które od wieków pełnią istotną rolę w społeczeństwie, inspirując, edukując i bawiąc publiczność.
                        </p>
                        <p>
                        Oto kilka charakterystycznych cech teatrów:
                        </p>
                        <ul>
                        <li>Scena artystyczna: Teatry to miejsca, gdzie sztuka wizualna i dźwiękowa spotyka się w wyjątkowym spektaklu. Sceny teatralne są przestrzeniami, gdzie aktorzy ożywiają postacie z różnych epok i kultur, przekazując widzom różnorodne emocje i przesłania.</li>

                        <li>Różnorodność gatunków: Teatry prezentują różnorodne gatunki sztuki scenicznej, obejmujące dramat, komedię, musical, operę, balet i wiele innych. To miejsca, gdzie każdy może znaleźć coś dla siebie, od głębokich, filozoficznych refleksji po lekką, rozrywkową komedię.</li>

                        <li>Platforma dla talentów: Teatry są przestrzenią, gdzie utalentowani aktorzy, reżyserzy, scenografowie, kompozytorzy i inne osoby związane z przemysłem teatralnym mogą prezentować swoje umiejętności i kreatywność. To miejsca, gdzie nowe talenty mają szansę się wybić, a doświadczeni artyści mogą kontynuować swoją twórczą podróż.</li>

                        <li>Edukacja i refleksja: Teatry nie tylko bawią, ale również edukują i inspirują. Przez prezentację różnorodnych historii i tematów, teatry mogą pobudzać wyobraźnię, wspierać dyskusje społeczne i promować zrozumienie różnorodności kulturowej i społecznej.</li>

                        <li>Wspólnota i interakcja: Teatry tworzą wspólnotę, zarówno między artystami a publicznością, jak i między samymi widzami. Dzięki wspólnemu doświadczeniu oglądania spektaklu, widzowie mogą nawiązywać relacje, dzielić się emocjami i poglądami.</li>

                        <li>Kreatywna przestrzeń: Teatry to miejsca, gdzie kreatywność kwitnie. Scenografowie tworzą magiczne światy, kostiumiści ożywiają postacie, a reżyserzy prowadzą aktorów przez złożone emocjonalnie podróże. To przestrzeń, gdzie wyobraźnia nie ma granic.</li>
                        </ul>
                        <p>
                        Teatry są niezastąpionymi instytucjami kulturalnymi, które od wieków przyciągają publiczność swoim urokiem, emocjami i inspiracją. To miejsca, gdzie sztuka staje się żywa, a widzowie mogą na chwilę oderwać się od codzienności i zanurzyć w magicznym świcie teatru.
                        </p>
                    </div>
                )
            case "Kina":
                return (
                    <div>
                        <p>
                        Kina są miejscami, gdzie świat filmowy ożywa na wielkim ekranie, przynosząc widzom niezapomniane doświadczenia wizualne i emocjonalne. To instytucje kulturalne, które od dziesięcioleci pełnią kluczową rolę w społeczeństwie, dostarczając rozrywki, edukacji i inspiracji.
                        </p>
                        <p>
                        Oto kilka charakterystycznych cech kin:
                        </p>
                       <ul>
                       <li>Ekranizacja filmowa: Kina są przestrzeniami, gdzie filmy stają się żywe, a obraz, dźwięk i historia łączą się w jedno, tworząc niepowtarzalne doświadczenie dla widzów. Wielki ekran i wysokiej jakości dźwięk zapewniają immersyjne przeżycia, które przenoszą widzów w światy fantastyczne, historyczne, przygodowe i wiele innych.</li>

                       <li>Różnorodność filmów: Kina prezentują różnorodne gatunki filmowe, obejmujące dramat, komedię, thriller, science fiction, animację, dokument oraz wiele innych. To miejsca, gdzie każdy może znaleźć coś dla siebie, niezależnie od gustu czy wieku.</li>

                       <li>Platforma dla twórców: Kina są przestrzenią, gdzie twórcy filmowi mają okazję prezentować swoje dzieła sztuki publiczności. To miejsca, gdzie filmy niezależne, eksperymentalne czy ambitne projekty mogą znaleźć swoją widownię i zyskać uznanie.</li>

                       <li>Wspólne doświadczenie: Kina tworzą atmosferę wspólnego doświadczenia, gdzie widzowie z różnych środowisk i kultur mogą spotkać się i podzielić emocjami podczas oglądania filmów. To miejsca, gdzie widownia może śmiać się, płakać, podziwiać i reflektować razem.</li>

                       <li>Edukacja i refleksja: Filmy prezentowane w kinach mogą nie tylko bawić, ale również edukować i inspirować. Przez różnorodność tematów, historii i przesłań, filmy mogą pobudzać wyobraźnię, zwiększać świadomość społeczną i promować zrozumienie różnorodności ludzkiej.</li>

                       <li>Rozwój technologiczny: Kina wykorzystują najnowsze technologie w celu zapewnienia widzom najlepszego możliwego doświadczenia oglądania filmów. Od cyfrowych projektorów po systemy dźwiękowe Dolby Atmos, kina stale inwestują w nowoczesne rozwiązania technologiczne, aby zapewnić najwyższą jakość obrazu i dźwięku.</li>
                       </ul>
                        <p>
                        Kina są nieodłączną częścią kultury masowej, dostarczając widzom rozrywki, edukacji i inspiracji. To miejsca, gdzie sztuka filmowa staje się żywa, a widzowie mogą na chwilę oderwać się od rzeczywistości i zanurzyć w magicznych światach kina.
                        </p>
                    </div>
                )
            case "Zabytki":
                return (
                    <div>
                        <p>
                        Zabytki są świadkami przeszłości, które przetrwały przez wieki, pozostawiając ślad swojej historii i kultury. To nie tylko obiekty architektoniczne, ale również symbole i pamiątki przeszłości, które odzwierciedlają bogactwo dziedzictwa kulturowego.
                        </p>
                        <p>
                        Oto kilka charakterystycznych cech zabytków:
                        </p>
                        <ul>
                        <li>Historyczne znaczenie: Zabytki są nieodłączną częścią historii narodowej i lokalnej społeczności. Mogą to być ruiny starożytnych budowli, średniowieczne zamki, renesansowe pałace, barokowe kościoły, czy też budynki związane z ważnymi wydarzeniami historycznymi.</li>

                        <li>Archiwalne piękno: Zabytki często charakteryzują się unikalną i imponującą architekturą oraz detalem wykonania. Ich piękno przyciąga uwagę i zachwyca odwiedzających, stanowiąc źródło inspiracji dla artystów, architektów i miłośników historii.</li>

                        <li>Edukacyjna wartość: Zabytki są ważnymi źródłami edukacji, umożliwiającymi zrozumienie przeszłości i jej wpływu na teraźniejszość. Mogą to być muzea, które prezentują zbiory historyczne, czy też otwarte dla zwiedzających zabytki, które pozwalają na bezpośrednie doświadczenie historii i kultury.</li>

                        <li>Turystyczna atrakcja: Zabytki przyciągają turystów z całego świata, stanowiąc ważną atrakcję turystyczną dla regionów i miast. Ich odwiedzanie pozwala turystom na poznanie lokalnej historii, kultury i tradycji, oraz na czerpanie przyjemności z estetyki i atmosfery zabytkowych miejsc.</li>

                        <li>Ochrona dziedzictwa: Zabytki wymagają specjalnej ochrony i konserwacji, aby zachować ich wartość historyczną i kulturową dla przyszłych pokoleń. Organizacje takie jak UNESCO nadzorują wpisanie najcenniejszych zabytków na Listę Światowego Dziedzictwa, co zwiększa ich międzynarodowy status ochrony.</li>
                        </ul>
                        <p>
                        Zabytki stanowią nie tylko obiekty materialne, ale również ważne dziedzictwo kulturowe, które łączy przeszłość z teraźniejszością i inspirowało kolejne pokolenia. Są one symbolem tożsamości narodowej i lokalnej, oraz źródłem wiedzy, piękna i dumy dla społeczności, które je otaczają.
                        </p>
                    </div>
                )
            case "KulturaInne":
                return (
                    <div>
                        <p>
                        Kultura stanowi integralną część ludzkiego życia, definiując tożsamość społeczeństwa, grupy czy narodu oraz wpływając na sposób myślenia, działania i oddziaływania na świat. Jest nieustannie kształtowana przez ludzi i ich interakcje, tworząc niezwykłą mozaikę ludzkiego doświadczenia.
                        </p>
                        <p>
                        Oto kilka kluczowych aspektów kultury:
                        </p>
                        <ul>
                        <li>Tradycje i zwyczaje: Kultura obejmuje tradycje, zwyczaje i obrzędy przekazywane z pokolenia na pokolenie. Mogą to być ceremonie religijne, obchody świąt, festiwale, rytuały życiowe czy też lokalne obyczaje charakterystyczne dla danej społeczności.</li>

                        <li>Sztuka i wytwórczość: Sztuka jest integralną częścią kultury, wyrażającą się poprzez malarstwo, rzeźbę, muzykę, teatr, taniec, literaturę, film i wiele innych form. Jest to sposób wyrażania emocji, przekazywania idei i refleksji nad ludzkim doświadczeniem.</li>

                        <li>Język i komunikacja: Język jest kluczowym elementem kultury, umożliwiającym komunikację, przekazywanie wiedzy i wartości oraz budowanie więzi społecznych. Zróżnicowanie języków i dialektów odzwierciedla różnorodność kulturową świata.</li>

                        <li>Religia i duchowość: Wiele aspektów kultury jest silnie związanych z religią i duchowością. Wyznawane wierzenia, praktyki religijne i ceremonie mają istotny wpływ na życie społeczne, moralność, obyczaje i sztukę.</li>

                        <li>Technologia i postęp: Kultura nieustannie ewoluuje wraz z postępem technologicznym i zmianami społecznymi. Nowe technologie wpływają na sposób, w jaki ludzie komunikują się, pracują, bawią się i tworzą sztukę.</li>

                        <li>Wartości i normy społeczne: Kultura określa wartości i normy społeczne, które kształtują zachowania jednostek i grup społecznych. Mogą to być wartości takie jak szacunek, uczciwość, tolerancja, czy też normy dotyczące rodziny, pracy, czy relacji międzyludzkich.</li>
                        </ul>
                        <p>
                        Kultura to złożony i wielowymiarowy zbiór wzorców zachowań, przekonań, wartości, tradycji, sztuki i symboli, które definiują społeczeństwo, grupę lub naród. Jest to niezmiernie bogaty i różnorodny obszar ludzkiej działalności, który odzwierciedla sposób życia, myślenia i działania ludzi w różnych czasach i miejscach.
                        </p>
                    </div>
                )
            case "Restauracje":
                return (
                    <div>
                        <p>
                        Restauracje są nie tylko miejscami, gdzie można zaspokoić głód, ale także przestrzeniami, gdzie odbywają się doświadczenia kulinarno-kulturowe, spotkania społeczne i celebrowanie kulinarnych talentów kucharzy.
                        </p>
                        <p>
                        Oto kilka charakterystycznych cech restauracji:
                        </p>
                        <ul>
                        <li>Kulinarna rozrywka: Restauracje to miejsca, gdzie można doświadczyć różnorodnych smaków, aromatów i tekstur, które tworzą niezapomniane doznania kulinarne. Menu restauracji może obejmować potrawy kuchni lokalnej, regionalnej, krajowej lub międzynarodowej, prezentując różnorodność kulinarną.</li>

                        <li>Atmosfera i design: Restauracje często starają się stworzyć wyjątkową atmosferę i estetykę, która odpowiada ich koncepcji i stylowi. Mogą to być eleganckie restauracje fine dining, przytulne kawiarnie, stylowe bistro czy też tradycyjne knajpki, każda z nich oferująca unikalną atmosferę i doświadczenie.</li>

                        <li>Obsługa i gościnność: Obsługa w restauracjach odgrywa kluczową rolę w tworzeniu pozytywnego doświadczenia dla gości. Profesjonalni kelnerzy i kucharze dbają o to, aby potrawy były świeże, smaczne i pięknie podane, a goście czuli się komfortowo i mile widziani.</li>

                        <li>Innowacje kulinarne: Restauracje często eksperymentują z nowymi technikami kulinarnymi, składnikami i prezentacją potraw, aby zaskakiwać i zachwycać gości. Kucharze mogą inspirować się tradycyjnymi przepisami, a jednocześnie wprowadzać nowoczesne elementy i smaki, tworząc unikalne doświadczenia kulinarne.</li>

                        <li>Miejsce spotkań i celebracji: Restauracje są popularnymi miejscami na randki, urodziny, spotkania biznesowe czy też rodzinne obiady. To miejsca, gdzie ludzie zbierają się, aby świętować, rozmawiać i cieszyć się ze sobą, tworząc niezapomniane wspomnienia.</li>

                        <li>Zrównoważona gastronomia: Coraz więcej restauracji kładzie nacisk na zrównoważoną gastronomię, oferując potrawy przygotowane ze świeżych, lokalnych składników, dbając o środowisko naturalne i dobrostan zwierząt.</li>
                        </ul>
                        <p>
                        Restauracje są nie tylko miejscami, gdzie można delektować się wyśmienitymi potrawami, ale także przestrzeniami, gdzie odbywają się spotkania, celebracje i doświadczenia kulinarno-kulturowe. Oferują one nie tylko jedzenie, ale także atmosferę, gościnność i emocje, tworząc wyjątkowe doświadczenia dla gości.
                        </p>
                    </div>
                )
            case "Fast Foody":
                return (
                    <div>
                        <p>
                        Fast foody są popularnymi miejscami, gdzie można szybko i łatwo zaspokoić głód, wybierając spośród szerokiej gamy przekąsek i dań szybkiego jedzenia. Charakteryzują się prostotą, szybkością obsługi i przystępnymi cenami.
                        </p>
                        <p>
                        Oto kilka cech charakterystycznych dla fast foodów:
                        </p>
                        <ul>
                        <li>Szybkość obsługi: Fast foody słyną z szybkiej obsługi, umożliwiając gościom zamówienie i otrzymanie posiłku w krótkim czasie. Proste menu, gotowe składniki i efektywna organizacja pracy pozwalają na błyskawiczne przygotowanie potraw.</li>

                        <li>Proste menu: Menu w fast foodach zazwyczaj jest proste i łatwe do zrozumienia, zawierając podstawowe przekąski, burgery, frytki, napoje i inne popularne dania. Są to często standardowe produkty, które są łatwe do przygotowania i serwowania.</li>

                        <li>Dostępność: Fast foody są często obecne w centrach handlowych, na stacjach benzynowych, przy autostradach i w innych strategicznych lokalizacjach, zapewniając łatwy dostęp dla klientów.</li>

                        <li>Przystępne ceny: Ceny w fast foodach zazwyczaj są stosunkowo niskie, co czyni je atrakcyjnymi dla osób poszukujących szybkiego i niedrogiego posiłku. Promocje, zestawy i kupony rabatowe często dodatkowo zachęcają do odwiedzenia fast foodów.</li>

                        <li>Jednolity standard: Fast foody często stosują jednolite standardy jakości i obsługi, co sprawia, że ​​doświadczenie klienta jest podobne w różnych lokalizacjach i restauracjach tej samej sieci.</li>

                        <li>Dostępność przez całą dobę: Niektóre fast foody są otwarte przez całą dobę, co czyni je dogodnym miejscem na szybki posiłek o każdej porze dnia i nocy.</li>
                        </ul>
                        <p>
                        Fast foody są popularnymi miejscami dla osób poszukujących szybkiego, łatwego i przystępnego cenowo posiłku. Pomimo krytyki związanej z ich wpływem na zdrowie publiczne i środowisko, fast foody pozostają istotną częścią krajobrazu gastronomicznego, przyciągając szerokie grono klientów z całego świata.
                        </p>
                    </div>
                )
            case "Kawiarnie":
                return (
                    <div>
                        <p>
                        Kawiarnie to urocze miejsca, gdzie można delektować się aromatyczną kawą, pysznymi deserami i relaksującą atmosferą. Są to miejsca, które zachęcają do spokojnych rozmów, refleksji, czytania książek, pracy zdalnej czy też po prostu obserwowania życia ulicznego.
                        </p>
                        <p>
                        Oto kilka cech charakterystycznych dla kawiarni:
                        </p>
                        <ul>
                        <li>Klimatyczna atmosfera: Kawiarnie często charakteryzują się przytulną i relaksującą atmosferą, która sprawia, że ​​goście czują się jak w domu. Miękkie fotele, ciepłe oświetlenie, delikatna muzyka w tle i zapach świeżo parzonej kawy tworzą idealne środowisko do odpoczynku i relaksu.</li>

                        <li>Wielokulturowe menu: Kawiarnie oferują szeroki wybór napojów, od tradycyjnej kawy po herbaty, koktajle, smoothie i inne napoje. Dodatkowo, często serwują pyszne desery, ciasta, croissanty, bagietki i inne przekąski, które doskonale komponują się z aromatycznym napojem.</li>

                        <li>Miejsce spotkań: Kawiarnie są popularnymi miejscami na spotkania towarzyskie, randki, czy rozmowy biznesowe. Ich atmosfera sprzyja luźnej rozmowie i wymianie poglądów, co czyni je idealnymi miejscami do spotkań zarówno ze znajomymi, jak i z klientami czy współpracownikami.</li>

                        <li>Przestrzeń do pracy i nauki: Dla wielu osób kawiarnie są idealnymi miejscami do pracy zdalnej, nauki czy czytania książek. W spokojnej atmosferze kawiarni można skupić się na zadaniach, unikając rozproszeń domowych czy biurowych.</li>

                        <li>Estetyczne wnętrza: Kawiarnie często dbają o estetykę swojego wnętrza, tworząc wyjątkowe i stylowe przestrzenie, które zachęcają do odwiedzenia i spędzenia czasu. Dekoracje, meble, rośliny i sztuka są starannie dobrane, aby stworzyć przyjemne wrażenie estetyczne.</li>

                        <li>Ekspresowa obsługa: Pomimo spokojnej atmosfery, kawiarnie zazwyczaj zapewniają szybką i sprawna obsługę, dzięki czemu goście mogą cieszyć się swoim ulubionym napojem i przekąską bez długiego oczekiwania.</li>
                        </ul>
                        <p>
                        Kawiarnie są nie tylko miejscami do picia kawy, ale także przestrzeniami do odpoczynku, relaksu, spotkań i pracy. Ich klimatyczna atmosfera, różnorodne menu i estetyczne wnętrza przyciągają szerokie grono klientów, tworząc wyjątkowe doświadczenia gastronomiczne i społeczne.
                        </p>
                    </div>
                )
            case "Desery":
                return (
                    <div>
                        <p>
                        Cukiernie to miejsca, gdzie można zanurzyć się w świat słodyczy i cieszyć się wykwintnymi wypiekami oraz deserami. Charakteryzują się one nie tylko bogatym wyborem pysznych smakołyków, ale także estetycznym wystrojem i przyjazną atmosferą.
                        </p>
                        <p>
                        Istnieje wiele rodzajów eventów, w tym:
                        </p>
                        <ul>
                        <li>Bogate menu słodkości: Cukiernie oferują szeroki wybór deserów, wypieków, ciast, ciasteczek, tortów, babeczek i innych pysznych smakołyków. Mogą to być tradycyjne przepisy rodzinne, jak również nowoczesne kompozycje kulinarnych eksperymentów.</li>

                        <li>Estetyczny wystój: Wnętrza cukierni są często urządzone w delikatnych pastelowych kolorach, z subtelnymi dekoracjami i eleganckim wyposażeniem. Stół z kolorowymi wypiekami i ciastami, kawiarniane krzesła i wyeksponowane witryny z kuszącymi słodkościami tworzą przyjemną atmosferę.</li>

                        <li>Słodkie doznania: Cukiernie to miejsca, gdzie można delektować się pysznymi smakami, które rozpieszczają podniebienie i wywołują uczucie przyjemności. Wiele cukierni stawia również na jakość składników i świeżość wypieków, co dodatkowo podnosi atrakcyjność oferty.</li>

                        <li>Miejsce spotkań i celebracji: Cukiernie są idealnymi miejscami na spotkania towarzyskie, urodziny, baby showery, czy inne uroczystości. Mogą one zorganizować wyjątkowe torty na zamówienie, dostosowane do indywidualnych preferencji i tematyki imprezy.</li>

                        <li>Kawiarniana atmosfera: Wiele cukierni oferuje także napoje, takie jak kawa, herbata, gorąca czekolada czy lemoniada, które doskonale komponują się z podawanymi słodkościami. To idealne miejsce na relaksującą przerwę od codziennych obowiązków.</li>

                        <li>Prezentacja wypieków: W witrynach cukierni często można podziwiać misternie udekorowane torty i ciasta, które są prawdziwymi dziełami sztuki cukierniczej. Kolorowe lukry, czekoladowe ozdoby i fantazyjne kształty zachęcają do zakupu i degustacji.</li>
                        </ul>
                        <p>
                        Cukiernie to miejsca, gdzie można zanurzyć się w świat słodyczy i cieszyć się niezapomnianymi smakami i wizualnymi doznaniami. Ich estetyczny wystój, bogate menu i przyjazna atmosfera przyciągają miłośników słodkości z całego świata, tworząc wyjątkowe doświadczenia kulinarno-kulturalne.
                        </p>
                    </div>
                )
            case "GastronomiaInne":
                return (
                    <div>
                        <p>
                        Gastronomia to sztuka przygotowywania i konsumowania jedzenia, która łączy w sobie kulinarną wiedzę, umiejętności kucharzy, różnorodność smaków i tradycje kulinarne różnych regionów i kultur. Jest to obszar, który odzwierciedla bogactwo i różnorodność kuchni świata oraz odgrywa istotną rolę w życiu społecznym, kulturalnym i ekonomicznym społeczeństw.
                        </p>
                        <p>
                        Oto kilka cech charakterystycznych dla gastronomii:
                        </p>
                        <ul>
                        <li>Różnorodność kulinarna: Gastronomia obejmuje szeroki zakres kuchni, smaków, technik gotowania i tradycji kulinarnej, które różnią się w zależności od regionu, kraju czy nawet miasta. Mogą to być potrawy kuchni włoskiej, francuskiej, chińskiej, meksykańskiej, indyjskiej i wielu innych, każda z nich oferująca unikalne smaki i doznania kulinarno-kulturowe.</li>

                        <li>Sztuka gotowania: Gastronomia to także sztuka gotowania, która wymaga pasji, kreatywności, precyzji i wiedzy kulinarnej. Doświadczeni kucharze potrafią tworzyć wykwintne dania, wykorzystując różnorodność składników, technik gotowania i przypraw, aby zaskakiwać i zachwycać smakiem.</li>

                        <li>Edukacja i badania: Gastronomia angażuje się w badania naukowe dotyczące żywności, żywienia, zdrowego odżywiania, technologii kulinarnych i innych zagadnień związanych z jedzeniem. Jest to obszar, który stale się rozwija i ewoluuje, poszukując nowych smaków, technik i trendów w świecie kulinariów.</li>

                        <li>Rola społeczna: Gastronomia odgrywa istotną rolę w życiu społecznym, łącząc ludzi wokół stołu, tworząc relacje międzyludzkie i promując wspólne doznania kulinarne. Restauracje, kawiarnie, cukiernie i inne lokale gastronomiczne są miejscami, gdzie odbywają się spotkania towarzyskie, rodzinne obiady, randki czy uroczystości.</li>

                        <li>Turystyka kulinarna: Gastronomia jest również ważnym czynnikiem w turystyce, przyciągając podróżnych z całego świata do odkrywania nowych smaków i kulinarnych tradycji różnych kultur i regionów. Kulinarne festiwale, degustacje, warsztaty kulinarnych i wycieczki smakosza są popularnymi atrakcjami turystycznymi dla miłośników jedzenia.</li>

                        <li>Zrównoważona gastronomia: Współcześnie coraz większy nacisk kładzie się na zrównoważoną gastronomię, która uwzględnia nie tylko walory smakowe, ale także aspekty ekologiczne, społeczne i zdrowotne. Kucharze i restauratorzy coraz częściej sięgają po lokalne, sezonowe składniki, dbając o środowisko naturalne i dobrostan zwierząt.</li>
                        </ul>
                        <p>
                        Gastronomia to nie tylko sztuka jedzenia, ale również sposób życia, kultura i nauka, która łączy ludzi wokół wspólnego stołu i inspiruje do odkrywania nowych smaków i doświadczeń kulinarnych.
                        </p>
                    </div>
                )
            case "Biblioteki":
                return (
                    <div>
                        <p>
                        Biblioteki są oazą wiedzy, kultury i nauki, stanowiącymi istotną część społeczności lokalnej i globalnej. To nie tylko miejsca, gdzie przechowuje się książki, ale również przestrzenie do nauki, czytania, badania i refleksji.
                        </p>
                        <p>
                        Oto kilka cech charakterystycznych dla bibliotek:
                        </p>
                        <ul>
                        <li>Dostęp do wiedzy: Biblioteki zapewniają dostęp do bogatych zasobów wiedzy, w postaci książek, czasopism, gazet, dokumentów elektronicznych, a także zasobów internetowych. Są to miejsca, gdzie każdy może znaleźć informacje na temat różnorodnych dziedzin wiedzy, od literatury i historii po naukę i technologię.</li>

                        <li>Czytelnictwo i edukacja: Biblioteki promują czytelnictwo i edukację, zachęcając ludzi do korzystania z książek i innych materiałów do nauki i rozwoju osobistego. Organizują warsztaty, wykłady, spotkania autorskie i inne wydarzenia kulturalno-edukacyjne, które wspierają rozwój czytelnictwa i umiejętności czytelnicze.</li>

                        <li>Przestrzeń społeczna: Biblioteki są również miejscami, gdzie ludzie mogą spotkać się, dyskutować, wymieniać poglądy i nawiązywać kontakty społeczne. Czytelnicy mogą korzystać z czytelni, strefy multimedialnej, pracowni komputerowej czy też sali konferencyjnej, tworząc przyjazne i otwarte środowisko społeczne.</li>

                        <li>Działalność kulturalna: Biblioteki często organizują różnorodne wydarzenia kulturalne, takie jak wystawy, koncerty, spektakle teatralne, pokazy filmowe czy spotkania literackie. Są to okazje do poszerzania horyzontów kulturowych, promocji lokalnych artystów i twórców oraz integrowania społeczności.</li>

                        <li>Źródło inspiracji: Biblioteki są miejscami, gdzie ludzie mogą szukać inspiracji, odkrywać nowe pasje i zainteresowania, oraz rozwijać swoje umiejętności i talenty. Bogate zbiory książek, czasopism i innych materiałów są nieocenionym źródłem wiedzy i inspiracji dla czytelników.</li>

                        <li>Ochrona dziedzictwa kulturowego: Biblioteki pełnią również rolę w ochronie i promocji dziedzictwa kulturowego, przechowując cenne zbiory historyczne, archiwalne i rzadkie wydania książek, dokumentów oraz innych materiałów kulturowych dla przyszłych pokoleń.</li>
                        </ul>
                        <p>
                        Biblioteki są sercem społeczności, które dostarczają wiedzy, inspiracji i rozrywki dla wszystkich jej członków. Są to miejsca, które nie tylko przechowują książki, ale również tworzą otwarte i wszechstronne przestrzenie do nauki, czytania, spotkań społecznych i rozwoju osobistego.
                        </p>
                    </div>
                )
            case "Księgarnie":
                return (
                    <div>
                        <p>
                        Księgarnie to miejsca, gdzie miłośnicy literatury mogą odkrywać, przeglądać i nabywać książki z różnorodnych dziedzin wiedzy oraz gatunków literackich. Są to nie tylko punkty sprzedaży książek, ale także przestrzenie kultury, edukacji i inspiracji.
                        </p>
                        <p>
                        Oto kilka cech charakterystycznych dla księgarni:
                        </p>
                        <ul>
                        <li>Bogaty wybór książek: Księgarnie oferują szeroki wybór książek, obejmujący literaturę piękną, literaturę faktu, podręczniki akademickie, książki popularnonaukowe, podróżnicze, kulinaria, literaturę dziecięcą i wiele innych. Dzięki temu każdy czytelnik może znaleźć coś dla siebie, zgodnie z własnymi zainteresowaniami i preferencjami.</li>

                        <li>Atmosfera kulturalna: Księgarnie często tworzą przyjazne i inspirujące otoczenie, które zachęca do spędzania czasu na przeglądaniu książek, czytaniu, a także uczestnictwie w wydarzeniach literackich, takich jak spotkania autorskie, prezentacje książek, warsztaty pisarskie czy dyskusje literackie.</li>

                        <li>Przestrzeń do spędzania czasu: Księgarnie są również miejscami, gdzie można spędzić czas w spokoju i ciszy, delektując się lekturą, korzystając z dostępnych stołów i foteli czy też pracując zdalnie przy komputerze. To idealne miejsce do skupienia się na książkach i poszukiwaniu inspiracji.</li>

                        <li>Dostępność ekspertów: W niektórych księgarniach pracują pasjonaci książek, którzy chętnie doradzą czytelnikom w wyborze odpowiednich lektur, polecając nowości wydawnicze, bestsellery, czy też książki pasujące do konkretnych zainteresowań czy wieku czytelnika.</li>

                        <li>Wydarzenia literackie: Księgarnie często organizują różnorodne wydarzenia literackie, takie jak spotkania autorskie, prezentacje książek, czytanie fragmentów, warsztaty pisarskie, konkursy czy dyskusje z autorami. Są to okazje do spotkania się z ulubionymi pisarzami, poznania nowych talentów literackich i poszerzenia horyzontów kulturowych.</li>

                        <li>Promocja czytelnictwa: Księgarnie pełnią także istotną rolę w promocji czytelnictwa i edukacji, zachęcając ludzi do czytania i poszukiwania wiedzy. Mogą to być programy lojalnościowe, akcje promocyjne, czytelnia dla dzieci, a także współpraca z lokalnymi szkołami i instytucjami kulturalnymi.</li>
                        </ul>
                        <p>
                        Księgarnie są miejscami, które nie tylko sprzedają książki, ale także tworzą przestrzenie kultury, edukacji i inspiracji dla wszystkich miłośników literatury. To miejsca, gdzie literatura staje się żywym elementem społeczności, inspirując do czytania, refleksji i rozmów o książkach.
                        </p>
                    </div>
                )
            case "Kursy":
                return (
                    <div>
                        <p>
                        Kursy to strukturalne programy edukacyjne, które oferują uczestnikom możliwość pogłębienia wiedzy, zdobycia nowych umiejętności lub doskonalenia już posiadanych. Mogą być prowadzone w różnych formach, takich jak lekcje w tradycyjnych szkołach, zajęcia online, warsztaty praktyczne, szkolenia zawodowe czy też kursy akademickie.
                        </p>
                        <p>
                        Oto kilka cech charakterystycznych dla kursów:
                        </p>
                        <ul>
                        <li>Zróżnicowana oferta tematyczna: Kursy mogą obejmować szeroki zakres tematów, od przedmiotów akademickich, jak matematyka czy historia, po umiejętności praktyczne, takie jak programowanie komputerowe, fotografia, czy zarządzanie projektami. Istnieje również wiele kursów specjalistycznych, które koncentrują się na określonych dziedzinach, takich jak medycyna, finanse czy sztuka.</li>

                        <li>Dostępność różnych poziomów: Kursy są często dostępne na różnych poziomach zaawansowania, aby odpowiadać różnym potrzebom i umiejętnościom uczestników. Mogą to być kursy dla początkujących, średniozaawansowane lub zaawansowane, które pozwalają uczestnikom rozwijać się w swoim tempie.</li>

                        <li>Interaktywność i zaangażowanie: Wiele kursów stawia na interaktywną formę nauczania, angażując uczestników w aktywne działania, dyskusje, praktyczne ćwiczenia i projekty grupowe. Dzięki temu uczestnicy nie tylko zdobywają wiedzę teoretyczną, ale również mają możliwość praktycznego stosowania nowych umiejętności.</li>

                        <li>Elastyczność czasowa i lokalizacyjna: Wiele kursów oferuje elastyczne opcje czasowe i lokalizacyjne, umożliwiając uczestnikom dostosowanie nauki do swojego harmonogramu życia. Mogą to być kursy online, które można realizować z dowolnego miejsca i o dowolnej porze, lub kursy stacjonarne prowadzone wieczorami lub w weekendy.</li>

                        <li>Certyfikacja i uznawalność: Niektóre kursy oferują możliwość uzyskania certyfikatu lub dyplomu potwierdzającego zdobyte umiejętności i wiedzę. Jest to istotne szczególnie w przypadku kursów zawodowych, które mogą być uznawane przez pracodawców lub instytucje edukacyjne.</li>

                        <li>Życiowe nauki i rozwój osobisty: Oprócz kursów akademickich i zawodowych, istnieją również kursy skoncentrowane na rozwoju osobistym, jak np. kursy zarządzania stresem, komunikacji interpersonalnej czy radzenia sobie z czasem. Są to kursy, które pomagają uczestnikom rozwijać umiejętności życiowe i poprawić jakość swojego życia.</li>
                        </ul>
                        <p>
                        Kursy stanowią doskonałą możliwość nauki, rozwoju i zdobywania nowych umiejętności w różnych dziedzinach życia. Dzięki różnorodności oferty tematycznej, elastyczności formy nauki i interaktywnej metodzie nauczania, kursy przyczyniają się do ciągłego rozwoju jednostek oraz społeczności jako całości.
                        </p>
                    </div>
                )
            case "Targi i kiermasze":
                return (
                    <div>
                        <p>
                        Targi i kiermasze to wydarzenia, podczas których producenci, sprzedawcy i konsumenci spotykają się w celu prezentacji, sprzedaży i zakupu różnorodnych produktów i usług. Są to istotne elementy życia społeczno-gospodarczego, które sprzyjają wymianie handlowej, promocji biznesu oraz integracji społeczności lokalnych.
                        </p>
                        <p>
                        Oto kilka cech charakterystycznych dla targów i kiermaszy:
                        </p>
                        <ul>
                        <li>Różnorodność oferty: Targi i kiermasze gromadzą szeroki asortyment produktów i usług, obejmujących takie dziedziny jak przemysł, rolnictwo, rzemiosło, moda, technologia, turystyka, kultura i wiele innych. Mogą to być zarówno produkty nowe, jak i tradycyjne, ręcznie wykonane czy związane z lokalną kulturą.</li>

                        <li>Promocja i marketing: Targi są doskonałą okazją do promocji i marketingu produktów oraz usług, pozwalając producentom i sprzedawcom na bezpośredni kontakt z klientami, prezentację nowości, zbieranie opinii oraz budowanie świadomości marki. Dzięki temu targi są skutecznym narzędziem biznesowym, wspierającym rozwój przedsiębiorstw.</li>

                        <li>Wymiana doświadczeń: Targi i kiermasze umożliwiają także wymianę doświadczeń, wiedzy i najlepszych praktyk między przedstawicielami różnych branż i sektorów gospodarki. Spotkania, konferencje, seminaria i warsztaty organizowane podczas targów stanowią platformę do dyskusji na temat trendów, innowacji i wyzwań w danej dziedzinie.</li>

                        <li>Rozwój biznesu: Targi mogą przyczynić się do rozwoju biznesu poprzez nawiązywanie nowych kontaktów handlowych, pozyskiwanie nowych klientów, budowanie relacji z partnerami biznesowymi oraz podpisywanie umów i kontraktów. Dla wielu firm udział w targach stanowi ważny element strategii marketingowej i sprzedażowej.</li>

                        <li>Atrakcje dla odwiedzających: Targi i kiermasze często oferują również atrakcje dla odwiedzających, takie jak pokazy produktów, degustacje, konkursy, występy artystyczne, pokazy rzemiosła czy zabawy dla dzieci. To nie tylko okazja do zakupów, ale również do spędzenia czasu w miłej atmosferze i wzbogacenia się o nowe doznania.</li>

                        <li>Wsparcie lokalnej społeczności: Targi i kiermasze często odgrywają istotną rolę w wspieraniu lokalnej społeczności poprzez promocję lokalnych producentów, rzemieślników i przedsiębiorców, przyciąganie turystów, generowanie dochodów dla lokalnej gospodarki oraz budowanie poczucia wspólnoty.</li>
                        </ul>
                        <p>
                        Targi i kiermasze to nie tylko miejsca handlu i promocji, ale także ważne wydarzenia społeczno-gospodarcze, które sprzyjają rozwojowi biznesu, wymianie doświadczeń oraz integracji społeczności lokalnych. Dzięki swojej różnorodności i atrakcyjności stanowią one istotny element życia społecznego i kulturalnego.
                        </p>
                    </div>
                )
            case "NaukaInne":
                return (
                    <div>
                        <p>
                        Nauka to proces badawczy, eksploracyjny i poznawczy, który ma na celu zgłębianie tajemnic świata fizycznego, biologicznego, społecznego i technologicznego. Jest to również systematyczna metoda zdobywania wiedzy, formułowania hipotez, przeprowadzania eksperymentów, analizowania danych i wyciągania wniosków.
                        </p>
                        <p>
                        Oto kilka cech charakterystycznych dla nauki:
                        </p>
                        <ul>
                        <li>Badania i eksperymenty: Nauka opiera się na badaniach naukowych i eksperymentach, które umożliwiają zgłębianie tajemnic przyrody i poznawanie jej prawidłowości. Badania naukowe są prowadzone w różnych dziedzinach wiedzy, od fizyki i biologii po socjologię i psychologię.</li>

                        <li>Metoda naukowa: Nauka stosuje metodę naukową, która obejmuje formułowanie hipotez, planowanie eksperymentów, zbieranie danych, analizę wyników i wyciąganie wniosków. Metoda naukowa jest procesem iteracyjnym, który umożliwia systematyczne zbliżanie się do prawdy naukowej.</li>

                        <li>Peer review: W naukowej społeczności istotną rolę odgrywa proces peer review, czyli recenzowanie prac naukowych przez niezależnych ekspertów z danej dziedziny. Recenzja przez rówieśników pomaga zapewnić wysoki poziom jakości i wiarygodności publikowanych badań.</li>

                        <li>Publikacje naukowe: Wyniki badań naukowych są publikowane w czasopismach naukowych, książkach, raportach i innych publikacjach, które są dostępne dla szerokiego grona naukowców i społeczeństwa. Publikacje naukowe stanowią podstawę dla dyskusji, dalszych badań i rozwoju wiedzy.</li>

                        <li>Interdyscyplinarność: Współczesna nauka coraz częściej stawia na interdyscyplinarność, czyli współpracę między różnymi dziedzinami nauki. Interdyscyplinarne podejście pozwala na holistyczne podejście do rozwiązywania skomplikowanych problemów i rozwijanie innowacyjnych rozwiązań.</li>

                        <li>Rozwój technologiczny: Postęp technologiczny odgrywa kluczową rolę w rozwoju nauki, umożliwiając przeprowadzanie bardziej zaawansowanych eksperymentów, analizę dużych zbiorów danych, symulacje komputerowe i wiele innych. Technologia jest nieodłącznym narzędziem w rękach współczesnych naukowców.</li>

                        <li>Edukacja i popularyzacja nauki: Nauka odgrywa istotną rolę w edukacji społeczeństwa, promując krytyczne myślenie, umiejętność rozwiązywania problemów i zdobywania wiedzy. Popularyzacja nauki, czyli dzielenie się wynikami badań i wiedzą naukową z szerokim gronem odbiorców, jest również istotnym aspektem działalności naukowej.</li>
                        </ul>
                        <p>
                        Nauka to dynamiczny i nieustanny proces odkrywania, badania i rozumienia świata, który przyczynia się do postępu społecznego, technologicznego i kulturowego. Dzięki metodzie naukowej, współpracy między naukowcami i rozwojowi technologicznemu, nauka pozwala na coraz głębsze zagłębianie się w tajemnice natury i rozwiązywanie skomplikowanych problemów społecznych.
                        </p>
                    </div>
                )
            default:
                return <div>

                </div>
        }
        
    }

    return(
        <div>
            {/* <h1>{getCategoryString(category)}</h1> */}
            <div className="information-crunches">
            <Crunches target={""} customText={"Powrót do kategorii"} />
            {/* <div className="category-back-link">
            <p onClick={() => navigate(-1)}><i><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></i> 
            &nbsp; Powrót do kategorii</p>
            </div> */}
            </div>
            <CategoryTitle category = { category } categoryInfo = { "" } />
            <div className="normal-sections category-information">
            { getCategoryDescription(category) }

            <br></br>
            <div className="category-back-link">
            <p onClick={() => navigate(-1)}><i><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></i> 
            &nbsp; Powrót do kategorii</p>
            </div>
            </div>
 
        </div>
    )
}

export default CategoryInformation;